<template>
  <el-dialog
    :title="$t('map.guzhangxinxi')"
    v-model="dialogFormVisible"
    :close-on-click-modal="false"
    :append-to-body="true"
    width="1000px"
    @close="clickFun('close')"
  >
    <el-row :gutter="0" class="mt-20">
      <el-col :span="4" :offset="0" class="gz">
        <div class="list">
          <div
            @click="search_data(it)"
            :class="{ 'list-it': true, 'list-act': sel_val == it.value }"
            v-for="it in navLeft"
            :key="'a' + it.name"           
          >
           <span v-if="it.value== 1" class="icon iconfont icon-baojing"></span>
           <span v-if="it.value== 2" class="icon iconfont icon-a-no-videobuluxiang"></span>
           <span v-if="it.value== 3" class="icon iconfont icon-budingwei"></span>
           <span v-if="it.value== 4" class="icon iconfont icon-xinhaodiushi"></span>
            {{ it.name }}
          </div>
        </div>
      </el-col>
      <el-col :span="20" :offset="0" class="gl">
        <el-table :data="dataList" border stripe :height="height" >
          <el-table-column
            v-for="col in columns"
            align="center"
            :key="col.field"
            :label="col.label"
            :width="col.width"
          >
            <template #default="{ row }">
              <span v-if="col.type == 'fun'" v-html="col.render(row)"> </span>
              <span v-else>{{ row[col.field] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <div class="gps-page">
      <el-pagination
        :currentPage="query.page"
        :page-size="query.rp"
        :page-sizes="[20, 50, 100, 200, 300, 400]"
        :background="background"
        layout="total, prev, pager, next"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </el-dialog>
</template>
<script>
export default {
  emits: ["ErpDialogEvent"],
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    isShow(val) {
      this.dialogFormVisible = val;
    },
  },
  data() {
    return {
      sel_val: "1",
      disabled: false,
      background: false,
      total: 0,
      query: { rp: 25, page: 1, flag: 1 },
      flag: 1,
      dataList: [],
      height: 500,
      columns: [],
      columns_tpl: [
        {
          field: "cph",
          label: this.$t("map.cph_name"),
          width: "150px",
          hide: false,
        },
        {
          field: "ssgs",
          label: this.$t("map.ssgs_name"),
          width: "150px",
          hide: false,
        },

        {
          field: "gpssj",
          label: this.$t("map.bjsj"),
          width: "150px",
          hide: false,
        },
        {
          field: "bgzt",
          label: this.$t("map.bjnr"),

          hide: false,
          type: "fun",
          render: (row) => {
            let pos = "";
            var a_str = row.bgzt;
            if (a_str != "") {
              var a_ay = a_str.split(",");
              var a_result = [];
              for (var i = 0; i < a_ay.length; i++) {
                a_result.push(this.$t("map.e_" + a_ay[i]));
              }
              pos = a_result.join(",");
            } else {
              pos = "";
            }
            return pos;
          },
        },
      ],
      navLeft: [
        { name: this.$t("map.cipanbaojing"), value: "1" },
        { name: this.$t("map.blx"), value: "2" },
        { name: this.$t("map.gpsbudingwei"), value: "3" },
        { name: this.$t("map.fanyi38"), value: "4" },
      ],
      dialogFormVisible: this.isShow,
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.query.page = val;
      this.getSearch();
    },
    handleSizeChange(val) {
      this.query.page = 1;
      this.query.rp = val;
      this.getSearch();
    },
    search_data(it) {
      this.query.flag = it.value;
      this.sel_val = it.value;
      this.flag = it.value;
      let ay = [];
      switch (parseInt(it.value)) {
        case 1:
          ay = ay.concat(this.columns_tpl);
          ay.push({
            field: "lVideoMemoryFailureStatus",
            label: this.$t("map.cipanzhuangtai"),
            hide: false,
            type: "fun",
            render: (row) => {
              let pos = "";
              var str_str = row["lVideoMemoryFailureStatus"];
              var str_ay = str_str.split(",");
              var result_ay = [];
              for (var i = 0; i < str_ay.length; i++) {
                if (str_ay[i] != "") {
                  if (str_ay[i] == 1) {
                    result_ay.push("HDD");
                  } else {
                    if (str_ay[i] == 2) {
                      result_ay.push("SD");
                    } else {
                      result_ay.push("SD" + (parseInt(str_ay[i]) - 1));
                    }
                  }
                }
              }
              pos = result_ay.join(",");

              return pos;
            },
          });
          this.columns = ay;
          break;
        case 2:
          ay = ay.concat(this.columns_tpl);
          ay.push({
            field: "lRecordState",
            label: this.$t("map.channel_no"),
            hide: false,
            type: "fun",
            render: (row) => {
              let pos = "";
              var str_str = row["lRecordState"];
              var str_ay = str_str.split(",");
              var result_ay = [];
              for (var i = 0; i < str_ay.length; i++) {
                if (str_ay[i] != "") {
                  result_ay.push("CH" + str_ay[i]);
                }
              }
              pos = result_ay.join(",");

              return pos;
            },
          });
          this.columns = ay;
          break;
        case 3:
          ay = ay.concat(this.columns_tpl);
          ay.push({
            field: "dw",
            label:  this.$t("map.dingwei"),
            hide: false,
            type: "fun",
            render: (row) => {
              return this.$t("map.gps_" + row.islatlng);
            },
          });
          this.columns = ay;
          break;
        case 4:
          ay = ay.concat(this.columns_tpl);
          ay.push({
            field: "lVideoLossStatus",
            label:this.$t("map.channel_no"),
            hide: false,
            type: "fun",
            render: (row) => {
              let pos = "";
              var str_str = row["lVideoLossStatus"];
              var str_ay = str_str.split(",");
              var result_ay = [];
              for (var i = 0; i < str_ay.length; i++) {
                if (str_ay[i] != "") {
                  result_ay.push("CH" + str_ay[i]);
                }
              }
              pos = result_ay.join(",");

              return pos;
            },
          });
          this.columns = ay;
          break;
      }
      this.getSearch();
    },
    getSearch() {
      this.$api.search_lastposition_list_alarm(this.query).then((res) => {
        this.dataList = res.data.data;
        this.total = res.data.pagination.totalRecords;
      });
    },
    clickFun(flag, obj = {}) {
      this.$emit("ErpDialogEvent", Object.assign({ type: flag }, obj));
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.el-dialog__body) {
  padding: 0 !important;
}
.list {
  padding:10px;font-size:14px;
  .list-it {
    box-sizing: border-box;
    height: 35px;
    line-height: 35px;
    // background-color: rgb(185, 213, 234);
    padding: 0 20px;
    font-size: 14px;
    cursor: pointer;
    text-align:left;margin-bottom:10px;display:flex; align-items:center;
  }
  .list-act {
    color: #fff;
    background: #409eff;border-radius:105px;
    span{color:#fff}
  }
  span{font-size:18px;margin-right:5px;color:rgb(44, 120, 191);}
  span.icon-budingwei{font-size:22px;margin-left:-3px;}
}
.gz{background:#fff;border:1px solid #ddd;color:#000}
.gl{padding-left:10px;border-box:box-sizing}
.mt-20{margin-top:-20px;}

::v-deep .el-table--border .el-table__inner-wrapper::after, ::v-deep  .el-table--border::after, ::v-deep .el-table--border::before, ::v-deep  .el-table__inner-wrapper::before{background:#ddd;}
::v-deep .el-table__border-left-patch{background:#ddd;}

</style>
