import axios from "../../utils/axios";
export default {
  get_web_socket_init(ay) {
    return axios.post("api/login/get_web_socket_init", Object.assign({}, ay));
  },
  get_config_imgs(ay) {
    return axios.post("api/login/get_config_imgs", Object.assign({}, ay));
  },
  gps_login(ay) {
    return axios.post("api/login/handler", Object.assign({}, ay));
  },
};
