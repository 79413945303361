export default {
  Title: "Add Driver",

  dCompanyID: "Company",
  dName: "Driver Name",
  dSex: "Gender",
  dBodyNo: "ID Card",
  dContactTel: "Tel",
  dDriverType: "Drving License Type​​",
  dDriverNo: "Drving License",
  dDriverStopDate: "​License Date",
  dDrvICNo: "License",
  dDrvEntrytime: "Join Time",
  dSortCode: "Sort",
  dMore: "Notes",
  dLastUpdated: "Time​",
  bindCars: "Vehicle",
  dfinger_img: "Fingerprint ID",
  dimg: "Driver Photo​",

  // top_dCompanyID: "Please select company",
  // top_dSex: "Please select gender",
  // top_dDriverType: "Please select your driver's license type",

  // xb_first: "Please select gender",
  // xb: [
  //   ["Male", "Male"],
  //   ["Female", "Female"],
  // ],
  // jzlx_first: "Please select your driver's license type",
  // jzlx: [
  //   ["C1", "C1"],
  //   ["C2", "C2"],
  // ],
};
