export default {
  sbwg: "Device Gateway",
  yhfw: "User Server",
  tycs: "General Parameter​",
  cccs: "FTP and Storage​",
  logo_title: "Logo and Name​",
  smtppz: "SMTP Server",
  webpz: "Default Parameter",
  apppz: "APP",

  wgsz: "Gateway",
  jtbbsbdk: "Device Port",
  jtbbsbdkp: "Please fill in the port.",
  yhfwip: "User Service IP",
  yhfwipp: "Please fill in the user service IP.",
  ygxzzdzc: "Strict Certification​",
  kq: "ON",
  gb: "OFF",
  send: "Save",
  czcg: "Done",

  websz: "Websocket",
  jtwebqqdk: "WebSocket Port",
  jtwebqqdkp: "Please fill in the WebSocket port.",
  webzsmm: "WebSocket SSL Password",
  webzsmmp: "Please fill in the WebSocket SSL password.",
  webzslj: "WebSocket SSL Address",
  webzsljp: "Please enter the WebSocket SSL address.",
  webqyssl: "SSL",
  jbcssz: "Server",
  jtkhddk: "Client Port",
  jtkhddkp: "Please fill in the client port.",
  jtsbwgfwdk: "Device Gateway Port",
  jtsbwgfwdkp: "Please fill in the device gateway port.",
  jthttpqqdk: "HTTP Port",
  jthttpqqdkp: "Please fill in the HTTP port.",
  fjfwqjtdk: "Media Server Port",
  fjfwqjtdkp: "Please fill in the media server port.",
  zcxx: "Registration Info",
  zcxxp: "Please fill in the registration info.​",
  spfwqsz: "Video Server",
  spfwqdz: "Video Server Address​",
  spfwqdzp: "Please fill in the video server address.",
  spfwqyldk: "Video Live Port​",
  spfwqyldkp: "Please fill in the video live port.​",
  sphfdk: "Video Playback Port​",
  sphfdkp: "Please fill in the video playback port.",
  spkhddk: "Video Client Port",
  spkhddkp: "Please fill in the video client port.",
  spflvdk: "Video FLV Port",
  spflvdkp: "Please fill in the video FLV port.",
  splzthqdz: "Video Status Address",
  splzthqdzp: "Please fill in the video status address.",
  spqyhttps: "HTTPS",

  tycssz: "General Parameter",
  bdip: "Intranet IP",
  bdipp: "Please fill in the intranet IP.",
  glsbidh: "Device Log ID",
  glsbidhp: "Please fill in the device log ID.",
  fwqgwip: "Extranet IP",
  fwqgwipp: "Please fill in the external IP.",
  qyqjts: "Debug",

  ftpcssz: "FTP",
  ftpipdz: "FTP IP",
  ftpipdzp: "Please fill in the FTP IP.",
  ftpdk: "FTP Port",
  ftpdkp: "Please fill in the FTP port.​",
  ftpyhmc: "FTP Name",
  ftpyhmcp: "Please fill in the FTP name.​",
  ftpyhmm: "FTP Password",
  ftpyhmmp: "​Please fill in the FTP password.",
  bjyjcssz: "Email",
  bjyjnrmbhtml: "Alarm Email HTML",
  bjyjnrmbhtmlp: "Please fill in the alarm email HTML.",
  bjyjjsdz: "Alarm Email Recipient​",
  bjyjjsdzp: "Please fill in the alarm email recipient.",
  yjnryy: "Email Language",
  zw: "Chinese",
  yw: "English",
  fwqnryy: "Server Language",
  csxxsz: "Storage",
  ccwz: "Media Storage Address​",
  ccwzp: "Please fill in the media storage address.",
  xdwz: "Media Address",
  xdwzp: "Please fill in the media address.",
  zdcckj: "Min Storage Remaining/GB​",
  zdcckjp: "Please fill in the min remaining storage space.​",
  yjcckj: "Storage Alarm/GB",
  yjcckjp: "Please fill in the storage alarm.",
  lxblts: "Media Storage Time/Day​",
  lxbltsp: "Please fill in the media storage time.",
  gjblts: "Trip Storage Time/Day​",
  gjbltsp: "Please fill in the trip storage time.",
  dw: "Capacity:GB",

  website_name_title: "WEB",
  website_name: "WEB Name",
  website_name_p: "Please fill in the web name.​",
  logo_pz: "LOGO",
  logo_photo: "LOGO",
  upimg: "Save",
  jianyi: "Please use the size 220*60.​",
  logo_titie: "Logo and Name",
  sccg: "Done",
  sccb: "Error",

  smtpsz: "SMTP",
  form: "From",
  formts: "Please fill in from.​",
  showname: "Name",
  showts: "Please fill in name.​",
  user: "Name",
  userts: "Please fill in name.",
  pwd: "Password",
  dz: "SMTP",
  dzts: "Please fill in SMTP.",
  dk: "Port",
  dkts: "Please fill in SMTP port.​",
  zt: "Theme​",
  ztts: "Please fill in theme.​",
  jmlx: "Encryption Type",
  bjm: "Not Encryption​",
  csyx: "Test Email",
  ts: "Please fill in video off time.​",
  czsb: "Error",
  nrts: "Please fill in.",
  sjrts: "Please fill in recipient.​",
  sendemail: "Send",
  mmts: "Please fill in password.",
  sjr: "To",
  nr: "Content",
  fscg: "Done",
  fssb: "Error",
  mrdt: "Default Map",
  ge: "Google Map",
  bd: "Baidu Map",
  osm: "Open Street Map",
  gekey: "Google Key",
  gets: "Please fill in Google Map key.​",
  bdkey: "Baidu Key",
  bdts: "Please fill in Baidu Map key.​",
  maxCar: "Max Vehicles Display",
  maxCarTitle:
    "Please fill in the max  of vehicles that can be displayed on Baidu Map.​",
  zdhqwz: "​Vehicle Address",
  gbsj: "Video Off Time/Min",
  gbsjts: "Please fill in video off time.",
  sjdw: "Time：Min",

  mr: "Default",
  qtx: "Please fill in.",
  websocketdz: "Websocket Address",
  websocketdk: "Websocket Port",
  dtsz: "Map",
  mrdtp: "Please fill in SMTP port.​",
  geapidz: "Google Map API",
  cssz: "Parameter Set",
  sddw: "Speed",
  mryy: "Language",
  chinese: "Chinese",
  en: "English",
  vi: "Vietnam",

  login_pz: "Login Page",
  login_photo: "Login page Image​",
  jianyi2: "Please use the size1920*600.",

  mrspfwqdzt: "Use # separate​",
  uplogo: "Upload Logo",

  bnwk: ":Please fill in",
  erro: "Please fill in:",
  wjbcz: "No WebSocket SSL",
  dtwzhqdz: "Reverse Address",
  dtwzhqdzp: "Please fill in reverse address info.​",

  azbb: "Android Version",
  androidremark: "Android Update Detail.​",
  azsjurl: "Android Update URL.",
  iosbb: "Apple Version",
  iosremark: "Apple Update Detail.",
  iossjurl: "Apple Update URL.",
  reark_text:
    "Use {#} to separate multiple languages ​​and arrange them in the order of the web default languages. Example: Chinese{#}English{#}····",

  bdkey_array: "​Multiple Baidu Map key",
  bdkey_array_text: "Use {#} to separate multiple Baidu map key​",
  djdk: "Intercom Port",
  sytx: "Alarm Audio",
};
