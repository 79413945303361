export default {
  carTitle: "Add Vehicle",
  vPlate: "Plate No.",
  vCompanyID: "Company",
  vBobyColor: "Vehicle Color",
  vDeviceID: "Device ID",
  // vSimNo: "SIM Card",
  vVehType: "Vehicle Type",
  vInstaller: "Installer",
  vStartServDate: "Start Date",
  vStopServDate: "End Date",
  vState: "Status",
  vLastUpdated: "Time",
  sMonthFlow: "Cellular",
  sResidualFlow: "Data Used(M)",
  vxlh: "Serial No.",
  vxh: "Model​",
  sMonthDay: "Data Reset",
  dType: "Device Type",
  dName: "Device ID",
  dChannelNum: "Channel",
  dPara: "Channel Name",
  vIcoID: "Vehicle Icon",
  sNo: "SIM Card",
  // delete_title: "Delete Car​",
  // delete_content: "Do you want to delete the selected vehicle?",

  // vPlateColorID: "Car Color​",

  // vChannelCount: "Channel",
  // vProductNo: "Factory ID",
  // vPassword: "Authentication Password",
  // vOwnNo: "Number",
  // vOwnerName: "Name",
  // vOwnerBobyNo: "ID Card",
  // vOwnerAddress: "Address",
  // vOwnerSex: "Gender",
  // vOwnerEmail: "Email",
  // vOwnerLinkTel: "Phone No.",
  // vFrameNo: "Engine No.",
  // vEngineNo: "Frame No.",

  // vTradeType: "Industry Type",

  // vState_1: "ON",
  // vState_0: "OFF",
  // vTermID: "The last 7 digits of the device ID",
  // vTermFacID: "Factory ID",
  // vTermFacName: "Factory Name",
  // vRegisterNo: "License",
  // vTermVersion: "Device Version",
  // vSortCode: "Sort",
  // vMore: "Notes",

  // cpys: [
  //   ["1", "Blue"],
  //   ["2", "Yellow"],
  //   ["3", "Black"],
  //   ["4", "White"],
  //   ["9", "Other Colors​"],
  // ],
  // btn_select: "Select Car",
  // unselect_sex: "Not Select",
  // search_select_array: [
  //   ["1", "Not Authorized"],
  //   ["2", "Authorized"],
  // ],
  // search_company: "Select Fleet",
  // btn_bind: "Vehicle",
  // set_success: "Done",

  // select_num: "Select Quantity​",
  // btn_kstj: "Quick Add",
  // btn_ksdr: "Import",

  // cz: "Fleet",
  // qb: "All",
  // cx: "Search",
  // xzmb: "Download Template",
  // zt: "State",

  // export: "Export",
};
