function imgData(st_msg) {
  //0 离线 1 在线 2 报警 3 未定位  4 acc关 速度为0  5 acc开 速度为0
  var data = [];
  data.dirName = "offline";
  data.color = "black";
  switch (st_msg) {
    case 0:
      data.dirName = "offline";
      data.color = "black";
      break;
    case 1:
      data.dirName = "online";
      data.color = "green";
      break;
    case 2:
      data.dirName = "alarm";
      data.color = "red";
      break;
    case 3:
      data.dirName = "parkaccon";
      data.color = "violet";
      break;
    case 4:
      data.dirName = "stopaccoff";
      data.color = "Orange";
      break;
    case 5:
      data.dirName = "stopaccon";
      data.color = "blue";
      break;
  }
  return data;
}
//设置车辆图标方向
function car_dir(v) {
  var r = 1;
  var v = parseFloat(v) / 22.5;
  if (v < 1 || v >= 15) {
    r = 1;
  } else if (v >= 1 && v < 3) {
    r = 2;
  } else if (v >= 3 && v < 5) {
    r = 3;
  } else if (v >= 5 && v < 7) {
    r = 4;
  } else if (v >= 7 && v < 9) {
    r = 5;
  } else if (v >= 9 && v < 11) {
    r = 6;
  } else if (v >= 11 && v < 13) {
    r = 7;
  } else if (v >= 13 && v < 15) {
    r = 8;
  }
  return r;
}

function getHTTPURL() {
  let rt = process.env.VUE_APP_BASE_URL;
  if (process.env.NODE_ENV == "development") {
    rt = process.env.VUE_APP_BASE_IMG_URL;
  }
  return rt;
}
export { imgData, car_dir, getHTTPURL };
