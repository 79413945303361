export default {
  Title: "基本信息",

  iPlate: "车牌号",
  iInsName: "保险公司",
  iAgentName: "代理人",
  iContactTel: "联系电话",
  iInsType: "保险类型",
  iPolicyNo: "保险单号",
  iEffectiveDate: "生效时间",
  iExpirationDate: "失效时间",
  iPreWarnDays: "提醒天数",
  iPayablePrice: "价格",
  iDiscount: "折扣",
  iPaidPrice: "实际价格",
  iInsState: "理赔情况",
  iMore: "备注",
  iLastUpdated: "时间",
};
