const GpsEventBroadcaster = {
  listeners: [],
  addListener: function (listener) {
    this.listeners.push(listener);
  },
  broadcast: function (event) {
    this.listeners.forEach(function (listener) {
      listener(event);
    });
  },
};
export default GpsEventBroadcaster;
