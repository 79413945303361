export default {
  title: "基本信息",
  sCompanyID: "所属公司",
  sNo: "SIM卡号",
  sMonthFlow: "流量限制(M)",
  zy_sMonthFlow: "已用流量(M)",
  sMonthDay: "流量重置日",
  sRegTime: "注册时间",
  sState: "状态",
  sRemark: "备注",
  sAddTime: "添加时间",
  th_sState_0: "未启用",
  th_sState_1: "启用",
  syll: "已用流量(%)",
  syllm: "已用流量(MB)",
  cName: "所属企业",
};
