const locales = require.context(".", true, /[A-Za-z0-9-_,\s]+\.js$/i);

const messages = {};
locales.keys().forEach((key) => {
  if (key.indexOf("index") === -1) {
    if (key.indexOf("global") > -1) {
      let p;
      for (p in locales(key).default) {
        messages[p] = locales(key).default[p];
      }
    } else {
      messages[key.replace("./", "").replace(".js", "")] = locales(key).default;
    }
  }
});
export default messages;
