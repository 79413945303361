import i18n from "@/i18n";
const { t } = i18n.global;
export default [
  {
    title: t("nav.xtsz"),
    icon: "el-icon-tickets",
    name: "SystemMgr",
    path: "/SystemMgr",
    children: [
      {
        title: t("nav.ssdw"),
        icon: "el-icon-tickets",
        name: "map",
        path: "/map",
        component: "Map",
      },
      {
        title: t("nav.gjhf"),
        icon: "el-icon-tickets",
        name: "historys",
        path: "/historys",
        component: "Historys",
      },
      {
        title: t("nav.sssp"),
        icon: "el-icon-tickets",
        name: "video",
        path: "/video",
        component: "Video",
      },
      {
        title: t("nav.lxhf"),
        icon: "el-icon-tickets",
        name: "BackVideo",
        path: "/BackVideo",
        component: "BackVideo",
      },
      {
        title: t("nav.zjzx"),
        icon: "el-icon-tickets",
        name: "Evidence",
        path: "/Evidence",
        component: "Evidence",
      },
      // {
      //   title: "测试地图",
      //   icon: "el-icon-tickets",
      //   name: "GMap",
      //   path: "/GMap",
      //   component: "GMap",
      // },
    ],
  },
  {
    title: t("nav.bb"),
    icon: "el-icon-tickets",
    name: "ReportMgr",
    path: "/ReportMgr",
    children: [
      {
        title: t("nav.bbcx"),
        icon: "el-icon-tickets",
        name: "reports",
        path: "/reports",
        component: "reports",
        hasSubs: true,
        children: [
          {
            title: t("nav.lcbb"),
            icon: "el-icon-tickets",
            name: "reports-lccx",
            path: "lccx",
            component: "Lccx",
          },
          {
            title: t("nav.sxxtj"),
            icon: "el-icon-tickets",
            name: "reports-sxxtj",
            path: "sxxtj",
            component: "Sxxtj",
          },
          {
            title: t("nav.acckgbb"),
            icon: "el-icon-tickets",
            name: "reports-accdhtj",
            path: "accdhtj",
            component: "Accdhtj",
          },
          {
            title: t("nav.cgbj"),
            icon: "el-icon-tickets",
            name: "reports-cgbj",
            path: "cgbj",
            component: "Cgbj",
          },
          {
            title: t("nav.gzbj"),
            icon: "el-icon-tickets",
            name: "reports-gzbj",
            path: "gzbj",
            component: "Gzbj",
          },
          {
            title: t("nav.zdaq"),
            icon: "el-icon-tickets",
            name: "reports-zdaqbj",
            path: "zdaqbj",
            component: "Zdaqbj",
          },
          {
            title: t("nav.dzwlcx"),
            icon: "el-icon-tickets",
            name: "reports-dzwl",
            path: "dzwl",
            component: "Dzwl",
          },
          {
            title: t("nav.sjll"),
            icon: "el-icon-tickets",
            name: "reports-sjll",
            path: "sjll",
            component: "Sjll",
          },
          {
            title: t("nav.yhrz"),
            icon: "el-icon-tickets",
            name: "reports-yhxw",
            path: "yhxw",
            component: "Yhxw",
          },
          {
            title: t("nav.cljspf"),
            icon: "el-icon-tickets",
            name: "reports-jspf",
            path: "jspf",
            component: "Jspf",
          },
        ],
      },
    ],
  },
  {
    title: t("nav.sz"),
    icon: "el-icon-tickets",
    name: "SysSetMgr",
    path: "/SysSetMgr",
    children: [
      {
        title: t("nav.sz"),
        icon: "el-icon-tickets",
        name: "syssets",
        path: "/syssets",
        component: "syssets",
        hasSubs: true,
        children: [
          {
            title: t("nav.clgl"),
            icon: "el-icon-tickets",
            name: "syssets-cars",
            path: "cars",
            component: "Cars",
          },
          {
            title: t("nav.czgl"),
            icon: "el-icon-tickets",
            name: "syssets-cargroup",
            path: "cargroup",
            component: "CarGroup",
          },
          {
            title: t("nav.jsgl"),
            icon: "el-icon-tickets",
            name: "syssets-roles",
            path: "roles",
            component: "Roles",
          },
          {
            title: t("nav.gsgl"),
            icon: "el-icon-tickets",
            name: "syssets-companys",
            path: "companys",
            component: "Companys",
          },
          {
            title: t("nav.yhgl"),
            icon: "el-icon-tickets",
            name: "syssets-users",
            path: "users",
            component: "Users",
          },
          {
            title: t("nav.sjgl"),
            icon: "el-icon-tickets",
            name: "syssets-drivers",
            path: "drivers",
            component: "Drivers",
          },
          {
            title: t("nav.bxgl"),
            icon: "el-icon-tickets",
            name: "syssets-insurances",
            path: "insurances",
            component: "Insurances",
          },
          {
            title: t("nav.simgl"),
            icon: "el-icon-tickets",
            name: "syssets-sims",
            path: "sims",
            component: "Sims",
          },
          {
            title: t("nav.xtgl"),
            icon: "el-icon-tickets",
            name: "syssets-xtpz",
            path: "xtpz",
            component: "Xtpz",
          },
          {
            title: t("nav.fwqgl"),
            icon: "el-icon-tickets",
            name: "syssets-fwqpz",
            path: "fwqpz",
            component: "Fwqpz",
          },
          {
            title: t("nav.gzgl"),
            icon: "el-icon-tickets",
            name: "syssets-rules",
            path: "rules",
            component: "Rules",
          },
          {
            title: t("nav.qygl"),
            icon: "el-icon-tickets",
            name: "syssets-maprect",
            path: "maprect",
            component: "MapRect",
          },
        ],
      },
    ],
  },
];
