export default {
  title: "Information",
  sCompanyID: "Company",
  sNo: "SIM Card",
  sMonthFlow: "Mobile Data(M)",
  zy_sMonthFlow: "Data Used(M)",
  sMonthDay: "Data Reset",
  sRegTime: "Time",
  sState: "State",
  sRemark: "Notes",
  sAddTime: "Entry Time​",
  th_sState_0: "Not Enabled",
  th_sState_1: "Enable",
  syll: "Data Used(%)",
  syllm: "Data Used(MB)",
  cName: "Company",
};
