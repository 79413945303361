export default {
  sbwg: "设备网关",
  yhfw: "用户服务",
  tycs: "通用参数",
  cccs: "FTP及存储",
  logo_title: "logo及名称",
  smtppz: "SMTP服务器",
  webpz: "默认配置",
  apppz: "app配置",

  wgsz: "网关设置",
  jtbbsbdk: "设备端口",
  jtbbsbdkp: "请输入设备端口",
  yhfwip: "用户服务IP",
  yhfwipp: "请输入用户服务IP",
  ygxzzdzc: "严格限制终端注册",
  kq: "开启",
  gb: "关闭",
  send: "立即提交",
  czcg: "操作成功",

  websz: "Websocket设置",
  jtwebqqdk: "WebSocket请求端口",
  jtwebqqdkp: "请填写WebSocket请求端口",
  webzsmm: "WebSocket证书密码",
  webzsmmp: "请填写WebSocket证书密码",
  webzslj: "WebSocket证书路径",
  webzsljp: "请填写WebSocket证书路径",
  webqyssl: "WebSocket启用SSL",
  jbcssz: "基础参数设置",
  jtkhddk: "客户端端口",
  jtkhddkp: "请填写客户端端口",
  jtsbwgfwdk: "设备网关服务端口",
  jtsbwgfwdkp: "请填写设备网关服务端口",
  jthttpqqdk: "Http请求端口",
  jthttpqqdkp: "请填写监听Http请求端口",
  fjfwqjtdk: "附件服务器端口",
  fjfwqjtdkp: "请填写附件服务器端口",
  zcxx: "注册信息",
  zcxxp: "请填写注册信息",
  spfwqsz: "视频服务器设置",
  spfwqdz: "视频服务器地址",
  spfwqdzp: "请填写视频服务器地址",
  spfwqyldk: "视频服务器直播端口",
  spfwqyldkp: "请填写视频服务器直播端口",
  sphfdk: "视频服务器回放端口",
  sphfdkp: "请填写视频服务器回放端口",
  spkhddk: "视频服务器客户端端口",
  spkhddkp: "请填写视频服务器客户端端口",
  spflvdk: "视频服务器FLV端口",
  spflvdkp: "请填写视频服务器FLV端口",
  splzthqdz: "视频流状态获取地址",
  splzthqdzp: "请填写视频流状态获取地址",
  spqyhttps: "视频服务器启用HTTPS",

  tycssz: "通用设置",
  bdip: "本地IP",
  bdipp: "请填写本地IP",
  glsbidh: "过滤设备ID号",
  glsbidhp: "请填写过滤设备ID号",
  fwqgwip: "服务器公网IP",
  fwqgwipp: "请填写服务器公网IP",
  qyqjts: "启用全局调试",

  ftpcssz: "FTP设置",
  ftpipdz: "FTP IP地址",
  ftpipdzp: "请填写FTP IP地址",
  ftpdk: "FTP端口",
  ftpdkp: "请填写FTP端口",
  ftpyhmc: "FTP用户名称",
  ftpyhmcp: "请填写FTP用户名称",
  ftpyhmm: "FTP用户密码",
  ftpyhmmp: "请填写FTP用户密码",
  bjyjcssz: "报警邮件参数",
  bjyjnrmbhtml: "报警邮件内容模板HTML",
  bjyjnrmbhtmlp: "请填写报警邮件内容模板HTML",
  bjyjjsdz: "服务器报警邮件接收地址",
  bjyjjsdzp: "请填写服务器报警邮件接收地址",
  yjnryy: "邮件内容语言",
  zw: "中文",
  yw: "英文",
  fwqnryy: "服务器内容语言",
  csxxsz: "存储设置",
  ccwz: "图片、录像、语音存储位置",
  ccwzp: "请填写图片、录像、语音存储位置",
  xdwz: "图片、录像、语音相对网址",
  xdwzp: "请填写图片、录像、语音相对网址",
  zdcckj: "最少剩余存储空间",
  zdcckjp: "请填写最少剩余存储空间",
  yjcckj: "剩余存储空间预警",
  yjcckjp: "请填写剩余存储空间预警",
  lxblts: "图片/录像保留天数",
  lxbltsp: "请填写图片/录像保留天数",
  gjblts: "轨迹保留时间/天",
  gjbltsp: "请填写轨迹保留时间",
  dw: "单位:GB",

  website_name_title: "网站名称",
  website_name: "网站名称",
  website_name_p: "请填写网站名称",
  logo_pz: "logo配置",
  logo_photo: "logo图片",
  upimg: "上传",
  jianyi: "建议上传220*60的图片",
  logo_titie: "logo及网站名称配置",
  sccg: "上传成功",
  sccb: "上传失败",

  smtpsz: "SMTP设置",
  form: "发件人",
  formts: "请填写发件人",
  showname: "展示名称",
  showts: "请填写展示名称",
  user: "用户名称",
  userts: "请填写用户名称",
  pwd: "密码",
  dz: "SMTP地址",
  dzts: "请填写SMTP地址",
  dk: "SMTP端口",
  dkts: "请填写SMTP端口",
  zt: "主题",
  ztts: "请填写主题",
  jmlx: "加密类型",
  bjm: "不加密",
  csyx: "测试邮箱",
  ts: "请设置正确的自动关闭视频时间",
  czsb: "操作失败",
  nrts: "请填写内容",
  sjrts: "请填写收件人",
  sendemail: "发送",
  mmts: "请填写密码",
  sjr: "收件人",
  nr: "内容",
  fscg: "发送成功",
  fssb: "发送失败",
  mrdt: "默认地图",
  ge: "谷歌地图",
  bd: "百度地图",
  osm: "open street map",
  gekey: "谷歌Key",
  gets: "请填写谷歌地图的秘钥",
  bdkey: "百度Key",
  bdts: "请填写百度地图的秘钥",
  maxCar: "最大显示车辆数",
  maxCarTitle: "请填写百度地图最大显示车辆数",
  zdhqwz: "自动获取位置",
  gbsj: "自动关闭视频时间",
  gbsjts: "请填写自动关闭视频时间",
  sjdw: "单位：Min",

  mr: "默认",
  qtx: "请填写",
  websocketdz: "websocket地址",
  websocketdk: "websocket端口",
  dtsz: "默认地图设置",
  mrdtp: "请填写SMTP端口",
  geapidz: "谷歌地图api地址",
  cssz: "参数设置",
  sddw: "速度单位",
  mryy: "默认语言",
  chinese: "中文",
  en: "英语",
  vi: "越南语",

  login_pz: "登录页面背景图片配置",
  login_photo: "登录页面背景图片",
  jianyi2: "建议上传1920*600的图片",

  mrspfwqdzt: "多个使用#分隔",
  uplogo: "上传自定义logo",

  bnwk: ":不能为空",
  erro: "请设置正确的:",
  wjbcz: "WebSocket证书文件不存在",
  dtwzhqdz: "地图位置获取地址",
  dtwzhqdzp: "请填写地图位置获取地址",

  azbb: "安卓APP版本",
  androidremark: "安卓APP升级描述",
  azsjurl: "安卓APP升级URL",
  iosbb: "苹果APP版本",
  iosremark: "苹果APP升级描述",
  iossjurl: "苹果APP升级URL",
  reark_text:
    "多种语言中间使用{#}间隔，语言的顺序按照web默认配置中默认参数设置中的默认语言顺序排列。实例：中文{#}英文{#}····",

  bdkey_array: "百度地图key集合",
  bdkey_array_text: "百度地图key之间使用{#}分隔",
  djdk: "对讲端口",
  sytx: "监控页面报警声音提醒",
};
