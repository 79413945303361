import axios from "../../utils/axios";
export default {
  alarm_get_alarm_list_by_id(ay) {
    return axios.post("api/alarm/get_alarm_list_by_id", Object.assign({}, ay), {
      hide_loading: 1,
    });
  },
  alarm_vehicle_info(ay) {
    return axios.post("api/alarm/vehicle_info", Object.assign({}, ay), {
      hide_loading: 1,
    });
  },
};
