<template>
  <div class="g-map">
    <div class="tool">
      <div class="map-change flex-row">
        <div style="min-width: 2px">
          <slot> </slot>
        </div>
        <!-- <div class="map-tool map-tool-1" @click="draw(6)"></div>
        <div class="map-tool map-tool-2" @click="draw(1)"></div>
        <div class="map-tool map-tool-3" @click="draw(2)"></div>
        <div class="map-tool map-tool-4" @click="draw(4)"></div>
        <div class="map-tool map-tool-5" @click="draw(3)"></div>
        <div class="map-tool map-tool-6" @click="draw(5)"></div> -->
        <div v-if="isPlay" style="flex-row">
          <div v-if="playState == 'pause'" @click="nextCar()">
            {{ $t("map.jixu") }}
          </div>
          <div v-if="playState == 'play'" @click="pauseCar()">
            {{ $t("map.zanting") }}
          </div>
          <div
            v-if="playState == 'play' || playState == 'pause'"
            @click="stopCar()"
          >
            {{ $t("map.jieshu") }}
          </div>
          <div v-if="playState == 'stop'" @click="playCar()">
            {{ $t("map.track_bf") }}
          </div>
        </div>
        <!-- <div v-for="(map, index) in maps" :key="index" @click="clickMap(map)">
          {{ map.name }}
        </div> -->
      </div>
    </div>
    <div style="height: calc(100% - 0px)">
      <component
        ref="mapRef"
        :is="currentMap.component"
        :api-key="currentMap.apiKey"
        :ayMarker="ayMarker"
        :ayData="ay_data"
        :ayHistory="ay_history"
        :mapID="mapID"
        @MAPEVENT="MAPEVENT"
      ></component>
    </div>
  </div>
</template>

<script>
import { fa } from "element-plus/es/locale";
import BaiduMap from "../components/GpsMap/BaiduMap.vue";
import GoogleMap from "../components/GpsMap/GoogleMap.vue";
import { markRaw } from "vue";

export default {
  props: {
    isPlay: {
      type: Boolean,
      default: true,
    },
    mapExpID: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      playState: "stop",
      mapID: "map-container-history" + this.mapExpID,
      // map_markers: new Map(),
      ay_data: [],
      ay_history: [],

      ayMarker: [], //lat纬度：-90~90，北纬正数，南纬负数  lng经度：-180~180，东经正数，西经负数
      currentMap: {
        // name: this.$t("map.baidu"),
        // component: markRaw(BaiduMap),
        // apiKey: "your_baidu_map_api_key",
        name: this.$t("map.guge"),
        component: markRaw(GoogleMap),
        apiKey: "your_google_map_api_key",
      },
      maps: [
        {
          id: 2,
          name: this.$t("map.baidu"),
          component: markRaw(BaiduMap),
          apiKey: "your_baidu_map_api_key",
        },
        {
          id: 1,
          name: this.$t("map.guge"),
          component: markRaw(GoogleMap),
          apiKey: "your_google_map_api_key",
        },
        // {
        //   name: "Bing Map",
        //   component: BingMap,
        //   apiKey: "your_bing_map_api_key",
        // },
        // {
        //   name: "Tianditu Map",
        //   component: TiandituMap,
        //   apiKey: "your_tianditu_map_api_key",
        // },
      ],
      currentMapIndex: 0,
    };
  },
  created() {
    this.ayMarker = [{ lat: 39.915, lng: 116.404 }];
  },
  mounted() {
    this.$api.sys_get_xtpz({}).then((res) => {
      let row = res.data.row;
      this.mainMap(parseInt(row.default_map));
      // console.log(res.data);
    });
  },
  methods: {
    clearAll() {
      //清理地图上图标
      this.$refs.mapRef.clearAll();
    },
    MAPEVENT(obj) {
      switch (obj.type) {
        case "play":
        case "pause":
        case "stop":
          this.playState = obj.type;
          break;
      }
    },
    posCar(row) {
      this.$refs.mapRef.posCar(row);
    },
    nextCar() {
      this.$refs.mapRef.nextCar();
    },
    pauseCar() {
      this.$refs.mapRef.pauseCar();
    },
    stopCar() {
      this.$refs.mapRef.stopCar();
    },
    playCar() {
      this.$refs.mapRef.playCar();
    },
    drawCarRoad(ay) {
      this.ay_history = ay;
      // this.$refs.mapRef.drawHistory(ay);
    },
    mainMap(id) {
      let it = this.maps.find((it) => it.id == id);
      this.currentMap = it;
    },
    posMarker(obj) {
      console.log("posMarker", obj);
      this.$refs["mapRef"].posMarker(obj);
    },
    removeMaerker(obj) {
      console.log("removeMaerker", obj);
      for (let i = this.ay_data.length - 1; i >= 0; i--) {
        if (this.ay_data[i].id == obj.id) {
          this.ay_data.splice(i, 1);
        }
      }
      this.$refs["mapRef"].removeMaerker(obj);
      // let key = "m" + obj.id;
      // if (this.map_markers.has(key)) {
      //   this.map_markers.delete(key);
      // }
      // console.log("removeMaerker", this.map_markers);
      // this.$refs["mapRef"].setMarkers(this.map_markers);
    },
    addMarker(obj) {
      console.log("addMarker", obj);
      this.ay_data.push(obj);
      this.$refs["mapRef"].addMarker(obj);
      // let key = "m" + obj.id;
      // if (!this.map_markers.has(key)) {
      //   this.map_markers.set(key, obj);
      // }
      // this.$refs["mapRef"].setMarkers(this.map_markers);
      // console.log("addMarker", this.map_markers);
    },
    draw(drawType) {
      this.$refs["mapRef"].drawModel(drawType);
    },
    clickMap(it) {
      this.currentMap = it;
      // this.ayMarker.splice(0, this.ayMarker.length);
      // this.ayMarker = [{ lat: 39.915, lng: 116.404 }];
    },
  },
};
</script>

<style lang="scss">
.car_info {
  width: 400px;
}

.car_info h2 {
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.car_info dl {
  clear: both;
  height: 20px;
  line-height: 20px;
  display: block;
}

.car_info dt {
  width: 25px;
  float: left;
  text-align: justify;
  text-justify: distribute-all-lines;
  text-align-last: justify;
  -moz-text-align-last: justify; /*ff*/
  -webkit-text-align-last: justify; /*chrome 20+*/
}

.car_info dd {
  width: 145px;
  float: left;
  margin-left: 5px;
}
</style>
<style lang="scss" scoped>
.g-map {
  width: 100%;
  height: 100%;
  position: relative;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.map-change {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: rgba(0, 0, 0, 0.3) 0 0 7px;
}
.tool {
  height: 30px;
  line-height: 30px;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 999;
  width: auto;
  font-size: 14px;
  padding: 0px 5px;
  .flex-row {
    display: flex;
    flex-direction: row;
  }
  .map-change div {
    min-width: 50px;
  }
  .map-change {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 10px;
    div {
      padding: 0 5px;
    }
    .map-tool {
      box-sizing: border-box;
      background: url(../assets/bg_drawing_tool.png) no-repeat 0 0;
      // background-size: 100% 100%;
      width: 50px;
      height: 40px;
    }
    .map-tool-1 {
      background-position: -10px -2px;
    }
    .map-tool-2 {
      background-position: -65px -2px;
    }
    .map-tool-3 {
      background-position: -138px -2px;
    }
    .map-tool-4 {
      background-position: -200px -2px;
    }
    .map-tool-5 {
      background-position: -265px -2px;
    }
    .map-tool-6 {
      background-position: -330px -2px;
    }
  }
}
</style>
