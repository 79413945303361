export default {
  Title: "Add Insurance",

  iPlate: "Plate No.",
  iInsName: "Company",
  iAgentName: "Salesman",
  iContactTel: "Tel",
  iInsType: "Type",
  iPolicyNo: "Policy No.",
  iEffectiveDate: "Start Time",
  iExpirationDate: "End Time",
  iPreWarnDays: "Reminder/Day​",
  iPayablePrice: "Price",
  iDiscount: "Discount",
  iPaidPrice: "Final Price​",
  iInsState: "Claims",
  iMore: "​Notes",
  iLastUpdated: "Time",
};
