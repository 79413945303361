export default {
  chinese: "简体中文",
  banbenhao: "版本",
  rRectType: "类型",
  rRectName: "名称",
  rRectPoints: "坐标",
  rCompanyID: "公司",
  rMore: "备注",
  rSortCode: "排序",
  rImg: "图标",
  group_name: "车组",
  guzhang: "故障",
  xiazai: "下载",
  xiugaimima: "修改密码",
  tuichu: "退出",
  caozuo: "操作",
  zhimingjingbao: "致命警报",
  pilaobaojing: "疲劳报警",
  baojingshijian: "报警事件",
  jinggaozhuyi: "警告注意",
  yichangduandian: "异常断电",
  tuoche: "拖车",
  shipinzhendang: "视频遮挡",
  shijiantongzhi: "事件通知",
  acckaiguanbaobiao: "ACC报表",
  xiangqingtongji: "详情统计",
  jintian: "今天",
  zuotian: "昨天",
  benzhou: "本周",
  shangzhou: "上周",
  benyue: "本月",
  shangyue: "上月",
  xiayue: "下月",
  zidingyi: "自定义",
  changguibaojing: "常规报警",
  dianziweilan: "电子围栏",
  guzhangbaojing: "故障报警",
  cheliangjiashipingfen: "车辆评分",
  lichengtongji: "里程统计",
  shujuliuliang: "数据流量",
  shangxiaxianbaobiao: "上下线报表",
  yonghurizhi: "用户日志",
  fangwenduan: "访问端",
  zhudonganquanbaojing: "AI报警",
  weifenpei: "未分配",
  yifenpei: "已分配",
  simka: "SIM卡",
  shiyongzhuangtai: "使用状态",
  cheliangfenpei: "车辆分配",
  chezufenpei: "车组分配",
  qingxuanzechezu: "请选择车组",
  tianjiachezu: "添加车组",
  xiugaichezu: "修改车组",
  shanchuchezu: "删除车组",
  mingcheng: "名称",
  chezuguanli: "车组管理",
  shanchu: "删除",
  duobianxing: "多边形",
  yuanxing: "圆形",
  juxing: "矩形",
  xianlu: "线路",
  xingqudian: "兴趣点",
  qingxuanzeshanchuxiang: "请选择删除项",
  mingchengbitian: "名称必填",
  tianjia: "添加",
  gengxinchenggong: "更新成功",
  pilaoguize: "疲劳规则",
  jinruguize: "禁入规则",
  jinchuguize: "禁出规则",
  quyuxiansu: "区域限速",
  yejianxingche: "夜间行车",
  dingshipaizhao: "定时拍照",
  qingxuanzegongsi: "请选择公司",
  shurudemimabuyizhi: "输入的密码不一致",
  shipinhuifang: "视频回放",
  wenjianxiazai: "文件下载",
  zhongduanshebei: "设备",
  qingchaxun: "请查询",
  zhaopianchakan: "照片查看",
  dangqiandi: "当前第",
  zhanggong: "张/共",
  zhang: "张",
  chepai: "车牌",
  tupian: "图片",
  cheliangshebei: "设备",
  cheliangpingfen: "车辆评分",

  baojingtongji: "报警统计",
  cipanbaojing: "磁盘报警",
  shipinbaojing: "视频报警",
  sosbaojing: "紧急报警",
  shipinshebeizongshu: "视频设备总数",
  zhuizongqizongshu: "追踪器总数",
  baiduditu: "百度地图",
  gugeditu: "谷歌地图",
  guzhangxinxi: "故障信息",
  gpsbudingwei: "GPS错误",
  cipanzhuangtai: "磁盘状态",
  ditushipinxiangqing: "地图/视频详情",
  dituguiji: "地图轨迹",
  baojijngxiangqing: "报警详情",
  baojingfashengdian: "报警发生点",
  baojingqian10fenzhong: "报警前10分钟",
  baoingqian30fenzhong: "报警前30分钟",
  baojingqian60fenzhong: "报警前60分钟",
  suduquxian: "速度曲线",
  luxianghuifang: "录像回放",
  shishibofang: "实时播放",
  baojingtupian: "报警图片",
  baojingshipin: "报警视频",
  baojingchuli: "报警处理",
  huanchongmiao: "缓冲(秒)",
  shuru: "输入",
  xiaohui: "销毁",
  quxiaojingyin: "取消静音",
  jingyin: "静音",
  yinliang: "音量",
  xuanzhuang: "旋转",
  quanping: "全屏",
  jietu: "截图",
  caozuoanniu: "操作按钮",
  wangsu: "网速",
  xingneng: "性能",
  lipingxuanran: "离屏渲染",
  wanquantianchonglashen: "完全填充(拉伸)",
  dengbisuofang: "等比缩放",
  wanquantianchongweilashen: "完全填充(未拉伸)",
  qingping: "清屏",
  luzhi: "录制",
  zantingluzhi: "暂停录制",
  fengkuangjiazaizhong: "疯狂加载中",
  kadun: "卡顿",
  feichangliuchang: "非常流畅",
  yi: "一",
  er: "二",
  san: "三",
  si: "四",
  wu: "五",
  liu: "六",
  ri: "日",
  liuchang: "流畅",
  xuanze: "选择",
  zanwufuhedexiazaiwenjian: "暂无符合的下载文件",
  xiangqing: "详情",
  daochu: "导出",
  tongji: "统计",
  baobiao: "报表",
  jixu: "继续",
  zanting: "暂停",
  jieshu: "结束",
  dianwokaishi: "点我开始",
  huizhiwancheng: "绘制完成",
  bianjiwancheng: "编辑完成",
  shanchuyibaocundequyu: "删除已保存的区域",
  dianjiditukaishihuizhiduobianxing: "点击地图开始绘制多边形",
  jixuxuanze: "继续选择",
  dianjidiyigedingdianwanchenghuizhi: "点击第一个顶点完成绘制",
  huizhi: "绘制",
  qingchuquanbu: "清除全部",
  zanwuquyukebianji: "暂无区域可编辑",
  xuanzhongyigequyuquqingchu: "选中一个区域去清除",
  bianji: "编辑",
  shanchu: "删除",
  quxiaohuizhi: "取消绘制",
  wancheng: "完成",
  wanchenghuizhi: "完成绘制",
  chexiao: "撤销",
  dianjiquxiaoyichenxiaogenggai: "点击取消以撤消更改",
  tuodongbiaojiyibianjituxing: "拖动标记以编辑图形",
  huizhiwanchengshidiaoyong: "绘制完成时调⽤",
  jinzhihuizhihuizhijieshu: "禁⽌绘制、绘制结束",
  xiazaijindu: "下载进度",

  // zhxx: "综合信息",
  // yt: "云台",
  cph_name: "车牌号",
  // sbid_name: "设备ID",
  ssgs_name: "所属公司",
  sbid: "ID",
  ssgs: "公司",

  // sscd_name: "所属车队",
  // zxzt_name: "在线状态",
  // zhzxsj_name: "在线时间",

  // bjhz_name: "报警汇总",
  zxbjsj_name: "时间",
  yuanyin: "原因",
  tongdao: "通道",
  qiye: "公司",
  zxbjnr_name: "最新报警内容",
  bjwz_name: "位置",
  sjxm_name: "司机姓名",
  sjdh_name: "司机电话",
  // whryxm_name: "维护人员姓名",
  // whrxdh_name: "维护人员电话",
  // bxlx_name: "保险类型",
  // bxdh_name: "保险电话",

  zt_1: "在线",
  zt_0: "离线",
  zt_2: "报警",
  zt_3: "在线",

  item_ylsysp: "预览所有视频",
  item_ksdj: "开始对讲",
  item_ksjt: "开始监听",
  item_xfwzxx: "发送文字信息",
  item_gpssbsj: "GPS上报时间",
  item_clkz: "车辆控制",
  item_gpssbjgsz: "GPS上报间隔设置",
  item_wlllpz: "网络流量配置",
  item_wifixzrwsz: "WIFI下载任务设置",
  item_cp: "重启",
  item_gshcp1: "格式化硬盘",
  item_gshcp2: "格式化SD卡",
  item_jzg: "校准GSENSOR",
  item_sbxx: "设备信息",
  item_sbsj: "设备升级",
  item_xfwj: "下发文件",
  item_wifipzzd: "WIFI配置诊断",
  item_lxsj: "离线升级",
  item_sbcssz: "参数设置",

  pop_sj: "时间",
  pop_sd: "速度",
  pop_wz: "位置",
  pop_lc: "里程",
  pop_zt: "状态",
  pop_bj: "报警",
  pop_sp: "视频",
  pop_dj: "对讲",
  pop_jt: "监听",
  pop_zp: "抓拍",
  pop_gd: "更多",
  pop_dkzzms: "打开追踪模式",
  pop_gbzzms: "关闭追踪模式",
  pop_cssz: "参数配置",
  pop_wqwz: "获取位置",

  // send_success: "保存成功",
  // send_fail: "保存失败",
  gps_0: "(基站定位)",
  gps_1: "(GPS定位)",
  gps_2: "(不定位)",

  // search_veh: "输入设备id",
  // btn_search: "搜索",
  // btn_xssp: "显示视频",
  // btn_ycsp: "隐藏视频",
  // tree_root_name: "所有数据",

  // MaxCarError: "已超过后台配置最大展示车辆数",
  maps: "普通地图",
  satellite: "卫星地图",

  blx: "不录像",
  yz: "右转",
  zz: "左转",
  cc: "刹车",
  dc: "倒车",
  yxh: "无信号",
  xhc: "信号差",
  xhyb: "信号一般",
  xhh: "信号好",
  wx: "卫星",
  yx: "有线",
  wz: "未知",

  // cz: "操作",
  // yes: "确定",
  no: "取消",
  // video_content: "当前播放视频已到达自动关闭视频时间,是否需要延迟时间?",
  bjjb: "报警级别",
  ckmx: "查看明细",
  // yjbj: "一级报警",
  // ejbj: "二级报警",

  // zdaqbj: "AI报警",

  // one: "单窗口",
  // four: "四窗口",
  // six: "六窗口",
  item_info: "车辆信息",
  item_intercom: "开始对讲",
  item_gshcp: "格式化磁盘",
  item_lcsz: "里程设置",
  item_drive: "驾驶设置",
  // top_CompanyID: "请选公司",
  // top_bindid: "请选车组",
  // msgType_4: "无权操作！",
  btnSave: "保存",

  // pernumber: "",
  // pagestat: "",
  // pagefrom: "",
  // pagetext: "",
  // pagetotal: "/",
  // findtext: "查找",
  // procmsg: "处理中，请稍候...",
  // nomsg: "没有记录",

  // panel_title: "基本信息",
  // rRectName: "名称",
  // rRectPoints: "坐标",
  // rCompanyID: "所属公司",
  // rMore: "备注",
  // rSortCode: "排序",
  // rImg: "图标",
  // top_CompanyID: "请选公司",
  // top_bindid: "请选车组",
  // xzcl: "选择车辆",

  track_cz: "查找",
  track_kssj: "开始时间",
  track_jssj: "结束时间",
  track_jj: "间距",
  track_lc: "里程",
  track_zchf: "正常回放",
  track_kshf: "快速回放",
  track_xsgjd: "显示轨迹点",
  track_cx: "查询",
  bangding: "绑定",
  jiebang: "解绑",
  track_bf: "播放",
  tingzhi: "停止",

  track_gjd: "轨迹点",

  track_cph: "车牌号",
  track_sj: "时间",
  track_sd: "速度",
  track_dslc: "里程",
  track_wz: "位置",
  track_zt: "状态",
  track_bj: "报警",

  a_1: "紧急报警",
  a_2: "超速报警",
  a_3: "疲劳报警",
  a_4: "危险预警",
  a_5: "GPS模块故障",
  a_6: "GPS天线断开报警",
  a_7: "GPS天线短路",
  a_8: "主电源欠压报警",
  a_9: "主电源掉电报警",
  a_10: "LCD或显示器故障",

  a_11: "TTS模块故障",
  a_12: "摄像头故障",
  a_13: "道路运输证IC卡模块故障",
  a_14: "超速预警",
  a_15: "疲劳驾驶预警",
  a_16: "",
  a_17: "",
  a_18: "",
  a_19: "当天累计驾驶超时报警",
  a_20: "超时停车报警",

  a_21: "进出区域报警",
  a_22: "进出线路报警",
  a_23: "路段行驶时间不足/过长",
  a_24: "路线偏离报警",
  a_25: "车辆VSS故障",
  a_26: "车辆油量异常",
  a_27: "车辆被盗",
  a_28: "车辆非法点火",
  a_29: "车辆非法位移",
  a_30: "碰撞报警",

  a_31: "侧翻报警",
  a_32: "非法开门报警",
  a_33: "区域超速报警(平台)",
  a_34: "区域低速报警(平台)",
  a_35: "进出区域报警(平台)",
  a_36: "线路偏离报警(平台)",
  a_37: "时间段超速报警(平台)",
  a_38: "时间段低速报警(平台)",
  a_39: "疲劳驾驶报警(平台)",
  a_40: "超时停车报警(平台)",

  a_41: "关键点监控报警(平台)",
  a_42: "线路超速报警(平台)",
  a_43: "线路低速报警(平台)",
  a_44: "道路等级超速报警(平台)",
  a_45: "夜间行车报警(平台)",
  a_46: "",
  a_47: "",
  a_48: "",
  a_49: "",
  a_50: "",

  a_51: "",
  a_52: "",
  a_53: "",
  a_54: "",
  a_55: "",
  a_56: "",
  a_57: "",
  a_58: "",
  a_59: "",
  a_60: "",

  a_61: "",
  a_62: "",
  a_63: "",
  a_64: "",

  s_1: { s0: "ACC关", s1: "ACC开" },
  s_2: { s0: "未定位", s1: "定位" },
  s_3: { s0: "北纬", s1: "南纬" },
  s_4: { s0: "东经", s1: "西经" },
  s_5: { s0: "运营状态", s1: "停运状态" },
  s_6: { s0: "经纬度未经保密插件加密", s1: "经纬度已经保密插件加密" },
  s_7: { s0: "", s1: "" },
  s_8: { s0: "", s1: "" },
  s_9: { s00: "空车", s01: "半载", s01: "", s11: "满载" },
  // 's_10':{'s0':'','s1':''},

  s_11: { s0: "油路正常", s1: "油路断开" },
  s_12: { s0: "电路正常", s1: "电路断开" },
  s_13: { s0: "车门解锁", s1: "车门加锁" },
  s_14: { s0: "门1关", s1: "门1开（前门）" },
  s_15: { s0: "门2关", s1: "门2开（中门）" },
  s_16: { s0: "门3关", s1: "门3开（后门）" },
  s_17: { s0: "门4关", s1: "门4开（驾驶门）" },
  s_18: { s0: "门5关", s1: "门5开（自定义）" },
  s_19: { s0: "未使用GPS定位", s1: "使用GPS定位" },
  s_20: { s0: "未使用北斗定位", s1: "使用北斗定位" },

  s_21: { s0: "未使用GLONASS定位", s1: "使用GLONASS定位" },
  s_22: { s0: "未使用Galileo定位", s1: "使用Galileo定位" },
  s_23: { s0: "", s1: "" },
  s_24: { s0: "", s1: "" },
  s_25: { s0: "", s1: "" },
  s_26: { s0: "", s1: "" },
  s_27: { s0: "", s1: "" },
  s_28: { s0: "", s1: "" },
  s_29: { s0: "", s1: "" },
  s_30: { s0: "", s1: "" },
  s_31: { s0: "", s1: "" },
  s_32: { s0: "", s1: "" },

  s_33: { s0: "近光灯关", s1: "近光灯开" },
  s_34: { s0: "远光灯关", s1: "远光灯开" },
  s_35: { s0: "右转关", s1: "右转开" },
  s_36: { s0: "左转关", s1: "左转开" },
  s_37: { s0: "刹车关", s1: "刹车开" },
  s_38: { s0: "倒档关", s1: "倒档开" },
  s_39: { s0: "雾灯关", s1: "雾灯开" },
  s_40: { s0: "示廓灯关", s1: "示廓灯开" },

  s_41: { s0: "喇叭关", s1: "喇叭开" },
  s_42: { s0: "空调关", s1: "空调开" },
  s_43: { s0: "空档关", s1: "空档开" },
  s_44: { s0: "缓速器关", s1: "缓速器开" },
  s_45: { s0: "ABS关", s1: "ABS开" },
  s_46: { s0: "加热器关", s1: "加热器开" },
  s_47: { s0: "离合器关", s1: "离合器开" },
  s_48: { s0: "", s1: "" },
  s_49: { s0: "", s1: "" },
  s_50: { s0: "", s1: "" },

  s_51: { s0: "", s1: "" },
  s_52: { s0: "", s1: "" },
  s_53: { s0: "", s1: "" },
  s_54: { s0: "", s1: "" },
  s_55: { s0: "", s1: "" },
  s_56: { s0: "", s1: "" },
  s_57: { s0: "", s1: "" },
  s_58: { s0: "", s1: "" },
  s_59: { s0: "", s1: "" },
  s_60: { s0: "", s1: "" },

  s_61: { s0: "", s1: "" },
  s_62: { s0: "", s1: "" },
  s_63: { s0: "", s1: "" },
  s_64: { s0: "", s1: "" },

  e_1: "紧急报警",
  e_2: "超速报警",
  e_3: "疲劳驾驶",
  e_4: "危险预警",
  e_5: "GPS模块故障",
  e_6: "GPS天线未接或被剪断",
  e_7: "GPS天线短路",
  e_8: "主电源欠压",
  e_9: "主电源掉电",
  e_10: "LCD或显示器故障",
  e_11: "TTS模块故障",
  e_12: "摄像头故障",
  e_13: "道路运输证IC卡模块故障",
  e_14: "超速预警",
  e_15: "疲劳驾驶预警",
  e_19: "当天累计驾驶超时",
  e_20: "超时停车",
  e_21: "进出区域",
  e_22: "进出线路",
  e_23: "路段行驶时间不足或过长",
  e_24: "路线偏离报警",
  e_25: "车辆VSS故障",
  e_26: "车辆油量异常",
  e_27: "车辆被盗",
  e_28: "车辆非法点火",
  e_29: "车辆非法位移",
  e_30: "碰撞报警",
  e_31: "侧翻报警",
  e_32: "非法开门报警",

  e_33: "视频丢失报警",
  e_34: "视频遮挡报警",
  e_35: "存储故障报警",
  e_36: "其他视频设备故障报警",
  e_37: "客车超员报警",
  e_38: "异常驾驶报警",
  e_39: "特殊报警录像达到存储阀值报警",

  e_65: "区域超速报警",
  e_66: "区域低速报警",
  e_67: "进出区域报警",
  e_68: "线路偏离报警",
  e_69: "时间段超速报警",
  e_70: "时间段低速报警",
  e_71: "疲劳驾驶报警",
  e_72: "超时停车报警",
  e_73: "关键点监控报警",
  e_74: "线路超速报警",
  e_75: "线路低速报警",
  e_76: "道路等级超速报警",
  e_77: "夜间行车报警",
  e_78: "进区域报警",
  e_79: "出区域报警",

  e_101: "视频丢失报警",
  e_102: "视频遮挡报警",
  e_103: "存储故障报警",
  e_104: "其他视频设备故障报警",
  e_105: "客车超员报警",
  e_106: "异常驾驶行为报警",
  e_107: "特殊报警录像达到存储阀值报警",

  e_201: "区域超速报警",
  e_202: "区域低速报警",
  e_203: "进出区域报警",
  e_204: "线路偏离报警",
  e_205: "时间段超速报警",

  e_206: "时间段低速报警",
  e_207: "疲劳驾驶报警",
  e_208: "超时停车报警",
  e_209: "关键点监控报警",
  e_210: "线路超速报警",
  e_211: "线路低速报警",
  e_212: "道路等级超速报警",
  e_213: "夜间行车报警",
  e_214: "进区域报警",
  e_215: "出区域报警",
  e_216: "拆机报警",
  // e_217: "传感器报警",
  // e_218: "传感器报警",
  // e_219: "IO/3报警",
  // e_220: "IO/4报警",
  // e_221: "IO/5报警",
  // e_222: "IO/6报警",
  // e_223: "IO/7报警",
  // e_224: "IO/8报警",

  e_217: "紧急报警",
  e_218: "前门报警",
  e_219: "中门报警",
  e_220: "后门报警",
  e_221: "右转报警",
  e_222: "左转报警",
  e_223: "刹车报警",
  e_224: "倒车报警",
  //IO1  紧急   IO8  倒车   IO6  左转  IO5  右转   IO7刹车  IO2 前门  IO3中门 IO4

  e_301: "前向碰撞报警",
  e_302: "车道偏离报警",
  e_303: "车距过近报警",
  e_304: "行人碰撞报警",
  e_305: "频繁变道报警",
  e_306: "道路标识超限报警",
  e_307: "障碍物报警",
  e_316: "道路标志识别事件",
  e_317: "主动抓拍事件",
  e_351: "疲劳驾驶报警",
  e_352: "接打电话报警",
  e_353: "抽烟报警",
  e_3541: "分神报警",
  e_3542: "驾驶员异常报警",
  e_354: "分神驾驶报警/长时间不目视前方报警",
  e_355: "驾驶员异常报警/未检测到驾驶员报警",
  e_360: "红外阻断报警",
  e_361: "遮挡报警",
  e_366: "自动抓拍事件",
  e_367: "驾驶员变更事件",
  e_380: "胎压（定时上报）",
  e_401: "后BSD",
  e_402: "左BSD",
  e_403: "右BSD",
  e_308: "驾驶辅助功能失效报警",
  e_356: "未系安全带",
  e_357: "驾驶员行为监测功能失效报警",
  e_381: "胎压过高报警",
  e_382: "胎压过低报警",
  e_383: "胎温过高报警",
  e_384: "传感器异常报警",
  e_385: "胎压不平衡报警",
  e_386: "慢漏气报警",
  e_387: "电池电量低报警",
  e_431: "急加速报警",
  e_432: "急减速报警",
  e_433: "急转弯报警",
  e_434: "怠速报警",
  e_435: "异常熄火报警",
  e_436: "空挡滑行报警",
  e_437: "发动机超转报警",

  e_624: "前BSD",

  ntpfuwuqi: "NTP服务器",
  ntpduankou: "NTP端口",

  gjd: "轨迹点",
  item_addr: "获取位置",
  // zt_1: "在线",
  // zt_0: "离线",
  // zt_2: "报警",
  // gps_0: "(基站定位)",
  // gps_1: "(GPS定位)",
  // gps_2: "(不定位)",

  zy: "注意：只有在线的设备才能发送",
  btnSend: "发送",
  table_title: "发送列表",
  table_zd: "设备",
  table_sj: "时间",
  table_zt: "状态",
  table_nr: "内容",
  empty_nr: "发送内容",
  fanyi1: "确认要格式化硬盘吗？",
  fanyi2: "确认要格式化SD卡？",
  fanyi3: "确认要校准G-SENSOR吗？",
  fanyi4: "上报时间间隔：",
  fanyi5: "休眠间隔设置：",
  fanyi6: "(单位：秒)",
  fanyi7: "车门状态",
  fanyi8: "车门关",
  fanyi9: "车门开",
  fanyi10: "电路状态",
  fanyi11: "电路关",
  fanyi12: "电路开",
  fanyi13: "对讲",
  fanyi14: "监听",
  fanyi15: "里程",
  fanyi16: "单位:千米",
  fanyi17: "最高速度",
  fanyi18: "超速持续时间",
  fanyi19: "单位:秒",
  fanyi20: "最小休息时间",
  fanyi21: "当天累计驾驶时间",
  fanyi22: "连续驾驶时间",
  fanyi23: "最长停车时间",
  fanyi24: "基本信息",
  fanyi25: "厂家编号",
  fanyi26: "设备信息",
  fanyi27: "版本",
  fanyi28: "IMEI",
  fanyi29: "无线&WIFI",
  fanyi30: "网络类型",
  fanyi31: "无线信号",
  fanyi32: "网络地址",
  fanyi33: "wifi名称",
  fanyi34: "wifi信号",
  fanyi35: "录像状态",
  fanyi36: "通道名称",
  fanyi37: "视频录像",
  fanyi38: "视频丢失",
  fanyi39: "存储",
  fanyi40: "磁盘",
  fanyi41: "总容量",
  fanyi42: "剩余容量",
  fanyi43: "设置",
  fanyi44: "获取",
  fanyi45: "系统设置",
  fanyi46: "终端设置",
  fanyi47: "系统时钟",
  fanyi48: "电源管理",
  fanyi49: "录像设置",
  fanyi50: "基本设置",
  fanyi51: "编码设置",
  fanyi52: "显示设置",
  fanyi53: "存储设置",
  fanyi54: "网络设置",
  fanyi55: "4G设置",
  fanyi56: "中心设置",
  fanyi57: "传感器设置",
  fanyi58: "速度报警",
  fanyi59: "IO报警",
  fanyi60: "加速度",
  fanyi61: "温度报警",
  fanyi62: "电压报警",
  fanyi63: "日期格式",
  fanyi64: "待机模式",
  fanyi65: "校时选项",
  fanyi66: "时区",
  fanyi67: "夏令时",
  fanyi68: "开始时间",
  fanyi69: "结束时间",
  fanyi70: "偏移时间(分)",
  fanyi71: "电源模式",
  fanyi72: "延时关机时间",
  fanyi73: "视频制式",
  fanyi74: "摄像头类型",
  fanyi75: "录像模式",
  fanyi76: "音频类型",
  fanyi77: "报警预录时间",
  fanyi78: "报警延迟时间",
  fanyi79: "报警录像锁定",
  fanyi80: "秒",
  fanyi81: "天",
  fanyi82: "通道",
  fanyi83: "码流类型",
  fanyi84: "录像开关",
  fanyi85: "分辨率",
  fanyi86: "帧率",
  fanyi87: "画质",
  fanyi88: "录音",
  fanyi89: "镜像",
  fanyi90: "翻转",
  fanyi91: "磁盘类型",
  fanyi92: "录像模式",
  fanyi93: "网络制式",
  fanyi94: "认证模式",
  fanyi95: "中心号码",
  fanyi96: "用户名",
  fanyi97: "密码",
  fanyi98: "服务器",
  fanyi99: "开关",
  fanyi100: "连接模式",
  fanyi101: "服务器地址",
  fanyi102: "端口号",
  fanyi103: "域名",
  fanyi104: "类型",
  fanyi105: "报警录像",
  fanyi106: "联动输出",
  fanyi107: "报警阈值",
  fanyi108: "防抖时间",
  fanyi109: "速度来源",
  fanyi110: "速度单位",
  fanyi111: "IO通道",
  fanyi112: "报警模式",
  fanyi113: "报警延时",
  fanyi114: "预览通道",
  fanyi115: "温度单位",
  fanyi116: "低电压关机延时",
  fanyi117: "分",
  fanyi118: "设备ID",
  fanyi119: "手机号",
  fanyi120: "上报时间间隔",

  channel_no: "通道号",
  file_pos: "文件位置",
  wjdx: "文件大小",
  bjsj: "报警开始时间",
  ejsj: "报警结束时间",
  bjnr: "报警内容",
  wlmc: "围栏名称",
  til1: "操作",

  dName: "设备ID",
  fChannelNo: "通道号",
  fFileStartTime: "开始日期",
  fFileEndTime: "结束日期",
  fState: "上传进度",
  til8: "时间",
  choose1: "上传中",
  choose2: "上传成功",
  choose3: "上传超时",
  choose4: "上传失败",
  choose5: "未上传",
  mMediaFileName: "文件名",
  num: "报警次数",
  qingshuruchaxunguanjianzi: "查询车牌或ID",
  ////////////////////////////////////////////////
  qingxuanze: "请选择",
  gongxuanze: "共选择",
  liangche: "辆车",
  qingxuanzecheliang: "请选择车辆",
  zanwujieguo: "暂无结果",
  qingshuruzhengquedekaishishijianhejieshushijian:
    "请输入正确的开始时间和结束时间",
  quanbu: "全部",
  zaixian: "在线",
  lixian: "离线",
  baojing: "报警",
  guanzhu: "关注",
  huise: "灰色",
  lvse: "绿色",
  shangxian: "上线",
  wubaojing: "无报警",
  zhengchang: "正常",
  yousudu: "有速度",
  hongse: "红色",
  youbaojing: "有报警",
  zise: "紫色",
  meiyouxinhao: "没有信号",
  huangse: "黄色",
  guanbi: "关闭",
  lanse: "蓝色",
  suduwei: "速度为",
  wangluo: "网络",
  weixing: "卫星",
  dianhuo: "点火",
  yue: "粤",
  wuxian: "无线",
  yingli: "英里",
  xiaoshi: "小时",
  gongli: "公里",
  dakai: "打开",
  danping: "单屏",
  siping: "四屏",
  jiuping: "九屏",
  kaiguan: "开关",
  shipin: "视频",
  guiji: "轨迹",
  huifang: "回放",
  geshihuayingpan: "格式化硬盘",
  geshihua: "格式化SD卡",
  // ka: "卡",
  biaozhun: "校准GSENSOR",
  geshihualeixing: "格式化类型",
  querenyaogeshihuayingpanma: "确认要格式化硬盘吗？",
  querenyaozhongqima: "确认要重启吗？",
  zhongduan: "设备",
  wangluodizhi: "网络地址",
  youjiancaidanbufen: "右键菜单部分",
  jiazaijiaobenku: "加载脚本库",
  yijibaojing: "一级报警",
  erjibaojing: "二级报警",
  guge: "谷歌",
  baidu: "百度",
  xingshi: "行驶",
  daisu: "怠速",
  tingche: "停车",
  weidingwei: "未定位",
  dian: "点",
  juxingquyu: "矩形区域",
  xian: "线",
  yuanxingquyu: "圆形区域",
  sudu: "速度",
  sdka: "SD卡",
  buluxiang: "不录像",
  licheng: "里程",
  zhuangtai: "状态",
  miaoshu: "描述",
  yingpan: "硬盘",
  xitongguanli: "系统管理",
  xinxi: "信息",
  zhongduanshezhi: "终端设置",
  xitongshizhong: "系统时钟",
  xialingshikaiguan: "夏令时",
  dianyuanguanli: "电源管理",
  fujiashezhi: "附加设置",
  luxiangshezhi: "录像设置",
  jibenshezhi: "基本设置",
  zhumaliu: "主码流",
  zimaliu: "子码流",
  dingshiluxiang: "定时录像",
  cunchushezhi: "存储设置",
  xinxidiejia: "信息叠加",
  shezhi: "设置",
  wangluoshezhi: "网络设置",
  zhongxinshezhi: "中心设置",
  bendishezhi: "本地设置",
  bohaoshezhi: "拨号设置",
  shengjifuwuqi: "升级服务器",
  baojinghewaishe: "报警和外设",
  sudubaojing: "速度报警",
  jiasudu: "加速度",
  yidongzhence: "移动侦测",
  dianyabaojing: "电压报警",
  chuankouguanli: "串口管理",
  yuntaikongzhi: "云台控制",
  yindaochengxu: "引导程序",
  neihechengxu: "内核程序",
  wenjianxitong: "文件系统",
  yingyongchengxu: "应用程序",
  danpianji: "单片机",
  yingjianbanben: "硬件版本",
  shebeihao: "设备ID",
  shoujihao: "手机号",
  chepaihao: "车牌号",
  zhongxin: "服务器",
  xieyi: "协议",
  zhongxinxieyi: "服务器1协议",
  zhongxinxieyi2: "服务器2协议",
  xiaoshixuanxiang: "校时选项",
  shoudong: "手动",
  xiaozhun: "校准",
  gpsxiaozhun: "GPS校准",
  chaoshishijian: "超时时间",
  shiqu: "时区",
  shijian: "时间",
  fuwuqi: "服务器",
  duankou: "端口",
  kaiqi: "开启",
  kaishishijian: "开始时间",
  yiyue: "一月",
  eryue: "二月",
  sanyue: "三月",
  siyue: "四月",
  wuyue: "五月",
  liuyue: "六月",
  qiyue: "七月",
  bayue: "八月",
  jiuyue: "九月",
  shiyue: "十月",
  shiyiyue: "十一月",
  shieryue: "十二月",
  diyizhou: "第一周",
  dierzhou: "第二周",
  disanzhou: "第三周",
  disizhou: "第四周",
  diwuzhou: "第五周",
  zhouyi: "周一",
  zhouer: "周二",
  zhousan: "周三",
  zhousi: "周四",
  zhouwu: "周五",
  zhouliu: "周六",
  zhouri: "周日",
  jieshushijian: "结束时间",
  pianyishijian: "偏移时间(分)",
  dianyuanmoshi: "电源模式",
  dingshi: "定时",
  yanshiguanji: "延时关机",
  fen: "分",
  xiumianshangbao: "休眠上报",
  pingbaoyanshi: "屏保延时",
  kaijishijian: "开机时间",
  guanjishijian: "关机时间",
  luxiangyanshi: "录像延时",
  xihuoluxiang: "熄火录像",
  zidongweihu: "自动维护",
  weihushijian: "维护时间",
  lianxujiashichaoshishijian: "连续驾驶超时时间",
  dangrileijijiashichaoshi: "当日累计驾驶超时",
  shipinzhishi: "视频制式",
  luxiangmoshi: "录像模式",
  kaijiluxiang: "开机录像",
  baojingluxiang: "报警录像",
  yinpinleixing: "音频类型",
  tongdao: "通道",
  fenbianlv: "分辨率",
  zhenglv: "帧率",
  huazhi: "画质",
  luxiang: "录像",
  jingxiang: "镜像",
  luyin: "录音",
  zuoyoujingxiang: "左右镜像",
  shangxiajingxiang: "上下镜像",
  shangxia: "上下",
  baojingyulu: "报警预录",
  miao: "秒",
  shangxiazuoyoujingxiang: "上下+左右镜像",
  baojingluxiangyanshi: "报警录像延时",
  baojingwenjianbaohu: "报警文件保护",
  tian: "天",
  baojingwenjianshangchuan: "报警文件上传",
  cipan: "磁盘",
  wu: "无",
  zhuluxiang: "主录像",
  jingxiangluxiang: "镜像录像",
  beixuan: "备选",
  wangluoleixing: "网络类型",
  jierudian: "接入点",
  zhongxinhaoma: "中心号码",
  yonghuming: "用户名",
  mima: "密码",
  bianhao: "IO",
  mingcheng: "名称",
  dianping: "电平",
  yanshi: "延时",
  fangdou: "防抖",
  baojingliandong: "报警联动",
  jinji: "紧急",
  qianmen: "前门",
  zhongmen: "中门",
  houmen: "后门",
  youzhuan: "右转",
  zuozhuan: "左转",
  shache: "刹车",
  daoche: "倒车",
  leixing: "类型",
  zuozhi: "阈值",
  chaoshitingche: "超时停车",
  disuyujing: "低速预警",
  disubaojing: "低速报警",
  chaosuyujing: "超速预警",
  chaosubaojing: "超速报警",
  sudulaiyuan: "速度来源",
  cheliang: "车辆",
  hunhe: "混合",
  sududanwei: "速度单位",
  maichongxishu: "脉冲系数",
  jijiasu: "急加速",
  jijiansu: "急减速",
  jizhuanwan: "急转弯",
  pengzhuang: "碰撞",
  cefan: "侧翻",
  sanzhou: "三轴",
  liuzhou: "六轴",
  daijimoshi: "待机模式",
  putong: "普通",
  zimaliuluxiang: "子码流录像",
  beifenfuwuqi: "备份服务器",
  yuming: "域名",
  didianping: "低电平",
  gaodianping: "高电平",
  diwen: "低温",
  gaowen: "高温",
  sheshidu: "摄氏度",
  huashidu: "华氏度",
  didianya: "低电压",
  jianting: "监听",
  laichufaguanbiyoujiancaidan: "触发关闭右键菜单",
  diaoyongguanbicaidandefangfa: "调用关闭菜单的方法",
  diaoyong: "调用",
  zhongdeshijian: "中的事件",
  fangda: "放大",
  suoxiao: "缩小",
  huoqushujushangxian: "获取数据上线",
  qingkongditu: "清空地图",
  panduanyouche: "判断有车",
  chulizuobiancheliangshifouxuanzhong: "处理左边车辆是否选中",
  nan: "男",
  guanzhuchenggong: "关注成功",
  quxiaoguanzhu: "取消关注",
  xiafaguanbi: "下发关闭",
  guanbiyoujiancaidan: "关闭右键菜单",
  xianpanduansuoyoushipinshifouzhengzaibofang: "先判断所有视频是否正在播放",
  xiaohuishipinhefasongguanbishipin: "销毁视频和发送关闭视频",
  xiafabofangshipinmingling: "下发播放视频命令",
  shipinzhengzaibofang: "视频正在播放",
  shipinmeibofang: "视频没播放",
  genjurongqidekuangaohewanggebujudelieshujisuanmeigequyudekuanhegao:
    "根据容器的宽高和网格布局的列数计算每个区域的宽和高",
  shezhimeigequyudekuanhegao: "设置每个区域的宽和高",
  shebeilixian: "设备离线",
  minglingxiafachenggong: "命令下发成功",
  fasongchenggong: "发送成功",
  weiluxiang: "未录像",
  diushi: "丢失",
  shujucunchuqilaifangbianyihoudiaoyong: "数据存储起来方便以后调用",
  meiyouchulifangshi: "没有处理方式",
  caozuochenggong: "操作成功",
  wenbenxinxifasongchenggong: "文本信息发送成功",
  yinpin: "音频",
  buxuyaodiaoyongxiamiandefangfa: "不需要调用下面的方法",
  shishijiankongyemianshengchengbaojingxinxi: "实时监控页面生成报警信息",
  canshushezhi: "参数设置",
  canshushezhihuodeshuju: "参数设置获得数据",
  qingshezhizhengquedezuigaosudu: "请设置正确的最高速度",
  xuyaozhuanwei: "需要转为",
  jinzhi: "进制",
  qingshezhizhengquedechaosuchixushijian: "请设置正确的超速持续时间",
  qingshezhizhengquededangtianleijijiashishijian:
    "请设置正确的当天累计驾驶时间",
  qingshezhizhengquedelianxujiashishijian: "请设置正确的连续驾驶时间",
  qingshezhizhengquedezuichangtingcheshijian: "请设置正确的最长停车时间",
  qingshezhizhengquedezuixiaoxiuxishijian: "请设置正确的最小休息时间",
  qingtianxieduiyingdeshuzhi: "请填写对应的数值",
  duijianggongneng: "对讲功能",
  shangbaoshijian: "上报时间",
  guolvkongge: "过滤空格",
  bunnegxiaoyu0huozhedayu0: "不能小于0或者等于0",
  liangxiangshujubunengquanbuweikong: "两项数据不能全部为空",
  zhinengdayu: "只能大于",
  bunengxiaoyu: "不能小于",
  huozhedengyu: "或者等于",
  qingshuruxiafaneirong: "请输入下发内容",
  ruo: "弱",
  suoshugongsi: "所属公司",
  houtaixuanzhongzhijiezhanshidizhi: "后台选中直接展示地址",
  jiuzhijiezhanshidizhidaima: "就直接展示地址代码",
  dingwei: "定位",
  ditu: "地图",
  yingxiang: "影像",
  biaozhu: "标注",
  chulitongdaoshuliang: "处理通道数量",
  fasong: "发送",
  qingqiu: "请求",
  kaiqishubiaogunlunsuofang: "开启鼠标滚轮缩放",
  yangshikeyizijidingyi: "样式可以自己定义",
  qbykycx: "请不要跨月查询",
  shipinzhedang: "视频遮挡报警",
  user: "用户",
  auth_1: "系统管理员",
  auth_2: "公司管理员",
  auth_3: "车队管理员",
  auth_4: "常规用户",
  e_: "",
  sfjxgk: "您是否继续观看视频",
  jx: "继续",
  gbts: "关闭",
};
