export default {
  carTitle: "创建车辆",
  vPlate: "车牌",
  vCompanyID: "公司",
  vBobyColor: "车辆颜色",
  vDeviceID: "设备ID",
  // vSimNo: "SIM卡",
  vVehType: "车辆类型",
  vInstaller: "安装人员",
  vStartServDate: "开始日期",
  vStopServDate: "结束日期",
  vState: "使用状态",
  vLastUpdated: "时间",
  sMonthFlow: "流量限制(M)",
  sResidualFlow: "已用流量(M)",
  vxlh: "序列号",
  vxh: "型号",
  sMonthDay: "流量重置日",
  dType: "设备类型",
  dName: "设备ID",
  dChannelNum: "通道数",
  dPara: "通道名称",
  vIcoID: "车辆图标",
  sNo: "SIM卡",
  // delete_title: "删除车辆",
  // delete_content: "是否将选择的车辆删除？",

  // vPlateColorID: "车牌颜色",

  // vChannelCount: "通道数量",
  // vProductNo: "终端生产编号",
  // vPassword: "认证的密码",
  // vOwnNo: "编号",
  // vOwnerName: "车主姓名",
  // vOwnerBobyNo: "车主身份证号",
  // vOwnerAddress: "车主住址",
  // vOwnerSex: "车主性别",
  // vOwnerEmail: "车主邮箱",
  // vOwnerLinkTel: "车主手机号码用",
  // vFrameNo: "发动机号码",
  // vEngineNo: "车架号",

  // vTradeType: "运输行业类型",

  // vState_1: "正常",
  // vState_0: "关闭",
  // vTermID: "终端ID后7位",
  // vTermFacID: "生产厂家ID",
  // vTermFacName: "厂家名称",
  // vRegisterNo: "授权码",
  // vTermVersion: "终端版本",
  // vSortCode: "排序",
  // vMore: "备注",

  // cpys: [
  //   ["1", "蓝色"],
  //   ["2", "黄色"],
  //   ["3", "黑色"],
  //   ["4", "白色"],
  //   ["9", "其他"],
  // ],
  // btn_select: "选择车辆",
  // unselect_sex: "未选择",
  // search_select_array: [
  //   ["1", "未分配"],
  //   ["2", "已分配"],
  // ],
  // search_company: "选择车组",
  // btn_bind: "绑定车辆",
  // set_success: "操作成功",

  // select_num: "选择数量",
  // btn_kstj: "快速添加",
  // btn_ksdr: "批量导入",

  // cz: "车组",
  // qb: "全部",
  // cx: "查询",
  // xzmb: "下载模板",
  // zt: "状态",

  // export: "导出",
};
