export default {
  Title: "Add User",

  uUserName: "User",
  uPwd: "Password",
  cPwd: "Confirm",

  uRoleID: "Role Name",
  uTrueName: "Name",
  uTel: "Tel",
  uRemark: "​Notes",
  uIsLogin: "Status",
  zhengchang: "ON",
  guanbi: "OFF",
  yes: "Yes",
  no: "No",
  top: "Permissions",
  uAddTime: "Time",
  uEmail: "Email",
  uCompanyID: "Company",
  uExpireTime: "Validity Time",
  uIsAdministrator: "Role Type​",
  uIsExpire: "Validity",
  // uNum: "Vehicle",
  uIsSingleLogin: "Single Login",

  uIsEditPwd: "Ban PSD-Change",
  bdcz: "Fleet",
  bdcl: "Vehicle",

  // uUserLevel: "User Level",
  // uUserLevel_select: [
  //   ["1", "I"],
  //   ["2", "II"],
  //   ["3", "III"],
  //   ["4", "V"],
  //   ["5", "IV"],
  // ],
  // btnSave: "Save​",

  // uIsAdministrator_select1: [
  //   ["2", "Company Admin"],
  //   ["3", "Fleet Admin"],
  //   ["4", "Regular"],
  // ],
  // uIsAdministrator_select2: [
  //   ["3", "Fleet Admin"],
  //   ["4", "Regular"],
  // ],
  // pwdNOSame: "The passwords you entered twice do not match",
  // username_rule: "Username: 6 to 16 characters (letters, numbers, underscores)",
  // userpwd_rule: "At least 6 characters, consisting of numbers, uppercase letters, and lowercase letters",

  // uVelIDS_error: "The current user has been associated with other company vehicles, please disassociate and try again!",
};
