// 动态加载外部链接
export default function loadScript(id, url) {
  return new Promise((resolve) => {
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.src = url;
    script.id = id;
    // IE
    if (script.readyState) {
      script.onreadystatechange = () => {
        if (script.readyState == "loaded" || script.readyState == "complete") {
          script.onreadystatechange = null;
          resolve();
        }
      };
    } else {
      // 其他浏览器
      script.onload = function () {
        resolve();
      };
    }
    script.onerror = () => reject(new Error(`Load script from ${url} failed`));
    document.getElementsByTagName("head")[0].appendChild(script);
  });
}

// export default function loadScript(url) {
//   return new Promise((resolve, reject) => {
//     const script = document.createElement('script')

//     script.onload = () => resolve()

//     script.onerror = () => reject(new Error(`Load script from ${url} failed`))

//     script.src = url
//     const head = document.head || document.getElementsByTagName('head')[0]
//     ;(document.body || head).appendChild(script)
//   })
// }
