import { createI18n } from "vue-i18n";

function loadLocaleMessages() {
  const locales = require.context(".", true, /[A-Za-z0-9-_,\s]+\/index\.js$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    messages[key.replace("./", "").replace("/index.js", "")] =
      locales(key).default;
  });
  // console.log(messages);
  return messages;
}

const i18n = new createI18n({
  locale: localStorage.lang || "cn",
  fallbackLocale: localStorage.lang || "cn",
  globalInjection: true,
  legacy: false, // you must specify 'legacy: false' option
  messages: loadLocaleMessages(),
});

export default i18n;
