import VueCookies from "vue-cookies";

let tabs_str = VueCookies.get("gps_tabs");
let tabs_json = [
  {
    title: "首页",
    name: "Index",
  },
];
if (tabs_str != null && tabs_str != "") {
  tabs_json = JSON.parse(tabs_str);
}

let tabs_name_str = VueCookies.get("gps_tabsName");
let tabs_name = "Index";

if (tabs_name_str != null && tabs_name_str != "") {
  tabs_name = tabs_name_str;
}

let menuList_str = VueCookies.get("gps_menuList");
let menuList_ay = [];
if (menuList_str != null && menuList_str != "" && menuList_str != "undefined") {
  menuList_ay = JSON.parse(menuList_str);
}

// let hasRoutes_str = VueCookies.get("hasRoutes_");
// let hasRoutes = false;
// if (hasRoutes_str != null && hasRoutes_str != "") {
//   hasRoutes = hasRoutes_str == "1" ? true : false;
// }

export default {
  state: {
    menuList: menuList_ay,
    permsList: [],

    hasRoutes: false,

    editableTabsValue: tabs_name,
    editableTabs: tabs_json,
  },
  mutations: {
    setMenuList(state, menus) {
      state.menuList = menus;
      VueCookies.set("gps_menuList", JSON.stringify(menus));
      console.log("menuList", "set", menus);
    },
    setPermList(state, perms) {
      state.permsList = perms;
    },
    changeRouteStatus(state, hasRoutes) {
      state.hasRoutes = hasRoutes;
      VueCookies.set("hasRoutes", hasRoutes ? 1 : 0);
    },

    addTab(state, tab) {
      let index = state.editableTabs.findIndex((e) => e.name === tab.name);

      if (index === -1) {
        state.editableTabs.push({
          title: tab.title,
          name: tab.name,
        });
      }

      state.editableTabsValue = tab.name;

      VueCookies.set("gps_tabs", JSON.stringify(state.editableTabs));
      VueCookies.set("gps_tabsName", tab.name);
    },

    removeTab(state, tab) {
      console.log("removeTab", tab);
      let index = state.editableTabs.findIndex((e) => e.name === tab.name);

      if (index > -1) {
        state.editableTabs.splice(index, 1);
      }
      VueCookies.set("gps_tabs", JSON.stringify(state.editableTabs));
    },

    setTabName(state, name) {
      state.editableTabsValue = name;
      VueCookies.set("gps_tabsName", name);
    },

    resetState: (state) => {
      state.menuList = [];
      state.permList = [];

      state.hasRoutes = false;
      state.editableTabsValue = "Index";
      state.editableTabs = [
        {
          title: "首页",
          name: "Index",
        },
      ];
    },
  },
};
