export default {
  dtsz: "地图设置",
  dwsz: "单位设置",
  spsz: "视频设置",

  mrdt: "默认地图",
  ge: "谷歌地图",
  bd: "百度地图",
  osm: "open street map",
  gekey: "谷歌Key",
  bdkey: "百度Key",
  maxCar: "最大显示车辆数",
  kq: "开启",
  gb: "关闭",
  zdhqwz: "自动获取位置",
  sytx: "监控页面报警声音提醒",
  sddw: "单位",
  gbsj: "自动关闭视频时间",

  // smtppz: "SMTP服务器配置",

  // dtkey: "地图秘钥（Key）",

  // gets: "请填写谷歌地图的秘钥",

  // bdts: "请填写百度地图的秘钥",
  // send: "立即提交",
  // reset: "重置",

  // gbsjts: "请填写自动关闭视频时间",
  // dw: "单位：Min",
  // smtpsz: "SMTP设置",
  // form: "发件人",
  // formts: "请填写发件人",
  // showname: "展示名称",
  // showts: "请填写展示名称",
  // user: "用户名称",
  // userts: "请填写用户名称",
  // pwd: "密码",
  // dz: "SMTP地址",
  // dzts: "请填写SMTP地址",
  // dk: "SMTP端口",
  // dkts: "请填写SMTP端口",
  // zt: "主题",
  // ztts: "请填写主题",
  // jmlx: "加密类型",
  // bjm: "不加密",
  // csyx: "测试邮箱",
  // ts: "请设置正确的自动关闭视频时间",
  // czcg: "操作成功",
  // czsb: "操作失败",
  // nrts: "请填写内容",
  // sjrts: "请填写收件人",
  // sendemail: "发送",
  // mmts: "请填写密码",
  // sjr: "收件人",
  // nr: "内容",
  // fscg: "发送成功",
  // fssb: "发送失败",
  // dtpz: "地图配置",

  // maxCarTitle: "请填写百度地图最大显示车辆数",
  // carzhuyi: "*：这里指的是百度地图",

  // maxCarError: "请设置正确的最大展示车辆数",
};
