<template>
  <el-dialog
    :title="$t('commKey.xiazaijiandu')"
    v-model="dialogFormVisible"
    :close-on-click-modal="false"
    :append-to-body="true"
    width="1000px"
    @close="clickFun('close')"
    @open="init_data"
  >
    <div class="tab_box">
      <div
        @click="changeTab('ftp')"
        :class="['tab_item', act == 'ftp' ? 'act' : '']"
      >
        {{ $t("map.pop_sp") }}
      </div>
      <div
        @click="changeTab('pic')"
        :class="['tab_item', act == 'pic' ? 'act' : '']"
      >
        {{ $t("map.tupian") }}
      </div>
    </div>
    <div v-if="act == 'ftp'">
      <div class="search">
        <div class="it">
          <el-date-picker
            clearable
            v-model="stime"
            type="datetime"
            :placeholder="$t('map.zxbjsj_name')"
            value-format="YYYY-MM-DD HH:mm:ss"
          />
        </div>
        <div class="it">
          <el-date-picker
            clearable
            v-model="etime"
            type="datetime"
            :placeholder="$t('map.zxbjsj_name')"
            value-format="YYYY-MM-DD HH:mm:ss"
          />
        </div>
        <div class="it">
          <el-select clearable v-model="state" :placeholder="$t('map.xuanze')">
            <el-option
              v-for="item in optionsType"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </div>
        <div class="it">
          <el-input
            v-model="dev_name"
            placeholder="Plate or ID"
            clearable
            @click="click_ftp"
            @clear="clear_ftp"
          ></el-input>
        </div>
        <div class="it">
          <el-button type="primary" @click="search_ftp">
            {{ $t("map.track_cx") }}</el-button
          >
        </div>
      </div>
      <el-table :data="dataList_ftp" border stripe :height="height">
        <el-table-column
          align="center"
          v-for="col in columns_ftp"
          :key="col.field"
          :label="col.label"
          :width="col.width"
        >
          <template #default="{ row }">
            <span v-if="col.type == 'fun'" v-html="col.render(row)"> </span>
            <span v-else>{{ row[col.field] }}</span>
          </template>
        </el-table-column>
        <el-table-column
          fixed="left"
          :label="$t('commKey.Actions')"
          width="70px"
          v-if="tableOps_ftp.length > 0"
        >
          <template #default="{ row }">
            <span class="gps-span" v-for="(op, idx) in tableOps_ftp" :key="idx">
              <el-button
                v-if="op.opType == 'btn'"
                :icon="op.icon"
                round
                :type="op.type"
                @click="op.click && op.click(row, callbackFun)"
                >{{ op.name }}</el-button
              >
              <el-popconfirm
                :title="$t('commKey.CONFIRMTITLE')"
                v-if="op.opType == 'del'"
                @confirm="op.click && op.click(row, callbackFun)"
              >
                <template #reference>
                  <el-button :icon="op.icon" round :type="op.type">{{
                    op.name
                  }}</el-button>
                </template>
              </el-popconfirm>
            </span>
          </template>
        </el-table-column>
      </el-table>
      <div class="gps-page">
        <el-pagination
          :currentPage="query_ftp.page"
          :page-size="query_ftp.rp"
          :page-sizes="[20, 50, 100, 200, 300, 400]"
          layout="total, prev, pager, next"
          :total="total_ftp"
          @size-change="handleSizeChangeFtp"
          @current-change="handleCurrentChangeFtp"
        />
      </div>
    </div>
    <div v-if="act == 'pic'">
      <div class="search">
        <div class="it">
          <el-date-picker
            clearable
            v-model="begintime"
            type="datetime"
            :placeholder="$t('map.zxbjsj_name')"
            value-format="YYYY-MM-DD HH:mm:ss"
            format="YYYY-MM-DD HH:mm:ss"
          />
        </div>
        <div class="it">
          <el-date-picker
            clearable
            v-model="endtime"
            type="datetime"
            :placeholder="$t('map.zxbjsj_name')"
            value-format="YYYY-MM-DD HH:mm:ss"
            format="YYYY-MM-DD HH:mm:ss"
          />
        </div>
        <div class="it">
          <el-input
            v-model="dev_name"
            placeholder="Plate or ID"
            clearable
            @click="dialogFormVisible_cph = true"
            @clear="clear_ftp"
          ></el-input>
        </div>
        <div class="it">
          <el-button type="primary" @click="search_pic">{{
            $t("map.track_cx")
          }}</el-button>
        </div>
      </div>
      <el-table :data="dataList_pic" border stripe :height="height">
        <el-table-column
          align="center"
          v-for="col in columns_pic"
          :key="col.field"
          :label="col.label"
          :width="col.width"
        >
          <template #default="{ row }">
            <span v-if="col.type == 'fun'" v-html="col.render(row)"> </span>
            <span v-else>{{ row[col.field] }}</span>
          </template>
        </el-table-column>
        <el-table-column
          fixed="left"
          :label="$t('commKey.Actions')"
          width="70px"
          v-if="tableOps.length > 0"
        >
          <template #default="{ row }">
            <span class="gps-span" v-for="(op, idx) in tableOps" :key="idx">
              <el-button
                v-if="op.opType == 'btn'"
                :icon="op.icon"
                round
                :type="op.type"
                @click="op.click && op.click(row, callbackFun)"
                >{{ op.name }}</el-button
              >
              <el-popconfirm
                :title="$t('commKey.CONFIRMTITLE')"
                v-if="op.opType == 'del'"
                @confirm="op.click && op.click(row, callbackFun)"
              >
                <template #reference>
                  <el-button :icon="op.icon" round :type="op.type">{{
                    op.name
                  }}</el-button>
                </template>
              </el-popconfirm>
            </span>
          </template>
        </el-table-column>
      </el-table>
      <div class="gps-page">
        <el-pagination
          :currentPage="query_pic.page"
          :page-size="query_pic.rp"
          :page-sizes="[20, 50, 100, 200, 300, 400]"
          layout="total, prev, pager, next"
          :total="total_pic"
          @size-change="handleSizeChangePic"
          @current-change="handleCurrentChangePic"
        />
      </div>
    </div>
    <el-dialog
      :title="$t('map.cheliang')"
      v-model="dialogFormVisible_cph"
      :close-on-click-modal="false"
      width="600px"
    >
      <el-input
        clearable
        v-model="query_key"
        :placeholder="$t('map.qingshuruchaxunguanjianzi')"
        @input="onQueryChanged"
      />
      <el-tree-v2
        highlight-current
        ref="treeRef_report"
        :data="treeDataMain"
        :height="treeH"
        :props="propoption"
        node-key="gps_id"
        :default-expanded-keys="defaultExpandedKeys"
        :filter-method="filterMethod"
        @node-click="treeclick"
      >
        <template #default="{ node, data }">
          <span class="prefix"
            ><img :src="data.icon" alt="" style="width: 17px; height: 17px"
          /></span>
          <span style="color: #333">{{ node.label }}</span>
        </template>
      </el-tree-v2></el-dialog
    >
  </el-dialog>
</template>
<script>
import moment from "moment";
export default {
  emits: ["ErpDialogEvent"],
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    isShow(val) {
      this.dialogFormVisible = val;
    },
  },
  data() {
    // 获取当前日期
    let now = new Date();

    // 设置时间为 00:00:00
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    now.setMilliseconds(0);

    // 获取当天开始时间
    // let startOfDay = now.getTime();

    let end_now = new Date();
    // 设置时间为 23:59:59
    end_now.setHours(23);
    end_now.setMinutes(59);
    end_now.setSeconds(59);
    end_now.setMilliseconds(999);
    return {
      timer: now,
      defaultExpandedKeys: ["a_0"],
      propoption: {
        icon: "icon",
        value: "gps_id",
        label: "nodeName",
        children: "children",
        disabled: "disabled",
      },
      treeH: 500,
      treeDataMain: [],
      query_key: "",
      dev_name: "",
      dialogFormVisible_cph: this.isShow,
      total_pic: 0,
      query_ftp: {
        rp: 25,
        page: 1,
        stime: moment().startOf("month").format("YYYY-MM-DD 00:00:00"),
        etime: moment().format("YYYY-MM-DD 23:59:59"),
        dev_id: "",
        state: -1,
        // begintime: moment().startOf("month").format("YYYY-MM-DD 00:00:00"),
        // endtime: moment().format("YYYY-MM-DD 23:59:59"),
        type_id: 2000,
      },
      query_pic: {
        rp: 25,
        page: 1,
        begintime: moment().startOf("month").format("YYYY-MM-DD 00:00:00"),
        endtime: moment().format("YYYY-MM-DD 23:59:59"),
        type_id: 1000,
      },
      act: "ftp",

      total_ftp: 0,
      tableOps_ftp: [
        {
          opType: "btn",
          // name: "下载",
          icon: "Download",
          type: "",
          click: (row, callback) => {
            let fileName = row["fFileName"];
            let url =
              this.$imgUrl +
              "/Public/ftp/Files/" +
              row["fFileUrl"] +
              "/" +
              row["fFileName"];
            // "/Public/ftp/Files/" + row["fFileUrl"] + "/" + row["fFileName"];
            const elink = document.createElement("a");
            elink.download = fileName;
            elink.href = url;
            elink.style.display = "none";
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
          },
        },
        {
          opType: "del",
          icon: "Close",

          // name: this.$t("commKey.Delete"),
          type: "danger",
          click: (row, callback) => {
            console.log("del", row);
            let begintime = this.stime;
            if (this.act == "ftp") {
              begintime = this.stime;
            } else {
              begintime = this.begintime;
            }
            this.$api
              .sys_ftp_delete({ id: row.id, begintime: begintime })
              .then((res) => {
                this.getListFtp();
              });
            // callback({ type: "delete", row: row });
            // activeKey.value = "test";
          },
        },
      ],
      tableOps: [
        {
          opType: "btn",
          // name: "下载",
          icon: "Download",
          type: "",
          click: (row, callback) => {
            let fileName = row["mMediaFileName"];
            let url = this.$imgUrl + "/" + row["mMediaUrl"] + "/" + fileName;
            // "/Public/ftp/Files/" + row["fFileUrl"] + "/" + row["fFileName"];
            const elink = document.createElement("a");
            elink.download = fileName;
            elink.href = url;
            elink.style.display = "none";
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
          },
        },
        {
          opType: "del",
          icon: "Close",

          // name: this.$t("commKey.Delete"),
          type: "danger",
          click: (row, callback) => {
            console.log("del", row);
            let begintime = this.begintime;
            // if (this.act == "ftp") {
            //   begintime = this.stime;
            // } else {
            //   begintime = this.begintime;
            // }
            this.$api
              .search_img_delete({ id: row.id, begintime: begintime })
              .then((res) => {
                this.getListPic();
              });
            // callback({ type: "delete", row: row });
            // activeKey.value = "test";
          },
        },
      ],
      dev_id: "",
      optionsType: [
        {
          name: this.$t("map.quanbu"),
          value: "-1",
        },
        {
          name: this.$t("map.choose1"),
          value: "1",
        },
        {
          name: this.$t("map.choose2"),
          value: "2",
        },
        {
          name: this.$t("map.choose3"),
          value: "3",
        },
      ],
      state: "-1",
      stime: moment().startOf("month").format("YYYY-MM-DD 00:00:00"), //this.us_date_format(now, "yyyy-MM-dd hh:mm:ss"),
      etime: moment().format("YYYY-MM-DD 23:59:59"), //this.us_date_format(end_now, "yyyy-MM-dd hh:mm:ss"),
      begintime: moment().startOf("month").format("YYYY-MM-DD 00:00:00"), //this.us_date_format(now, "yyyy-MM-dd hh:mm:ss"),
      endtime: moment().format("YYYY-MM-DD 23:59:59"), //this.us_date_format(end_now, "yyyy-MM-dd hh:mm:ss"),
      dataList_ftp: [],
      dataList_pic: [],
      height: "calc(100vh - 500px)",
      columns_ftp: [
        // { field: "cpy_name", label: this.$t("map.ssgs_name"), width: "150px" },
        { field: "cph", label: this.$t("map.cph_name"), width: "150px" },
        {
          field: "dName",
          label: this.$t("map.dName"),
          width: "150px",
        },
        {
          field: "mChannelNo",
          label: this.$t("map.fChannelNo"),
          type: "fun",
          render: (row) => {
            return "CH" + row.fChannelNo;
          },
        },
        { field: "fFileStartTime", label: this.$t("map.fFileStartTime") },
        { field: "fFileEndTime", label: this.$t("map.fFileEndTime") },
        {
          field: "fState",
          label: this.$t("map.fState"),
          type: "fun",
          render: (row) => {
            let rt = this.$t("map.choose5");
            // 0=未上传 1=上传中 2=上传成功 3=上传超时 4=上传失败
            switch (parseInt(row.fState)) {
              case 1:
                rt = this.$t("map.choose1");
                break;
              case 2:
                rt = this.$t("map.choose2");
                break;
              case 3:
                rt = this.$t("map.choose3");
                break;
              case 4:
                rt = this.$t("map.choose4");
                break;
            }
            if (row.fState == 1) {
              if (row.fVal < 90) {
                rt = row.fVal + "%";
              } else {
                rt = "90%";
              }
            }

            // if (row.fState == 1 && row.fVal == 1) {
            //   rt = "90%";
            // } else if (row.fState == 1 && row.fVal == 0) {
            //   if (row.st_val < 90) {
            //     rt = row.st_val + "%";
            //   } else {
            //     rt = "90%";
            //     if (row.st_val <= 90) {
            //       //更新数据库状态
            //       this.$api.sys_ftp_update({ id: row.id }).then((res_rs) => {});
            //     }
            //   }
            // }

            return rt;
          },
        },

        // { field: "mMediaFileName", label: this.$t("map.mMediaFileName") },
        // { field: "mMediaTime", label: this.$t("map.til8") },
        // { field: "fFileEndTime", label: this.$t("map.fFileEndTime") },
        // {
        //   field: "fState",
        //   label: this.$t("map.fState"),
        //   width: "90px",
        //   type: "fun",
        //   render: (row) => {
        //     return this.$t("map.choose" + row.fState);
        //   },
        // },
      ],
      columns_pic: [
        // { field: "cpy_name", label: this.$t("map.ssgs_name"), width: "150px" },
        { field: "cph", label: this.$t("map.cph_name"), width: "150px" },
        {
          field: "dName",
          label: this.$t("map.dName"),
          width: "150px",
        },
        {
          field: "mChannelNo",
          label: this.$t("map.channel_no"),
           width: "140px",
          type: "fun",
          render: (row) => {
            return "CH" + row.mChannelNo;
          },
        },
        { field: "mMediaFileName", label: this.$t("map.mMediaFileName") },
        { field: "mMediaTime", label: this.$t("map.til8"),width: "150px", },
      ],
      dialogFormVisible: this.isShow,
    };
  },
  methods: {
    clear_ftp() {
      if (this.dev_name == "") {
        this.dev_id = 0;
      }
    },
    treeclick(node) {
      this.dev_id = node.vDeviceID;
      this.dev_name = node.vPlate;
      this.dialogFormVisible_cph = false;
    },
    init_tree() {
      this.$api.tree_deptTree_Tree({}).then((res) => {
        // searchCols.value[0].data = res.data.resultData;
        let dataTree = res.data.resultData;
        this.delChannel(dataTree);
        this.treeDataMain = dataTree;
      });
    },
    delChannel(dataList) {
      dataList.forEach((it) => {
        if (it.nodetype == 2) {
          it.children = [];
        } else {
          if (it.children && it.children.length > 0) {
            this.delChannel(it.children);
          }
        }
      });
    },
    filterMethod(query, node) {
      return node.nodeName.includes(query);
    },

    onQueryChanged(query) {
      this.$refs.treeRef_report.filter(query);
    },
    click_ftp() {
      console.log("click_ftp");
      this.dialogFormVisible_cph = true;
    },
    handleCurrentChangeFtp(val) {
      this.query_ftp.page = val;
      this.getListFtp();
    },
    handleCurrentChangePic(val) {
      this.query_pic.page = val;
      this.getListPic();
    },
    handleSizeChangeFtp(val) {
      this.query_ftp.rp = val;
      this.getListFtp();
    },
    handleSizeChangePic(val) {
      this.query_pic.rp = val;
      this.getListPic();
    },
    search_pic() {
      console.log("search_pic");
      this.query_pic.page = 1;
      this.query_pic.begintime = this.begintime;
      this.query_pic.endtime = this.endtime;
      this.query_pic.dev_id = this.dev_id;
      this.query_pic.type_id = 1000;
      this.getListPic();
    },
    search_ftp() {
      this.query_ftp.page = 1;
      // this.query_ftp.begintime = this.stime;
      // this.query_ftp.endtime = this.etime;
      this.query_ftp.stime = this.stime;
      this.query_ftp.etime = this.etime;
      // this.query_ftp.state = this.state;
      this.query_ftp.type_id = 2000;
      // this.query_ftp.dev_ids = this.dev_id;
      this.query_ftp.dev_id = this.dev_id;
      this.query_ftp.state = this.state;
      this.getListFtp();
    },
    changeTab(act) {
      this.act = act;
    },
    getListFtp() {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.$api.sys_ftp_grid(this.query_ftp).then((res) => {
        this.dataList_ftp = res.data.data;
        this.timer = setInterval(() => {
          let ay = [...this.dataList_ftp];
          ay.forEach((it) => {
            if (it.fState == 1) {
              if (it.fVal < 90) {
                it.fVal++;
                this.$api
                  .sys_ftp_update({ id: it.id, val: it.fVal })
                  .then((res_rs) => {});
              }
            }
            // if (it.fState == 1 && it.fVal == 0) {
            //   if (it.st_val < 100) it.st_val = parseInt(it.st_val) + 1;
            // }
          });
          // console.log(ay);
          // this.dataList_ftp.splice(0, this.dataList_ftp.length);
          this.dataList_ftp = ay;
        }, 500);
        this.total_ftp = res.data.pagination.totalRecords;
      });
      // this.$api.search_media_file_list_by_ids(this.query_ftp).then((res) => {
      //   this.dataList_ftp = res.data.data;
      //   // this.total_ftp = res.data.pagination.totalRecords;
      // });
    },
    getListPic() {
      this.$api.search_media_file_list(this.query_pic).then((res) => {
        this.dataList_pic = res.data.data;
        this.total_pic = res.data.pagination.totalRecords;
      });
    },
    /**
     * @function
     * @name us_date_format
     * @param {string} _date - 不是时间字符串而是时间对象，当值为字符串时需要对时间字符串进行处理new Date("2022-03-23 01:02:03")
     * @param {string} fmt - 格式化样式  yyyy-MM-dd hh:mm:ss
     * @desc 返回时间字符串
     */
    us_date_format(_date, fmt) {
      //console.log(_date);
      if (fmt == undefined) {
        fmt = "yyyy-MM-dd hh:mm:ss";
      }
      var o = {
        "M+": _date.getMonth() + 1, //月份
        "d+": _date.getDate(), //日
        "h+": _date.getHours(), //小时
        "m+": _date.getMinutes(), //分
        "s+": _date.getSeconds(), //秒
        "q+": Math.floor((_date.getMonth() + 3) / 3), //季度
        S: _date.getMilliseconds(), //毫秒
      };
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (_date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length == 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
        }
      }
      //console.log(fmt);
      return fmt;
    },
    init_data() {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.getListFtp();
      this.getListPic();
      this.init_tree();
    },
    clickFun(flag, obj = {}) {
      // if (flag == "close") {
      //   if (this.timer) {
      //     clearInterval(this.timer);
      //   }
      // }
      this.$emit("ErpDialogEvent", Object.assign({ type: flag }, obj));
    },
  },
};
</script>

<style lang="scss" scoped>
.tab_box {
  display: flex;
  flex-direction: row;

  width: 260px;
  height: 37px;
  line-height: 37px;
  background: #fff;
  margin: 0 auto;
  margin-top: -20px;
  margin-bottom: 10px;

  .tab_item {
    width: 120px;
    float: left;
    text-align: center;
    cursor: pointer;
    font-size: 17px;
    background: #eee;
    //border:1px solid #ccc;
    border-radius: 20px;
    color: #333;
    margin-right: 10px;
    &.act {
      background: #2196f3;
      border-radius: 20px;
      color: #fff;
    }
  }
}
.search {
  padding: 10px 0px;
  display: flex;
  margin-left: -10px;
  flex-direction: row;
  .it {
    padding: 0 10px;
  }
}
::v-deep .el-input--small .el-input__wrapper {
  padding: 2px 7px;
  height: 30px;
}
::v-deep .el-button--small {
  height: 30px;
  font-size: 14px;
}

::v-deep .gps-span .is-round {
  background: none !important;
  border: none;
  color: #1975d1;
  font-size: 20px;
  overflow: hidden;
  float: left;
}
::v-deep .el-button--small.is-round {
  padding: 0px;
  margin-left: -3px;
  //padding-left: 5px;
}
::v-deep .gps-span .is-round .el-icon {
  font-size: 18px !important;
}
::v-deep .gps-span .el-button--danger {
  //background: #fcf7f6 !important;
  //border: 1px solid #f9543e;
  border: none;
  color: #dc3a24;
  margin-left: 1px;
}
::v-deep .gps-span .el-button--small {
  height: 25px;
  font-size: 12px;
}
.gps-span {
  width: 50px;
  display: block;
}
</style>
