export default {
  Title: "Add Company",
  cName: "Company",
  cIndustryID: "Industry",
  cContact: "Liaison",
  cContactTel: "Tel",
  cCJPerson: "Boss",
  cCJPersonTel: "TEL Boss",
  cExpiryDate: "Service Deadline",
  cEnableExpiryDate: "Enable service expiration date?",
  cBusinessCert: "Business License",
  cBusinessLicenseNo: "Business License No.​",
  cAddress: "Address​",
  cRegDate: "Time",
  cCompanyImage: "Company business license picture",
  cMaxDevNum: "License limit",
  cMore: "Notes",
  cLastUpdated: "Time",
  undelete_reason:
    "The following companies currently have bound fleets or vehicles, please unbind before deleting! Company Name:",
};
