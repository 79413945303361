import axios from "../../utils/axios";
export default {
  pdf_wap_report_pc(ay) {
    return axios({
      method: "get",
      url: "api/cmspdf/wap_report_pc",
      responseType: "blob", //二进制流
      data: ay,
    });
  },
  pdf_wap_report_html(ay) {
    return axios.post("api/cmspdf/wap_report_html", Object.assign({}, ay), {
      hide_loading: 1,
    });
  },
};
