export default {
  commKey: {
    keyname: "查询关键字",
    riqi: "日期",
    shouye: "首页",
    zhinengfalvzixun: "智能法律咨询",
    falvfaguichaxun: "法律法规查询",
    anliku: "案例库",
    xuanze: "请选择",
    Search: "查询",
    Add: "添加",
    Edit: "编辑",
    Delete: "删除",
    Import: "导入",
    Export: "导出",
    Template: "模板",
    Actions: "操作",
    Cancel: "取消",
    Confirm: "确定",
    BITIAN: "必填",
    CONFIRMTITLE: "Are you sure to delete this?",
    MSG_1: "添加成功！",
    MSG_2: "保存成功",
    MSG_3: "您的权限不够",

    MSG_4: "设备号不能重复",
    MSG_5: "SIM卡不能重复",
    MSG_6: "超过了最大上传数量！",
    MSG_7: "不能重复",
    MSG_TITLE: "温馨提示",
    DELETESUCCESS: "删除成功",
    DELETEFAIL: "删除失败",
    COMPANYS: "所属公司",
    VEHGROUP: "车组",
    IMPORTSUCCESS: "导入成功",
    IMPORTFAIL: "导入失败",
    STARTDATE: "开始时间",
    ENDDATE: "结束时间",
    DETAIL: "详情",
    MINGXI: "明细",
    EXCEL: "EXCEL",
    PDF: "PDF",
    QXZGS: "请选择公司",
    QXZSCX: "请选择删除项",
    QXZFZ: "请选择分组",
    CARGROUPLEFT: "未分配",
    CARGROUPRIGHT: "已分配",
    QXZCZ: "请选择车组",
    SAVE: "保存",
    SETTING: "设置",
    ZHUANGTAI: "状态",
    CHEPAIHAO: "车牌号",
    BINDCAR: "绑定车辆",
    UNBINDCAR: "解绑车辆",
    CAR_3: "保存成功",
    CAR_33: "车牌号码不能重复",
    CAR_3: "您的权限不够",
    CAR_4: "设备号不能重复",
    CAR_5: "SIM卡号不能重复",
    CAR_6: "超过了最大上传数量！",
    CAR_7: "车组和用户都存在",
    CAR_8: "用户存在",
    CAR_9: "车组存在",

    // Create: "添加",
    // DeleteSuccess: "删除成功",
    // DeleteFail: "删除失败",
    // AddSuccess: "添加成功",
    // EditSuccess: "修改成功",
    // NoDataEdit: "无数据修改",
    // NoSame: "已经存在",
    // NotifySuccessTitle: "提示",
    // UpdateSuccess: "更新成功",
    // DeleteRelation: "删除关联项",
  },
};
