export default {
  userName: "用户名",
  userPwd: "密码",
  vcode: "验证码",
  login: "登录",

  empty_username: "用户名不能为空！",
  length_username: "用户名长度至少5位",
  empty_userpwd: "密码不能为空！",
  empty_vcode: "验证码不能为空！",

  msg_type_1: "验证码错误",
  msg_type_2: "用户名或密码为空",
  msg_type_3: "您的账号已经到期，请联系管理员",
  msg_type_4: "用户名或密码不正确",
  msg_type_5: "您的账号正在使用",
  msg_type_6: "您的账号被锁，请联系管理员",
  oPwd: "旧密码",
  uPwd: "新密码",
  cPwd: "确认密码",
  oPwd_empty: "旧密码必填",
  uPwd_empty: "新密码必填",
  cPwd_empty: "确认密码必填",
  btnSave: "保存",
  pwdNOSame: "两次输入的密码不一致",
  userpwd_rule: "6-18位 ，由数字、大写字母、小写字母、特殊字符,至少2种",

  msgType_1: "修改成功",
  msgType_2: "旧密码错误",
  msgType_3: "请重新登录",
  msgType_4: "无权修改密码",
};
