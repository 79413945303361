// var x_pi = (3.14159265358979324 * 3000.0) / 180.0;

// //谷歌坐标转百度坐标
// function Convert_GCJ02_To_BD09(lng, lat) {
//   var x = lng;
//   var y = lat;
//   var z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * x_pi);
//   var theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * x_pi);
//   lng = z * Math.cos(theta) + 0.0065;
//   lat = z * Math.sin(theta) + 0.006;
//   var gg = [];
//   gg.lng = lng;
//   gg.lat = lat;
//   return gg;
// }

// //百度坐标转谷歌坐标
// function Convert_BD09_To_GCJ02(lng, lat) {
//   var x = lng - 0.0065;
//   var y = lat - 0.006;
//   var z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_pi);
//   var theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi);
//   lng = z * Math.cos(theta);
//   lat = z * Math.sin(theta);
//   var gg = [];
//   gg.lng = lng;
//   gg.lat = lat;
//   return gg;
// }

var regions = [];
//把中国划分为五个图形区域
regions.push(new RectangleInsets(54.1415, 109.6872, 39.3742, 135.0002));
regions.push(new RectangleInsets(49.2204, 79.4462, 42.8899, 96.33));
regions.push(new RectangleInsets(42.8899, 73.1246, 29.5297, 124.143255));
regions.push(new RectangleInsets(29.5297, 82.9684, 26.7186, 97.0352));
regions.push(new RectangleInsets(29.5297, 97.0253, 20.414096, 124.367395));
regions.push(new RectangleInsets(20.414096, 107.975793, 17.871542, 111.744104));
//这几个区域不在上面五个区域中  不再国内
var regionNos = [];
regionNos.push(new RectangleInsets(22.284, 101.8652, 20.0988, 106.665));
regionNos.push(new RectangleInsets(21.5422, 106.4525, 20.4878, 108.051));
regionNos.push(new RectangleInsets(55.8175, 109.0323, 50.3257, 119.127));
regionNos.push(new RectangleInsets(55.8175, 127.4568, 49.5574, 137.0227));
regionNos.push(new RectangleInsets(44.8922, 131.2662, 42.5692, 137.0227));
regionNos.push(new RectangleInsets(35.067203, 73.1246, 29.5297, 77.611654));
//香港 澳门 台湾 在国内
var regionInChinaNos = [];
//台湾
regionInChinaNos.push(
  new RectangleInsets(25.398623, 119.921265, 21.785006, 122.497559)
);
//香港
regionInChinaNos.push(
  new RectangleInsets(22.371854, 113.838099, 22.146008, 114.516499)
);
regionInChinaNos.push(
  new RectangleInsets(22.537524, 114.095661, 22.371854, 114.464757)
);
regionInChinaNos.push(
  new RectangleInsets(22.43387, 113.882942, 22.370784, 114.095661)
);
regionInChinaNos.push(
  new RectangleInsets(22.491584, 113.958831, 22.430663, 114.10026)
);
regionInChinaNos.push(
  new RectangleInsets(22.510015, 114.001087, 22.490248, 114.101698)
);
//澳门
regionInChinaNos.push(
  new RectangleInsets(22.219904, 113.55984, 22.10985, 113.615319)
);
regionInChinaNos.push(
  new RectangleInsets(22.219904, 113.545754, 22.159398, 113.56099)
);

function RectangleInsets(top, left, bottom, right) {
  this.top = top;
  this.left = left;
  this.bottom = bottom;
  this.right = right;
}

function InRectangle(rect, jingDu, weiDu) {
  jingDu = Number(jingDu);
  weiDu = Number(weiDu);
  return (
    rect.right >= jingDu &&
    rect.left <= jingDu &&
    rect.top >= weiDu &&
    rect.bottom <= weiDu
  );
}

/**
 *lat 纬度
 *lng 经度
 *chinaRegion  是否中国区域都纠偏  true 都纠偏 false  港澳台不纠偏
 */
function IsInsideChina(lat, lng, chinaRegion) {
  for (var i = 0; i < regions.length; i++) {
    if (InRectangle(regions[i], lng, lat)) {
      for (var j = 0; j < regionNos.length; j++) {
        if (InRectangle(regionNos[j], lng, lat)) {
          return false;
        }
      }
      if (!chinaRegion) {
        for (var j = 0; j < regionInChinaNos.length; j++) {
          if (InRectangle(regionInChinaNos[j], lng, lat)) {
            return false;
          }
        }
      }
      return true;
    }
  }
  return false;
}

/**
 * Created by Wandergis on 2015/7/8.
 * 提供了百度坐标（BD09）、国测局坐标（火星坐标，GCJ02）、和WGS84坐标系之间的转换
 * coordtransform.js
 */
//定义一些常量
var x_PI = (3.14159265358979324 * 3000.0) / 180.0;
var PI = 3.1415926535897932384626;
var a = 6378245.0;
var ee = 0.00669342162296594323;
/**
 * 百度坐标系 (BD-09) 与 火星坐标系 (GCJ-02)的转换
 * 即 百度 转 谷歌、高德
 * @param bd_lon
 * @param bd_lat
 * @returns {*[]}
 */
function bd09togcj02(bd_lon, bd_lat) {
  var bd_lon = +bd_lon;
  var bd_lat = +bd_lat;
  var x = bd_lon - 0.0065;
  var y = bd_lat - 0.006;
  var z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_PI);
  var theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_PI);
  var gg_lng = z * Math.cos(theta);
  var gg_lat = z * Math.sin(theta);
  return [gg_lng, gg_lat];
}

/**
 * 火星坐标系 (GCJ-02) 与百度坐标系 (BD-09) 的转换
 * 即谷歌、高德 转 百度
 * @param lng
 * @param lat
 * @returns {*[]}
 */
function gcj02tobd09(lng, lat) {
  var lat = +lat;
  var lng = +lng;
  var z = Math.sqrt(lng * lng + lat * lat) + 0.00002 * Math.sin(lat * x_PI);
  var theta = Math.atan2(lat, lng) + 0.000003 * Math.cos(lng * x_PI);
  var bd_lng = z * Math.cos(theta) + 0.0065;
  var bd_lat = z * Math.sin(theta) + 0.006;
  return [bd_lng, bd_lat];
}

/**
 * WGS84转GCj02
 * @param lng
 * @param lat
 * @returns {*[]}
 */
function wgs84togcj02(lng, lat) {
  var lat = +lat;
  var lng = +lng;
  if (out_of_china(lng, lat)) {
    return [lng, lat];
  } else {
    var dlat = transformlat(lng - 105.0, lat - 35.0);
    var dlng = transformlng(lng - 105.0, lat - 35.0);
    var radlat = (lat / 180.0) * PI;
    var magic = Math.sin(radlat);
    magic = 1 - ee * magic * magic;
    var sqrtmagic = Math.sqrt(magic);
    dlat = (dlat * 180.0) / (((a * (1 - ee)) / (magic * sqrtmagic)) * PI);
    dlng = (dlng * 180.0) / ((a / sqrtmagic) * Math.cos(radlat) * PI);
    var mglat = lat + dlat;
    var mglng = lng + dlng;
    return [mglng, mglat];
  }
}

/**
 * GCJ02 转换为 WGS84
 * @param lng
 * @param lat
 * @returns {*[]}
 */
function gcj02towgs84(lng, lat) {
  var lat = +lat;
  var lng = +lng;
  if (out_of_china(lng, lat)) {
    return [lng, lat];
  } else {
    var dlat = transformlat(lng - 105.0, lat - 35.0);
    var dlng = transformlng(lng - 105.0, lat - 35.0);
    var radlat = (lat / 180.0) * PI;
    var magic = Math.sin(radlat);
    magic = 1 - ee * magic * magic;
    var sqrtmagic = Math.sqrt(magic);
    dlat = (dlat * 180.0) / (((a * (1 - ee)) / (magic * sqrtmagic)) * PI);
    dlng = (dlng * 180.0) / ((a / sqrtmagic) * Math.cos(radlat) * PI);
    var mglat = lat + dlat;
    var mglng = lng + dlng;
    return [lng * 2 - mglng, lat * 2 - mglat];
  }
}

function transformlat(lng, lat) {
  var lat = +lat;
  var lng = +lng;
  var ret =
    -100.0 +
    2.0 * lng +
    3.0 * lat +
    0.2 * lat * lat +
    0.1 * lng * lat +
    0.2 * Math.sqrt(Math.abs(lng));
  ret +=
    ((20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) *
      2.0) /
    3.0;
  ret +=
    ((20.0 * Math.sin(lat * PI) + 40.0 * Math.sin((lat / 3.0) * PI)) * 2.0) /
    3.0;
  ret +=
    ((160.0 * Math.sin((lat / 12.0) * PI) + 320 * Math.sin((lat * PI) / 30.0)) *
      2.0) /
    3.0;
  return ret;
}

function transformlng(lng, lat) {
  var lat = +lat;
  var lng = +lng;
  var ret =
    300.0 +
    lng +
    2.0 * lat +
    0.1 * lng * lng +
    0.1 * lng * lat +
    0.1 * Math.sqrt(Math.abs(lng));
  ret +=
    ((20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) *
      2.0) /
    3.0;
  ret +=
    ((20.0 * Math.sin(lng * PI) + 40.0 * Math.sin((lng / 3.0) * PI)) * 2.0) /
    3.0;
  ret +=
    ((150.0 * Math.sin((lng / 12.0) * PI) +
      300.0 * Math.sin((lng / 30.0) * PI)) *
      2.0) /
    3.0;
  return ret;
}

/**
 * 判断是否在国内，不在国内则不做偏移
 * @param lng
 * @param lat
 * @returns {boolean}
 */
function out_of_china(lng, lat) {
  return !IsInsideChina(lat, lng, true);
  // var lat = +lat;
  // var lng = +lng;
  // // 纬度3.86~53.55,经度73.66~135.05
  // return !(lng > 73.66 && lng < 135.05 && lat > 3.86 && lat < 53.55);
}

function Convert_GCJ02_To_BD09(lng, lat) {
  let pp = gcj02tobd09(lng, lat);
  return { lng: pp[0], lat: pp[1] };
}
function Convert_BD09_To_GCJ02(lng, lat) {
  let pp = bd09togcj02(lng, lat);
  return { lng: pp[0], lat: pp[1] };
}

export {
  Convert_BD09_To_GCJ02,
  Convert_GCJ02_To_BD09,
  gcj02towgs84,
  gcj02tobd09,
  wgs84togcj02,
  out_of_china,
  bd09togcj02,
};
