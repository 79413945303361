<template>
  <div class="mapbox">
    <div :id="mapID" class="map-container"></div>
    <div class="tool">
      <div @click="changeMap(1)" class="">{{ $t("map.maps") }}</div>
      <div @click="changeMap(2)" class="">{{ $t("map.satellite") }}</div>
    </div>
  </div>
</template>

<script>
import loadScript from "../../utils/loadScript";
import { wgs84togcj02, gcj02tobd09, out_of_china } from "../../utils/gps";
import { fa } from "element-plus/es/locale";
import api from "../../api";
export default {
  props: {
    mapID: {
      type: String,
      default: "map-container",
    },
    ayHistory: {
      type: Array,
      default: () => [],
    },
    ayData: {
      type: Array,
      default: () => [],
    },
    ayMarker: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    ayMarker(val) {
      console.log(val, "baidu");
    },
    ayHistory(val) {
      this.drawHistory(val);
    },
  },
  data() {
    return {
      curr_car: 0,
      car_idx: 0,
      ay_baidu: [],
      isRun: false,
      timer: null,
      carMarker: null,
      ay_marker: [], //清理图标
      markerClusterer: null,
      baiduInfoWindowPtos: {
        //百度信息框偏移量
        offset: {
          height: -12,
          width: 0,
        },
        enableAutoPan: false,
      },
      infoWindow: null,
      infoWindow_html: "",
      map: null,
      lnglat: null,
      drawingManager: null,
      // ay_markers: [],
      map_markers: new Map(),
      // ay_cars: [],
      mapvLayer: null,
    };
  },
  mounted() {
    window.initMap = this.initMap;
    this.loadScripts();
  },
  methods: {
    clearAll() {
      //清理地图上图标
      this.map.clearOverlays();
    },
    setCurrCar(id) {
      this.curr_car = id;
    },
    posCar(row) {
      let lnglat = this.gps842bd09(row.lng, row.lat);
      let pt = new BMap.Point(lnglat[0], lnglat[1]);
      pt.time = row.time;
      pt.cph = row.cph;
      pt.ang = row.ang;
      this.setCar(pt);
    },
    nextCar() {
      this.isRun = true;
      this.clickFun("play");
    },
    pauseCar() {
      this.isRun = false;
      this.clickFun("pause");
    },
    stopCar() {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.setCar(this.ay_baidu[0]);
      this.clickFun("stop");
    },
    playCar() {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.car_idx = 0;
      this.isRun = true;
      // this.isAni = true;
      this.timer = setInterval(() => {
        if (this.isRun) {
          if (this.car_idx < this.ay_baidu.length) {
            this.setCar(this.ay_baidu[this.car_idx++]);
          } else {
            clearInterval(this.timer);
            // this.isAni = false;
            this.car_idx = 0;
            this.setCar(this.ay_baidu[this.car_idx++]);
            this.clickFun("stop");
          }
        }
      }, 100);
      this.clickFun("play");
    },
    drawHistory(ay) {
      //清理地图上图标
      this.map.clearOverlays();
      this.carMarker = null;
      if (this.timer) {
        clearInterval(this.timer);
      }
      if (ay.length == 0) return;

      // if(this.ay_marker.length>0){
      //   this.map.clear
      // }
      //转换百度经纬度
      // let ay_baidu = [];
      this.ay_baidu.splice(0);
      let lnglat;
      let pt;
      ay.forEach((it) => {
        lnglat = this.gps842bd09(it.lng, it.lat);
        pt = new BMap.Point(lnglat[0], lnglat[1]);
        pt.time = it.time;
        pt.cph = it.cph;
        pt.ang = it.ang;
        this.ay_baidu.push(pt);
      });
      //画路线
      this.drawRoad(this.ay_baidu);
    },
    drawRoad() {
      var sy = new BMap.Symbol(BMap_Symbol_SHAPE_BACKWARD_OPEN_ARROW, {
        fillColor: "#0dbc79", //图标填充颜色
        fillOpacity: 1, //图标填充透明度
        scale: 1.2, //图标缩放大小
        // rotation : '90deg', //图标旋转角度
        strokeColor: "#0dbc79", //线填充颜色
        strokeWeight: 4, //线的透明度
        strokeOpacity: 1, //线宽
      });
      var icons = new BMap.IconSequence(sy, "10", "100");
      let carLine = new BMap.Polyline(this.ay_baidu, {
        strokeColor: "#0dbc79",
        strokeWeight: 4,
        strokeOpacity: 1,
        icons: [icons],
      });
      this.map.addOverlay(carLine);
      this.map.panTo(this.ay_baidu[0]);
      this.map.setViewport(this.ay_baidu);

      this.createMarker(
        this.ay_baidu[0],
        require("../../assets/map/1.png"),
        null
      );
      this.createMarker(
        this.ay_baidu[this.ay_baidu.length - 1],
        require("../../assets/map/2.png"),
        null
      );
      this.setCar(this.ay_baidu[0]);
    },
    setCar(pt) {
      if (this.carMarker == null) {
        // 创建小车图标
        var myIcon = new BMap.Icon("images/1.png", new BMap.Size(36, 36));
        // 创建Marker标注，使用小车图标

        this.carMarker = new BMap.Marker(pt, {
          icon: myIcon,
        });

        //
        let label = new BMap.Label(pt.time, {
          position: pt,
          offset: new BMap.Size(28, 8),
        });
        label.setStyle({
          backgroundColor: "0.000000000001", //通过这个方法，去掉背景色
          color: "green",
          fontSize: "12px",
          border: "0",
          fontWeight: "bolder",
        });
        this.carMarker.setLabel(label);

        this.carMarker.setRotation(pt.ang);
        // marker.row = row;
        this.map.addOverlay(this.carMarker); // 将标注添加到地图中
        // marker.addEventListener("click",  (evt)=> {
        //   this.infoWindow_html = this.baiPop_html(evt.currentTarget.row);
        //   this.infoWindow.setContent(this.infoWindow_html);
        //   evt.currentTarget.openInfoWindow(this.infoWindow);
        // });
      } else {
        this.carMarker.setPosition(pt);
        this.carMarker.setRotation(pt.ang);
        this.carMarker.getLabel().setPosition(pt);
        this.carMarker.getLabel().setContent(pt.time);
      }
      // this.map.panTo(pt);
    },
    createMarker(pt, imgPath, row) {
      // 创建小车图标
      var myIcon = new BMap.Icon(imgPath, new BMap.Size(36, 36));
      // 创建Marker标注，使用小车图标
      let marker = new BMap.Marker(pt, {
        icon: myIcon,
      });
      marker.row = row;
      marker.addEventListener("click", (evt) => {
        if (row) {
          // this.infoWindow_html = this.msgHtml(evt.currentTarget.row);
          // this.infoWindow.setContent(this.infoWindow_html);
          // evt.currentTarget.openInfoWindow(this.infoWindow);
        }
      });
      this.map.addOverlay(marker);
      this.ay_marker.push(marker);
    },
    posMarker(row) {
      this.addMarker(row);
    },
    removeMaerker(row) {
      let mapKey = "map" + row.id;
      if (this.map_markers.has(mapKey)) {
        let marker = this.map_markers.get(mapKey);
        this.markerClusterer.removeMarker(marker);
        this.map_markers.delete(mapKey);
        this.map.closeInfoWindow();
      }
    },
    addMarkers(ay, is_pos = 1) {
      console.log("wxw pos", is_pos);
      if (ay.length > 0) {
        let ayTemp = [];
        let mapKey;
        let lnglat;
        let pt;
        ay.forEach((row) => {
          mapKey = "map" + row.id;
          lnglat = this.gps842bd09(row.lng, row.lat);
          pt = new BMap.Point(lnglat[0], lnglat[1]);
          if (this.map_markers.has(mapKey)) {
            //更新位置
            marker = this.map_markers.get(mapKey);
            marker.setPosition(pt);
            // console.log("addMarker", "update");
          } else {
            //添加marker
            // 创建小车图标
            var myIcon = new BMap.Icon(row.icon, new BMap.Size(36, 36));
            // 创建Marker标注，使用小车图标

            var marker = new BMap.Marker(pt, {
              icon: myIcon,
            });
            marker.row = row;
            marker.addEventListener("click", (evt) => {
              // console.log(evt);
              this.infoWindow_html = evt.currentTarget.row.html;
              this.infoWindow.setContent(this.infoWindow_html);
              this.map.openInfoWindow(this.infoWindow, evt.point);
            });

            this.map_markers.set(mapKey, marker);
            // this.map.addOverlay(marker);
          }
          // this.infoWindow_html = row.html;
          // this.infoWindow.setContent(this.infoWindow_html);
          // this.map.openInfoWindow(this.infoWindow, pt);
          ayTemp.push(marker);
        });
        this.markerClusterer.addMarkers(ayTemp, 4, 18);
        //
        setTimeout(() => {
          if (is_pos == 1) {
            this.map.panTo(pt);
          }

          setTimeout(() => {
            this.map.setZoom(17);
          });
        }, 500);
      }

      // console.log("addMarker", "add");
    },
    addMarker(row, is_pos = 1) {
      console.log("addMarker", row);
      let mapKey = "map" + row.id;
      let lnglat = this.gps842bd09(row.lng, row.lat);
      let pt = new BMap.Point(lnglat[0], lnglat[1]);
      if (this.map_markers.has(mapKey)) {
        //更新位置
        marker = this.map_markers.get(mapKey);
        marker.setPosition(pt);
        marker.getIcon().setImageUrl(row.icon);
        console.log("addMarker", "update");
      } else {
        //添加marker
        // 创建小车图标
        var myIcon = new BMap.Icon(row.icon, new BMap.Size(36, 36));
        // 创建Marker标注，使用小车图标

        var marker = new BMap.Marker(pt, {
          icon: myIcon,
        });
        marker.row = row;
        marker.addEventListener("click", (evt) => {
          // console.log(evt);
          this.infoWindow_html = evt.currentTarget.row.html;
          this.infoWindow.setContent(this.infoWindow_html);
          this.map.openInfoWindow(this.infoWindow, evt.point);
        });
        this.map_markers.set(mapKey, marker);
        // this.map.addOverlay(marker);
        this.markerClusterer.addMarker(marker, 4, 18);
        console.log("addMarker", "add");
      }
      setTimeout(() => {
        this.infoWindow_html = row.html;
        this.infoWindow.setContent(this.infoWindow_html);
        this.map.openInfoWindow(this.infoWindow, pt);
      }, 100);

      // console.log(pt);
      //
      this.$nextTick(() => {
        if (is_pos == 1) {
          this.map.panTo(pt);
        }

        setTimeout(() => {
          this.map.setZoom(18);
        }, 100);
      });
    },
    drawModel(drawType) {
      switch (parseInt(drawType)) {
        case 1:
          this.drawingManager.setDrawingMode(BMAP_DRAWING_MARKER);
          break;
        case 2:
          this.drawingManager.setDrawingMode(BMAP_DRAWING_CIRCLE);
          break;
        case 3:
          this.drawingManager.setDrawingMode(BMAP_DRAWING_POLYGON);
          break;
        case 4:
          this.drawingManager.setDrawingMode(BMAP_DRAWING_POLYLINE);
          break;
        case 5:
          this.drawingManager.setDrawingMode(BMAP_DRAWING_RECTANGLE);
          break;
        case 6:
          this.drawingManager.close();
          break;
      }
      this.drawingManager.open();
    },
    initDraw() {
      //////////////////////////////////////////////
      let styleOptions = {
        strokeColor: "blue", //边线颜色。
        fillColor: "blue", //填充颜色。当参数为空时，圆形将没有填充效果。
        strokeWeight: 3, //边线的宽度，以像素为单位。
        strokeOpacity: 0.8, //边线透明度，取值范围0 - 1。
        fillOpacity: 0.6, //填充的透明度，取值范围0 - 1。
        strokeStyle: "solid", //边线的样式，solid或dashed。
      };
      //实例化鼠标绘制工具
      this.drawingManager = new window.BMapLib.DrawingManager(this.map, {
        isOpen: false, //是否开启绘制模式
        enableDrawingTool: false, //是否显示工具栏
        drawingToolOptions: {
          anchor: BMAP_ANCHOR_TOP_LEFT, //位置
          offset: new BMap.Size(5, 5), //偏离值
        },
        circleOptions: styleOptions, //圆的样式
        polylineOptions: styleOptions, //线的样式
        polygonOptions: styleOptions, //多边形的样式
        rectangleOptions: styleOptions, //矩形的样式
      });
      //添加鼠标绘制工具监听事件，用于获取绘制结果
      this.drawingManager.addEventListener("overlaycomplete", (evt) => {
        // overlays.push(evt.overlay);
        switch (evt.drawingMode) {
          case "marker":
            break;
          case "circle":
            break;
          case "polyline":
            break;
          case "polygon":
            break;
          case "rectangle":
            break;
        }
        this.drawingManager.close();
      });
    },
    changeMap(mapType) {
      switch (mapType) {
        case 1:
          this.map.setMapType(BMAP_NORMAL_MAP);
          break;
        case 2:
          this.map.setMapType(BMAP_SATELLITE_MAP); //BMAP_EARTH_MAP  BMAP_SATELLITE_MAP
          break;
      }
    },
    async loadScripts() {
      const res = await api.sys_get_xtpz({});

      let baiduMapEle = document.getElementById("baiduMapEle");
      if (!baiduMapEle) {
        let link = document.createElement("link");
        link.href =
          "https://api.map.baidu.com/library/DrawingManager/1.4/src/DrawingManager_min.css";
        link.rel = "stylesheet";
        link.type = "text/css";
        document.getElementsByTagName("head")[0].appendChild(link);
        loadScript(
          "baiduMapEle",
          "https://api.map.baidu.com/api?v=3.0&ak=" +
            res.data.row.baiduKey +
            "&callback=initMap"
        );
      } else {
        this.initMap();
      }
      // this.initMap();
      // let styleElement = document.getElementById("baiduDrawLink");
      // if (!styleElement) {
      //   let link = document.createElement("link");
      //   link.href =
      //     "https://api.map.baidu.com/library/DrawingManager/1.4/src/DrawingManager_min.css";
      //   link.rel = "stylesheet";
      //   link.type = "text/css";
      //   link.id = "baiduDrawLink";
      //   document.getElementsByTagName("head")[0].appendChild(link);
      // }
      // let baiduMapEle = document.getElementById("baiduMapEle");
      // if (!baiduMapEle) {
      //   var script = document.createElement("script");
      //   script.type = "text/javascript";
      //   script.id = "baiduMapEle";
      //   script.src =
      //     "https://api.map.baidu.com/api?v=3.0&ak=GFD3HXy0CtiNpEGK0CxTrbGOXtIy0WdU&callback=initMap";
      //   // "https://api.map.baidu.com/api?type=webgl&v=1.0&ak=GWw7zIq29zLmWRVW9fmiPdVgRHv2PwoY&callback=initMap";
      //   document.body.appendChild(script);
      // } else {
      //   this.initMap();
      // }
    },
    async initMap() {
      let baiduMapDrawEle = document.getElementById("baiduMapDrawEle");
      if (!baiduMapDrawEle) {
        await loadScript(
          "baiduMapDrawEle",
          "https://api.map.baidu.com/library/DrawingManager/1.4/src/DrawingManager_min.js"
        );
        await loadScript(
          "baiduTextIconOverlayEle",
          "https://api.map.baidu.com/library/TextIconOverlay/1.2/src/TextIconOverlay_min.js"
        );
        await loadScript(
          "baiduMarkerClustererEle",
          "https://api.map.baidu.com/library/MarkerClusterer/1.2/src/MarkerClusterer_min.js"
        );
      }

      this.map = new BMap.Map(this.mapID); // 创建Map实例"map-container"
      this.lnglat = new BMap.Point(116.404, 39.915); // 创建点坐标
      this.map.centerAndZoom(this.lnglat, 13);
      this.map.enableScrollWheelZoom(); // 启用滚轮放大
      console.log("load map baidu map");
      this.infoWindow = new BMap.InfoWindow(
        this.infoWindow_html,
        this.baiduInfoWindowPtos
      );

      var top_right_navigation = new BMap.NavigationControl({
        anchor: BMAP_ANCHOR_BOTTOM_RIGHT,
        type: BMAP_NAVIGATION_CONTROL_SMALL,
      }); //右上角，仅包含平移和缩放按钮

      this.map.addControl(top_right_navigation);

      // 初始地图类型为普通地图
      this.map.setMapType(BMAP_NORMAL_MAP);

      this.markerClusterer = new window.BMapLib.MarkerClusterer(this.map, {});

      // this.map.addControl(new BMap.MapTypeControl());
      //动态加载画图js
      // let baiduMapDrawEle = document.getElementById("baiduMapDrawEle");
      // if (!baiduMapDrawEle) {
      //   var script_draw = document.createElement("script");
      //   script_draw.onload = () => {
      //     console.log("load map draw");
      // this.initDraw();
      //   };
      //   script_draw.type = "text/javascript";
      //   script_draw.id = "baiduMapDrawEle";
      //   script_draw.src =
      //     "https://api.map.baidu.com/library/DrawingManager/1.4/src/DrawingManager_min.js";

      //   document.body.appendChild(script_draw);
      // }

      //https://mapv.baidu.com/build/mapv.min.js
      // let baiduMapV = document.getElementById("baiduMapV");
      // if (!baiduMapV) {
      //   var script_draw = document.createElement("script");
      //   script_draw.onload = () => {
      //     //加载地图数据
      //     this.initData();
      //   };
      //   script_draw.type = "text/javascript";
      //   script_draw.id = "baiduMapV";
      //   script_draw.src = "https://mapv.baidu.com/build/mapv.min.js";

      //   document.body.appendChild(script_draw);
      // }

      this.initData();
    },
    initData() {
      console.log("baidu", this.ayMarker);
      //加载位置
      // this.lnglat = new BMap.Point(this.ayMarker[0].lng, this.ayMarker[0].lat);
      // var marker = new BMap.Marker(this.lnglat); // 创建标注
      // this.map.addOverlay(marker);
      // this.map.panTo(this.lnglat);
      //初始化海量点
      // console.log(this.ayData);
      this.addMarkers(this.ayData);

      this.drawHistory(this.ayHistory);
    },
    setMarkers(map) {
      this.map_markers = map;
      //this.drawMarkers();
    },
    drawMarkers() {
      let data_set = [];
      let pt;
      let lnglat;
      for (let [kv, row_gps] of this.map_markers) {
        if (row_gps) {
          lnglat = this.gps842bd09(row_gps.lng, row_gps.lat);
          pt = new BMap.Point(lnglat[0], lnglat[1]);
          data_set.push({
            geometry: {
              type: "Point",
              coordinates: lnglat,
            },
            iconOptions: {
              url: row_gps.icon,
              width: 35,
              height: 35,
            },
            row: {
              dev_id: row_gps.dev_id,
              img: row_gps.icon,
              cphm: row_gps.cphm,
              dev_name: row_gps.dev_name,
              sudu: row_gps.sudu,
              dwsj: row_gps.dwsj,
              cpy_name: row_gps.cpy_name,
              licheng: row_gps.licheng,
              lng: row_gps.lng,
              lat: row_gps.lat,
              wz: row_gps.wz,
              islatlng: row_gps.islatlng,
              html: row_gps.html,
            },
          });
        }
      }

      if (this.mapvLayer == null) {
        this.dataSet = new mapv.DataSet(data_set);
        var options = {
          shadowColor: "rgba(255, 250, 50, 1)",
          shadowBlur: 10,
          strokeStyle: "rgba(0, 0, 255, 1)", // 描边颜色
          lineWidth: 4, // 描边宽度
          // 非聚合点的颜色和大小，未设置icon或icon获取失败时使用
          fillStyle: "rgba(255, 50, 0, 1.0)",
          size: 50 / 3 / 2, // 非聚合点的半径
          // 非聚合时点的icon设置，会被具体点的设置覆盖，可设置为空
          // iconOptions: {
          //     url: 'images/marker.png',
          //     width: 50 / 3,
          //     height: 77 / 3,
          //     offset: {
          //         x: 0,
          //         y: 0
          //     }
          // },
          minSize: 8, // 聚合点最小半径
          maxSize: 31, // 聚合点最大半径
          globalAlpha: 0.8, // 透明度
          clusterRadius: 150, // 聚合像素半径
          maxClusterZoom: 18, // 最大聚合的级别
          maxZoom: 19, // 最大显示级别
          minPoints: 5, // 最少聚合点数，点数多于此值才会被聚合
          extent: 400, // 聚合的细腻程度，越高聚合后点越密集
          label: {
            // 聚合文本样式
            show: true, // 是否显示
            fillStyle: "white",
            // shadowColor: 'yellow',
            // font: '20px Arial',
            // shadowBlur: 10,
          },
          gradient: {
            0: "rgba(62,210,135,1)",
            0.5: "rgba(54,180,166,1)",
            1.0: "rgba(251,156,46,1)",
          }, // 聚合图标渐变色
          draw: "cluster",
          methods: {
            click: (point) => {
              if (point) {
                // console.log(point)
                this.infoWindow_html = point.row.html;
                this.infoWindow.setContent(this.infoWindow_html);
                this.map.openInfoWindow(
                  this.infoWindow,
                  new BMap.Point(
                    point.geometry.coordinates[0],
                    point.geometry.coordinates[1]
                  )
                );
                // if (point.iconOptions) {
                //     console.log(point.iconOptions.url);
                // }
                // // 通过children可以拿到被聚合的所有点
                // console.log(point.children);
              }
            },
          },
        };
        this.mapvLayer = new mapv.baiduMapLayer(
          this.map,
          this.dataSet,
          options
        );
      } else {
        // this.dataSet.clear();
        this.dataSet.set(data_set);
      }
      this.map.panTo(pt);
    },
    gps842bd09(lng, lat) {
      if (out_of_china(lng, lat)) {
        return [lng, lat];
      } else {
        let ay = wgs84togcj02(lng, lat);
        return gcj02tobd09(ay[0], ay[1]);
      }
      // return [lng, lat];
    },
    createMaker(row) {
      let lnglat = this.gps842bd09(row.lng, row.lat);
      var pt = new BMap.Point(lnglat[0], lnglat[1]);
      if (this.ay_marker.has(row.VehId)) {
        //更新位置
        this.ay_marker.get(row.VehId).setPosition(pt);
      } else {
        // 创建小车图标
        var myIcon = new BMap.Icon(row.img, new BMap.Size(36, 36));
        // 创建Marker标注，使用小车图标

        var marker = new BMap.Marker(pt, {
          icon: myIcon,
        });
        marker.row = row;
        this.map.addOverlay(marker); // 将标注添加到地图中
        marker.addEventListener("click", (evt) => {
          this.infoWindow_html = this.baiPop_html(evt.currentTarget.row);
          this.infoWindow.setContent(this.infoWindow_html);
          evt.currentTarget.openInfoWindow(this.infoWindow);
        });
        this.ay_marker.set(row.VehId, marker);
        this.ay_markers.push(marker);
      }
      // this.map.panTo(pt);
    },
    clickFun(flag, obj = {}) {
      this.$emit("MAPEVENT", Object.assign({ type: flag }, obj));
    },
  },
};
</script>

<style lang="scss" scoped>
.mapbox {
  position: relative;
  width: 100%;
  height: 100%;
  .map-container {
    width: 100%;
    height: 100%;
  }
  .tool {
    position: absolute;
    left: 10px;
    top: 10px;
    cursor: pointer;
    z-index: 100;
    font-size: 16px;
    line-height: 30px;
    background: #fff;
    padding: 0 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    div {
      padding: 5px 10px;
    }
  }
}
</style>
