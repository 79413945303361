import axios from "../../utils/axios";
export default {
  tree_tree_vehicles(ay) {
    return axios.post("api/tree/tree_vehicles", Object.assign({}, ay), {
      hide_loading: 1,
    });
  },
  tree_get_company_tree_by_id(ay) {
    return axios.post(
      "api/tree/get_company_tree_by_id",
      Object.assign({}, ay),
      {
        hide_loading: 1,
      }
    );
  },
  tree_rule_vehicle_process_exts(ay) {
    return axios.post(
      "api/tree/rule_vehicle_process_exts",
      Object.assign({}, ay),
      {
        hide_loading: 1,
      }
    );
  },
  tree_rule_vehicle_process(ay) {
    return axios.post("api/tree/rule_vehicle_process", Object.assign({}, ay), {
      hide_loading: 1,
    });
  },
  tree_get_total_num(ay) {
    return axios.post("api/tree/get_total_num", Object.assign({}, ay), {
      hide_loading: 1,
    });
  },
  tree_get_company_tree(ay) {
    return axios.post("api/tree/get_company_tree", Object.assign({}, ay));
  },
  tree_deptTree_Tree(ay) {
    return axios.post("api/tree/deptTree_Tree", Object.assign({}, ay));
  },
};
