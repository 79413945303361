<template>
  <el-config-provider :locale="lang"
    ><div id="gps-app"><router-view /></div
  ></el-config-provider>
</template>

<script>
import { defineComponent, ref, watch } from "vue";
import { ElConfigProvider } from "element-plus";
import ZhLocale from "element-plus/lib/locale/lang/zh-cn"; // 中文
import EhLocale from "element-plus/es/locale/lang/en"; // 英文
import { useI18n } from "vue-i18n";
import GpsSocket from "./utils/GpsSocket";
import store from "./store";
import VueCookies from "vue-cookies";

export default defineComponent({
  components: {
    ElConfigProvider,
  },
  setup() {
    //
    GpsSocket.addListener((res) => {
      if (VueCookies.get("gps_token")) {
        if (res.content.desc && res.content.desc.indexOf("需要鉴权") > -1) {
          GpsSocket.sendMsg("login", {
            username: "",
            password: "",
            session: store.getters.token,
            SubscribeType: 2,
          });
        }
      }

      // console.log("app", res);
      // let msg = "";
      // switch (res.type) {
      //   case "chat":
      //     break;
      //   case "answer":
      //     if (res.content.desc && res.content.desc.indexOf("需要鉴权") > -1) {
      //       GpsSocket.sendMsg("login", {
      //         username: "",
      //         password: "",
      //         session: store.getters.token,
      //         SubscribeType: 2,
      //       });
      //     }
      //     break;
      //   case "realtime": //实时推送的数据
      //     let deviceid = data.content.deviceid; //12位设备ID号，不足11位前面补了0
      //     if (data.content.order == "Client") {
      //       //推送给客户端/WEB
      //       let msgData = data.content.content;
      //       if (msgData.MsgType == "Alarm") {
      //         //报警信息
      //         let alarm = msgData.Content;
      //         //需要处理alarm 更新或添加报警信息到报警列表
      //         // console.log('Alarm-报警信息:', alarm);
      //       } else if (msgData.MsgType == "Position") {
      //         //实时定位信息
      //         let pos = msgData.Content;
      //         //需要处理定位数据
      //         //console.log('Position-实时定位信息:', pos);
      //       }
      //     }
      //     break;
      // }
    });
    const { locale } = useI18n();
    let lang = ref(ZhLocale);
    if (localStorage.lang == "en") {
      lang.value = EhLocale;
    }
    // const { proxy } = getCurrentInstance();
    watch(locale, (val) => {
      console.log(val, "wxw");
      switch (val) {
        case "en":
          lang.value = EhLocale;
          break;
        case "cn":
          lang.value = ZhLocale;
          break;
      }
    });
    return { lang };
    // let lang = ref(ZhLocale);
    // proxy.$i18n.locale => computed(() => {
    //   console.log('$i18n.locale',proxy.$i18n.locale)
    // });
    // // let langObj = reactive({ en: EhLocale, cn: ZhLocale });
    // // let lang = "cn";
    // return {
    //   locale: ZhLocale,
    // };
  },
});
</script>

<style lang="scss">
.bj {
  color: #f00;
}
</style>

<style lang="scss" scoped>
#gps-app {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
} */
</style>
<style lang="scss">
.default-theme.splitpanes--vertical > .splitpanes__splitter {
  margin-left: -4px;
  width: 5px;
}
.splitpanes.default-theme .splitpanes__splitter {
  background: #f2f2f2 !important;
  z-index: 999;
  border-left: none;
}
.el-drawer__title {
  font-size: 22px !important;
  color: #2c78bf !important;
  padding-left: 7px;
  text-align: left;
  border-left: 4px solid #2c78bf;
  height: 20px;
  line-height: 20px !important;
  overflow: hidden;
}
.xfwz .el-tree {
  padding-top: 0px !important;
  margin-top: -5px;
}
.el-table th.el-table__cell {
  color: #333 !important;
  background: #eef3f8 !important;
}
.ttr {
  border: 1px solid #eee !important;
  border-bottom: none !important;
}
.ttr .el-table__empty-block {
  height: 320px !important;
}
.clxx .el-descriptions__body {
  margin-bottom: 10px;
}
.clxx .el-table--small {
  border: 1px solid #eee !important;
  border-bottom: none !important;
  margin-top: 10px;
}
.el-tree-node__content span {
  font-size: 13px;
}
.is-current {
  background: #eee;
}
</style>
