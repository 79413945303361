<template>
  <div class="d-flex flex-row">
   
    <div
      v-for="it in mainNav"
      :key="'mainNav' + it.id"
      :class="['menu_item', act == it.mRName ? 'act' : '']"
      @click="go_url({ name: it.mRName })"
      
      
    >
    <div v-if="it.mTitle!='系统设置' && it.mTitle!='SYSTEM'">
    <img
        :src="'./meun/' + it.mIcon"
        style="
          vertical-align: middle;
          margin-right: 2px;
          width: 32px;
          height: 32px;
        "
        
      />
      {{ it.mTitle }}
    </div>
      
    </div>
  
    
    
    <!-- <div
      :class="['menu_item', act == 'historys' ? 'act' : '']"
      @click="go_url({ name: 'historys' })"
    >
      <img
        src="../../../assets/meun/102-big.png"
        style="
          vertical-align: middle;
          margin-right: 2px;
          width: 32px;
          height: 32px;
        "
      />
      轨迹回访
    </div> -->
    <!-- <div
      :class="['menu_item', act == 'video' ? 'act' : '']"
      @click="go_url({ name: 'video' })"
    >
      <img
        src="../../../assets/meun/105-big.png"
        style="
          vertical-align: middle;
          margin-right: 2px;
          width: 32px;
          height: 32px;
        "
      />
      实时视频
    </div> -->
    <!-- <div
      :class="['menu_item', act == 'BackVideo' ? 'act' : '']"
      @click="go_url({ name: 'BackVideo' })"
    >
      <img
        src="../../../assets/meun/106-big.png"
        style="
          vertical-align: middle;
          margin-right: 2px;
          width: 32px;
          height: 32px;
        "
      />
      录像回访
    </div> -->
    <!-- <div
      :class="['menu_item', act == 'GMap' ? 'act' : '']"
      @click="go_url({ name: 'GMap' })"
    >
      <img
        src="../../../assets/meun/106-big.png"
        style="
          vertical-align: middle;
          margin-right: 2px;
          width: 32px;
          height: 32px;
        "
      />
      测试地图
    </div> -->
    <!-- <div :class="['menu_item', act == 'index' ? 'act' : '']">
      <img
        src="../../../assets/meun/112-big.png"
        style="
          vertical-align: middle;
          margin-right: 2px;
          width: 32px;
          height: 32px;
        "
      />
      录像文件
    </div> -->
    <!-- <div
      :class="['menu_item', act == 'reports-lccx' ? 'act' : '']"
      @click="go_url({ name: 'reports-lccx' })"
    >
      <img
        src="../../../assets/meun/201-big.png"
        style="
          vertical-align: middle;
          margin-right: 2px;
          width: 32px;
          height: 32px;
        "
      />
      报表查询
    </div> -->
    <!-- <div :class="['menu_item', act == 'index' ? 'act' : '']">
      <img
        src="../../../assets/meun/204-big.png"
        style="
          vertical-align: middle;
          margin-right: 2px;
          width: 32px;
          height: 32px;
        "
      />
      主动安全
    </div> -->
    <!-- <div
      :class="['menu_item', act == 'syssets-cars' ? 'act' : '']"
      @click="go_url({ name: 'syssets-cars' })"
    >
      <img
        src="../../../assets/meun/302-big.png"
        style="
          vertical-align: middle;
          margin-right: 2px;
          width: 32px;
          height: 32px;
        "
      />
      车辆设置
    </div> -->
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { ref, getCurrentInstance } from "vue";
export default {
  setup() {
    let { proxy } = getCurrentInstance();
    const router = useRouter();
    const act = ref("index");
    const go_url = (url) => {
      router.push(url);
      act.value = url.name;
    };
    const mainNav = ref([]);
    proxy.$api.menu_get_menu_list_by_role({ pid: 0 }).then((res) => {
      // console.log(res.data);
      mainNav.value = res.data.list;
    });
    return { go_url, act, mainNav };
  },
};
</script>

<style lang="scss" scoped>
.menu_item {
  height: 55px;
  line-height: 55px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  padding-left: 15px;
  padding-right: 10px;min-width:120px;
}
.menu_items {
  height: 55px;
  line-height: 55px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;

}
.menu_item:nth-child(3){
  height: 55px;
  line-height: 55px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  padding-left: 0px;
  padding-right: 0px;width:0px;min-width:0px;
}
.gps-header .act {
  color: #fff;
  background: #2267a8;
}
.ml15 {
  margin-left: 15px;
}
</style>
