export default {
  searchTip: "规则名称",
  btnAllData: "所有规则",
  btnAddRule: "添加",
  typeTip:
    "规则使用说明：1.添加规则 2.设置自定义的规则条件 3.并把需要执行此规则的车辆授权给它",

  ID: "序号",
  rRuleName: "规则名称",
  rRuleType: "规则类别",
  rCompanyID: "绑定公司",
  rLastUpdated: "编辑时间",
  item_edit: "编辑",
  item_delete: "删除",
  rule_empty: "请选择规则！",
  item_bind: "绑定",

  yes: "确定",
  no: "取消",
  alert_confirm: "确认要操作删除吗?",
  success: "操作成功",
  error: "操作失败",

  rule_title: "基本信息",
  th_rule_name: "规则名称",
  th_vehgroup: "所属公司",

  th_travel_time: "行驶时间",
  travelTimeUnit: "分钟",
  th_rest_time: "休息时间",
  restTimeUnit: "分钟",
  th_min_speed: "最低速度",
  minSpeedUnit1: "公里/时（0表示无限制）",
  minSpeedUnit2: "英里/时（0表示无限制）",
  th_begin_time: "时间范围",
  time_split: "至",
  th_voice: "报警语音",
  panel_title: "拍照和录像",
  th_camera: "是否拍照",
  label_camera_yes: "是",
  label_camera_no: "否",
  th_cameraWebcam: "拍照摄像头",
  checkbox_cameraAll: "全选",
  camera_num_ay: [
    "CH1",
    "CH2",
    "CH3",
    "CH4",
    "CH5",
    "CH6",
    "CH7",
    "CH8",
    "CH9",
    "CH10",
  ],
  th_video: "录像时长",
  videoUnit: "秒（0-120）",
  th_videoWebcam: "录像摄像头",
  checkbox_videoAll: "全选",
  videoWebcam_num_ay: [
    "CH1",
    "CH2",
    "CH3",
    "CH4",
    "CH5",
    "CH6",
    "CH7",
    "CH8",
    "CH9",
    "CH10",
  ],
  panel_title_info: "邮件",
  th_phone: "手机号码",
  phoneTip: "请正确填写手机号码，如果多个请使用|分隔",
  phoneContentTip: "短信内容为: 终端 + 时间 + 报警描述",
  th_mail: "邮件地址",
  mailTip:
    "请正确填写邮件地址，如果多个请使用竖线分隔,邮件内容为: 终端 + 时间 + 报警描述",
  submit: "保存",
  close: "关闭",
  top_CompanyID: "请选公司",
  top_bindid: "请选车组",
  th_area_name: "区域名称",

  th_maxSpeed: "最高速度",
  maxSpeedUnit1: "公里/时（0表示无限制）",
  maxSpeedUnit2: "英里/时（0表示无限制）",
  th_minSpeed: "最低速度",
  minSpeedUnit1: "公里/时（0表示无限制）",
  minSpeedUnit2: "英里/时（0表示无限制）",
  th_keyType: "抓拍模式",
  modeTypeValue: [
    [1, "循环抓拍"],
    [0, "独立抓拍"],
  ],

  th_time_jg: "抓拍间隔",
  offsetWidthUnit: "秒（60-9999）",
  th_begin_time_a: "时间范围2",
  th_keyType_xz: "下载类型",
  labelAlarmSelect: "报警类型",
  jj: "紧急",
  pz: "碰撞",
  cs: "超速",
  cpcw: "磁盘错误",
  lxls: "录像丢失",
  cclljg: "超出流量报警",
};
