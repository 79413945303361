export default {
  Title: "基本信息",

  uUserName: "用户名",
  uPwd: "密码",
  cPwd: "确认密码",

  uRoleID: "权限",
  uTrueName: "姓名",
  uTel: "电话",
  uRemark: "备注",
  uIsLogin: "登录状态",
  zhengchang: "正常",
  guanbi: "关闭",
  yes: "是",
  no: "否",
  top: "权限",
  uAddTime: "注册时间",
  uEmail: "用户邮箱",
  uCompanyID: "公司",
  uExpireTime: "有效期",
  uIsAdministrator: "用户分类",
  uIsExpire: "有效期启用",
  // uNum: "绑定车辆",
  uIsSingleLogin: "单点登陆",

  uIsEditPwd: "禁用修改密码",
  bdcz: "绑定车组",
  bdcl: "绑定车辆",

  // uUserLevel: "用户级别",
  // uUserLevel_select: [
  //   ["1", "I"],
  //   ["2", "II"],
  //   ["3", "III"],
  //   ["4", "V"],
  //   ["5", "IV"],
  // ],
  // btnSave: "保存",

  // uIsAdministrator_select1: [
  //   ["2", "企业管理员"],
  //   ["3", "车组管理员"],
  //   ["4", "普通用户"],
  // ],
  // uIsAdministrator_select2: [
  //   ["3", "车组管理员"],
  //   ["4", "普通用户"],
  // ],
  // pwdNOSame: "两次输入的密码不一致",
  // username_rule: "用户名6到16位（字母，数字，下划线）",
  // userpwd_rule: "至少6位 ，由数字、大写字母、小写字母组成",

  // uVelIDS_error: "当前用户已绑定其他公司车辆，请解绑后再次操作！",
};
