export default {
  Title: "创建公司",
  cName: "公司名称",
  cIndustryID: "所属行业",
  cContact: "公司联系人",
  cContactTel: "公司联系电话",
  cCJPerson: "公司法人",
  cCJPersonTel: "公司法人电话",
  cExpiryDate: "服务截止日期",
  cEnableExpiryDate: "是否启用服务截止日期",
  cBusinessCert: "经营许可证",
  cBusinessLicenseNo: "营业执照号",
  cAddress: "公司地址",
  cRegDate: "注册时间",
  cCompanyImage: "公司营业执照图片",
  cMaxDevNum: "注册设备数量",
  cMore: "备注",
  cLastUpdated: "添加时间",
  undelete_reason:
    "以下公司目前存在绑定车组或车辆，请先解绑后再删除！公司名称为：",
};
