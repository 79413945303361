import axios from "../../utils/axios";
export default {
  search_track_grid_excel(ay) {
    return axios({
      method: "get",
      url: "api/search/track_grid_excel",
      responseType: "blob", //二进制流
      data: ay,
    });
  },
  search_img_delete(ay) {
    return axios.post("api/search/img_delete", Object.assign({}, ay));
  },
  search_media_file_list_by_ids(ay) {
    return axios.post(
      "api/search/media_file_list_by_ids",
      Object.assign({}, ay)
    );
  },
  search_jspf_grid_excel(ay) {
    return axios({
      method: "get",
      url: "api/search/jspf_grid_excel",
      responseType: "blob", //二进制流
      data: ay,
    });
  },
  search_jspf_detail_excel(ay) {
    return axios({
      method: "get",
      url: "api/search/jspf_detail_excel",
      responseType: "blob", //二进制流
      data: ay,
    });
  },
  search_jspf_grid_pdf(ay) {
    return axios({
      method: "get",
      url: "api/search/jspf_grid_pdf",
      responseType: "blob", //二进制流
      data: ay,
    });
  },
  search_jspf_detail_pdf(ay) {
    return axios({
      method: "get",
      url: "api/search/jspf_detail_pdf",
      responseType: "blob", //二进制流
      data: ay,
    });
  },

  search_yhxw_detail_pdf(ay) {
    return axios({
      method: "get",
      url: "api/search/yhxw_detail_pdf",
      responseType: "blob", //二进制流
      data: ay,
    });
  },
  search_yhxw_detail_excel(ay) {
    return axios({
      method: "get",
      url: "api/search/yhxw_detail_excel",
      responseType: "blob", //二进制流
      data: ay,
    });
  },

  search_lltj_grid_excel(ay) {
    return axios({
      method: "get",
      url: "api/search/lltj_grid_excel",
      responseType: "blob", //二进制流
      data: ay,
    });
  },
  search_lltj_detail_excel(ay) {
    return axios({
      method: "get",
      url: "api/search/lltj_detail_excel",
      responseType: "blob", //二进制流
      data: ay,
    });
  },
  search_lltj_grid_pdf(ay) {
    return axios({
      method: "get",
      url: "api/search/lltj_grid_pdf",
      responseType: "blob", //二进制流
      data: ay,
    });
  },
  search_lltj_detail_pdf(ay) {
    return axios({
      method: "get",
      url: "api/search/lltj_detail_pdf",
      responseType: "blob", //二进制流
      data: ay,
    });
  },

  search_dzwl_grid_excel(ay) {
    return axios({
      method: "get",
      url: "api/search/dzwl_grid_excel",
      responseType: "blob", //二进制流
      data: ay,
    });
  },
  search_dzwl_detail_excel(ay) {
    return axios({
      method: "get",
      url: "api/search/dzwl_detail_excel",
      responseType: "blob", //二进制流
      data: ay,
    });
  },

  search_dzwl_grid_pdf(ay) {
    return axios({
      method: "get",
      url: "api/search/dzwl_grid_pdf",
      responseType: "blob", //二进制流
      data: ay,
    });
  },
  search_dzwl_detail_pdf(ay) {
    return axios({
      method: "get",
      url: "api/search/dzwl_detail_pdf",
      responseType: "blob", //二进制流
      data: ay,
    });
  },

  search_zdaqbj_grid_excel(ay) {
    return axios({
      method: "get",
      url: "api/search/zdaqbj_grid_excel",
      responseType: "blob", //二进制流
      data: ay,
    });
  },
  search_zdaqbj_detail_excel(ay) {
    return axios({
      method: "get",
      url: "api/search/zdaqbj_detail_excel",
      responseType: "blob", //二进制流
      data: ay,
    });
  },
  search_zdaqbj_grid_pdf(ay) {
    return axios({
      method: "get",
      url: "api/search/zdaqbj_grid_pdf",
      responseType: "blob", //二进制流
      data: ay,
    });
  },
  search_zdaqbj_detail_pdf(ay) {
    return axios({
      method: "get",
      url: "api/search/zdaqbj_detail_pdf",
      responseType: "blob", //二进制流
      data: ay,
    });
  },

  search_gzbj_grid_excel(ay) {
    return axios({
      method: "get",
      url: "api/search/gzbj_grid_excel",
      responseType: "blob", //二进制流
      data: ay,
    });
  },
  search_gzbj_detail_excel(ay) {
    return axios({
      method: "get",
      url: "api/search/gzbj_detail_excel",
      responseType: "blob", //二进制流
      data: ay,
    });
  },

  search_gzbj_grid_pdf(ay) {
    return axios({
      method: "get",
      url: "api/search/gzbj_grid_pdf",
      responseType: "blob", //二进制流
      data: ay,
    });
  },
  search_gzbj_detail_pdf(ay) {
    return axios({
      method: "get",
      url: "api/search/gzbj_detail_pdf",
      responseType: "blob", //二进制流
      data: ay,
    });
  },
  search_cgbj_grid_excel(ay) {
    return axios({
      method: "get",
      url: "api/search/cgbj_grid_excel",
      responseType: "blob", //二进制流
      data: ay,
    });
  },
  search_cgbj_detail_excel(ay) {
    return axios({
      method: "get",
      url: "api/search/cgbj_detail_excel",
      responseType: "blob", //二进制流
      data: ay,
    });
  },

  search_cgbj_detail_pdf(ay) {
    return axios({
      method: "get",
      url: "api/search/cgbj_detail_pdf",
      responseType: "blob", //二进制流
      data: ay,
    });
  },
  search_cgbj_grid_pdf(ay) {
    return axios({
      method: "get",
      url: "api/search/cgbj_grid_pdf",
      responseType: "blob", //二进制流
      data: ay,
    });
  },

  search_accdhtj_grid_pdf(ay) {
    return axios({
      method: "get",
      url: "api/search/accdhtj_grid_pdf",
      responseType: "blob", //二进制流
      data: ay,
    });
  },
  search_accdhtj_detail_pdf(ay) {
    return axios({
      method: "get",
      url: "api/search/accdhtj_detail_pdf",
      responseType: "blob", //二进制流
      data: ay,
    });
  },
  search_accdhtj_detail_excel(ay) {
    return axios({
      method: "get",
      url: "api/search/accdhtj_detail_excel",
      responseType: "blob", //二进制流
      data: ay,
    });
  },
  search_accdhtj_grid_excel(ay) {
    return axios({
      method: "get",
      url: "api/search/accdhtj_grid_excel",
      responseType: "blob", //二进制流
      data: ay,
    });
  },
  search_sxxtj_detail_pdf(ay) {
    return axios({
      method: "get",
      url: "api/search/sxxtj_detail_pdf",
      responseType: "blob", //二进制流
      data: ay,
    });
  },
  search_sxxtj_grid_pdf(ay) {
    return axios({
      method: "get",
      url: "api/search/sxxtj_grid_pdf",
      responseType: "blob", //二进制流
      data: ay,
    });
  },
  search_sxxtj_detail_excel(ay) {
    return axios({
      method: "get",
      url: "api/search/sxxtj_detail_excel",
      responseType: "blob", //二进制流
      data: ay,
    });
  },
  search_sxxtj_grid_excel(ay) {
    return axios({
      method: "get",
      url: "api/search/sxxtj_grid_excel",
      responseType: "blob", //二进制流
      data: ay,
    });
  },
  search_lctj_detail_excel(ay) {
    return axios({
      method: "get",
      url: "api/search/lctj_detail_excel",
      responseType: "blob", //二进制流
      data: ay,
    });
  },
  search_lctj_detail_pdf(ay) {
    return axios({
      method: "get",
      url: "api/search/lctj_detail_pdf",
      responseType: "blob", //二进制流
      data: ay,
    });
  },
  search_lctj_grid_excel(ay) {
    return axios({
      method: "get",
      url: "api/search/lctj_grid_excel",
      responseType: "blob", //二进制流
      data: ay,
    });
  },
  search_lctj_grid_pdf(ay) {
    return axios({
      method: "get",
      url: "api/search/lctj_grid_pdf",
      responseType: "blob", //二进制流
      data: ay,
    });
  },
  search_media_file_list(ay) {
    return axios.post("api/search/media_file_list", Object.assign({}, ay));
  },
  search_lastposition_list_alarm(ay) {
    return axios.post(
      "api/search/lastposition_list_alarm",
      Object.assign({}, ay)
    );
  },
  search_getVehInfo(ay) {
    return axios.post("api/search/getVehInfo", Object.assign({}, ay));
  },
  search_car_track_info(ay) {
    return axios.post("api/search/car_track_info", Object.assign({}, ay));
  },
  search_get_main_result(ay) {
    return axios.post("api/search/get_main_result", Object.assign({}, ay));
  },
  search_track_grid(ay) {
    return axios.post("api/search/track_grid", Object.assign({}, ay));
  },
  search_get_devices_state(ay) {
    return axios.post("api/search/get_devices_state", Object.assign({}, ay));
  },
  search_yhxw_detail(ay) {
    return axios.post("api/search/yhxw_detail", Object.assign({}, ay));
  },

  search_jspf_detail(ay) {
    return axios.post("api/search/jspf_detail", Object.assign({}, ay));
  },
  search_jspf_grid(ay) {
    return axios.post("api/search/jspf_grid", Object.assign({}, ay));
  },

  search_lltj_detail(ay) {
    return axios.post("api/search/lltj_detail", Object.assign({}, ay));
  },
  search_lltj_grid(ay) {
    return axios.post("api/search/lltj_grid", Object.assign({}, ay));
  },

  search_dzwl_detail(ay) {
    return axios.post("api/search/dzwl_detail", Object.assign({}, ay));
  },
  search_dzwl_grid(ay) {
    return axios.post("api/search/dzwl_grid", Object.assign({}, ay));
  },

  search_zdaqbj_grid_detail(ay) {
    return axios.post("api/search/zdaqbj_grid_detail", Object.assign({}, ay));
  },
  search_zdaqbj_detail(ay) {
    return axios.post("api/search/zdaqbj_detail", Object.assign({}, ay));
  },
  search_zdaqbj_grid(ay) {
    return axios.post("api/search/zdaqbj_grid", Object.assign({}, ay));
  },

  search_gzbj_detail(ay) {
    return axios.post("api/search/gzbj_detail", Object.assign({}, ay));
  },
  search_gzbj_grid(ay) {
    return axios.post("api/search/gzbj_grid", Object.assign({}, ay));
  },

  search_cgbj_detail(ay) {
    return axios.post("api/search/cgbj_detail", Object.assign({}, ay));
  },
  search_cgbj_grid(ay) {
    return axios.post("api/search/cgbj_grid", Object.assign({}, ay));
  },

  search_accdhtj_detail(ay) {
    return axios.post("api/search/accdhtj_detail", Object.assign({}, ay));
  },
  search_accdhtj_grid(ay) {
    return axios.post("api/search/accdhtj_grid", Object.assign({}, ay));
  },

  search_sxxtj_detail(ay) {
    return axios.post("api/search/sxxtj_detail", Object.assign({}, ay));
  },
  search_sxxtj_grid(ay) {
    return axios.post("api/search/sxxtj_grid", Object.assign({}, ay));
  },

  search_lctj_detail(ay) {
    return axios.post("api/search/lctj_detail", Object.assign({}, ay));
  },
  search_lctj_grid(ay) {
    return axios.post("api/search/lctj_grid", Object.assign({}, ay));
  },
  search_getGroupList(ay) {
    return axios.post("api/search/getGroupList", Object.assign({}, ay));
  },
};
