export default {
  dtsz: "Map Settings",
  dwsz: "Speed ​​Settings",
  spsz: "Video Settings",

  mrdt: "Map",
  ge: "Google Map",
  bd: "Baidu Map",
  osm: "Open Street Map",
  gekey: "Google Key",
  bdkey: "Baidu Key",
  maxCar: "Number of vehicles displayed on the map",
  kq: "ON",
  gb: "OFF",
  zdhqwz: "Obtain location auto",
  sytx: "Alarm Audio",
  sddw: "Speed",
  gbsj: "Video auto-off time",

  // smtppz: "SMTP Server",

  // dtkey: "Map Key",

  // gets: "Please fill in the Google Maps key",

  // bdts: "Please fill in the Baidu Map key",
  // send: "Save​",
  // reset: "Reset",

  // gbsjts: "Please fill in the video automatic closing time",
  // dw: "Speed：Min",
  // smtpsz: "SMTP Settings",
  // form: "Sender",
  // formts: "Please input sender",
  // showname: "Name",
  // showts: "Input Name",
  // user: "User Name",
  // userts: "Please Input Name",
  // pwd: "Password",
  // dz: "SMTP Addres",
  // dzts: "Please Input SMTP Add",
  // dk: "SMTP Port",
  // dkts: "Please Input SMTP Port",
  // zt: "Theme",
  // ztts: "Theme Select Please",
  // jmlx: "Encript Type",
  // bjm: "Non-Encript",
  // csyx: "Trial Mail",
  // ts: "Please set up correct time of auto shutdown streaming",
  // czcg: "Done",
  // czsb: "Error",
  // nrts: "Please Input Content",
  // sjrts: "Please Input Receiver",
  // sendemail: "Send",
  // mmts: "Please Input Password",
  // sjr: "Receiver",
  // nr: "Content",
  // fscg: "Done",
  // fssb: "Error",
  // dtpz: "Map Config",

  // maxCarTitle: "Please fill in the maximum number of Baidu maps to display",
  // carzhuyi: "*：Here refers to Baidu Maps",

  // maxCarError: "Please set the correct maximum display quantity",
};
