import axios from "../../utils/axios";
export default {
  sys_orderdata_save(ay) {
    return axios.post("api/system/orderdata_save", Object.assign({}, ay));
  },
  // sys_get_config_imgs(ay) {
  //   return axios.post("api/system/get_config_imgs", Object.assign({}, ay));
  // },
  sys_ftp_update(ay) {
    return axios.post("api/system/ftp_update", Object.assign({}, ay), {
      hide_loading: 1,
    });
  },
  sys_ftp_delete(ay) {
    return axios.post("api/system/ftp_delete", Object.assign({}, ay));
  },
  sys_users_update_pwd(ay) {
    return axios.post("api/system/users_update_pwd", Object.assign({}, ay));
  },
  sys_get_user_favs(ay) {
    return axios.post("api/system/get_user_favs", Object.assign({}, ay), {
      hide_loading: 1,
    });
  },
  sys_set_user_favs(ay) {
    return axios.post("api/system/set_user_favs", Object.assign({}, ay));
  },
  sys_users_grids(ay) {
    return axios.post("api/system/users_grids", Object.assign({}, ay));
  },
  sys_ckfj_grid(ay) {
    return axios.post("api/system/ckfj_grid", Object.assign({}, ay));
  },
  sys_url_download(url) {
    return axios({
      method: "get",
      url: url,
      responseType: "arraybuffer",
      // responseType: "blob", //二进制流
    });
  },
  sys_ftp_grid(ay) {
    return axios.post("api/system/ftp_grid", Object.assign({}, ay));
  },
  sys_get_car_clpf(ay) {
    return axios.post("api/system/get_car_clpf", Object.assign({}, ay));
  },
  sys_get_car_lctj(ay) {
    return axios.post("api/system/get_car_lctj", Object.assign({}, ay));
  },
  sys_get_main_result(ay) {
    return axios.post("api/system/get_main_result", Object.assign({}, ay));
  },
  sys_save_fwqpz(ay) {
    return axios.post("api/system/save_fwqpz", Object.assign({}, ay));
  },
  sys_get_fwqpz(ay) {
    return axios.post("api/system/get_fwqpz", Object.assign({}, ay));
  },
  sys_save_xtpz(ay) {
    return axios.post("api/system/save_xtpz", Object.assign({}, ay));
  },
  sys_get_xtpz(ay) {
    return axios.post("api/system/get_xtpz", Object.assign({}, ay));
  },
  sys_rect_delete(ay) {
    return axios.post("api/system/rect_delete", Object.assign({}, ay));
  },
  sys_rect_grid(ay) {
    return axios.post("api/system/rect_grid", Object.assign({}, ay));
  },
  sys_rect_save(ay) {
    return axios.post("api/system/rect_save", Object.assign({}, ay));
  },
  sys_vehgroup_veh_config_add(ay) {
    return axios.post(
      "api/system/vehgroup_veh_config_add",
      Object.assign({}, ay)
    );
  },
  sys_vehicles_config_grid(ay) {
    return axios.post("api/system/vehicles_config_grid", Object.assign({}, ay));
  },
  sys_vehicles_config_grid(ay) {
    return axios.post("api/system/vehicles_config_grid", Object.assign({}, ay));
  },
  sys_vehgroup_config_delete(ay) {
    return axios.post(
      "api/system/vehgroup_config_delete",
      Object.assign({}, ay)
    );
  },
  sys_vehgroup_save_config(ay) {
    return axios.post("api/system/vehgroup_save_config", Object.assign({}, ay));
  },
  sys_rules_save(ay) {
    return axios.post("api/system/rules_save", Object.assign({}, ay));
  },
  sys_rules_list(ay) {
    return axios.post("api/system/rules_list", Object.assign({}, ay));
  },
  sys_rules_row(ay) {
    return axios.post("api/system/rules_row", Object.assign({}, ay));
  },
  sys_rules_delete(ay) {
    return axios.post("api/system/rules_delete", Object.assign({}, ay));
  },
  sys_users_save(ay) {
    return axios.post("api/system/users_save", Object.assign({}, ay));
  },
  sys_users_grid(ay) {
    return axios.post("api/system/users_grid", Object.assign({}, ay));
  },
  sys_users_row(ay) {
    return axios.post("api/system/users_row", Object.assign({}, ay));
  },
  sys_users_delete(ay) {
    return axios.post("api/system/users_delete", Object.assign({}, ay));
  },

  sys_get_vehgroup_all(ay) {
    return axios.post("api/system/get_vehgroup_all", Object.assign({}, ay));
  },
  sys_role_select(ay) {
    return axios.post("api/system/role_select", Object.assign({}, ay));
  },
  sys_get_vehicles_all(ay) {
    return axios.post("api/system/get_vehicles_all", Object.assign({}, ay));
  },
  sys_drivers_save(ay) {
    return axios.post("api/system/drivers_save", Object.assign({}, ay));
  },
  sys_drivers_grid(ay) {
    return axios.post("api/system/drivers_grid", Object.assign({}, ay));
  },
  sys_drivers_row(ay) {
    return axios.post("api/system/drivers_row", Object.assign({}, ay));
  },
  sys_drivers_delete(ay) {
    return axios.post("api/system/drivers_delete", Object.assign({}, ay));
  },

  sys_insurances_save(ay) {
    return axios.post("api/system/insurances_save", Object.assign({}, ay));
  },
  sys_insurances_grid(ay) {
    return axios.post("api/system/insurances_grid", Object.assign({}, ay));
  },
  sys_insurances_row(ay) {
    return axios.post("api/system/insurances_row", Object.assign({}, ay));
  },
  sys_insurances_delete(ay) {
    return axios.post("api/system/insurances_delete", Object.assign({}, ay));
  },

  sys_sims_grid(ay) {
    return axios.post("api/system/sims_grid", Object.assign({}, ay));
  },

  sys_companys_save(ay) {
    return axios.post("api/system/companys_save", Object.assign({}, ay));
  },
  sys_companys_grid(ay) {
    return axios.post("api/system/companys_grid", Object.assign({}, ay));
  },
  sys_companys_row(ay) {
    return axios.post("api/system/companys_row", Object.assign({}, ay));
  },
  sys_companys_delete(ay) {
    return axios.post("api/system/companys_delete", Object.assign({}, ay));
  },

  sys_role_save(ay) {
    return axios.post("api/system/role_save", Object.assign({}, ay));
  },
  sys_role_grid(ay) {
    return axios.post("api/system/role_grid", Object.assign({}, ay));
  },
  sys_role_row(ay) {
    return axios.post("api/system/role_row", Object.assign({}, ay));
  },
  sys_role_delete(ay) {
    return axios.post("api/system/role_delete", Object.assign({}, ay));
  },

  sys_init_datas(ay) {
    return axios.post("api/system/init_datas", Object.assign({}, ay));
  },
  sys_vehicles_grid_excel(ay) {
    return axios({
      method: "get",
      url: "api/system/vehicles_grid_excel",
      responseType: "blob", //二进制流
      data: ay,
    });
  },
  sys_orderdata_save(ay, tables) {
    var json = {
      id: "11111-11111-11111-11111",
      type: "refresh",
      content: { tablename: tables, refreshtype: 5 },
    };
    return axios.post(
      "api/system/orderdata_save",
      Object.assign(
        {
          id: 0,
          oDeviceID: 0,
          oDevType: 0,
          oOrderType: "refresh",
          oOrderContent: JSON.stringify(json),
          oSendState: 0,
        },
        ay
      )
    );
  },
  sys_vehicles_delete(ay) {
    return axios.post("api/system/vehicles_delete", Object.assign({}, ay));
  },
  sys_vehicles_row(ay) {
    return axios.post("api/system/vehicles_row", Object.assign({}, ay));
  },
  sys_vehicles_save(ay) {
    return axios.post("api/system/vehicles_save", Object.assign({}, ay));
  },
  sys_get_bases(ay) {
    return axios.post("api/system/get_bases", Object.assign({}, ay));
  },
  sys_vehicles_grid(ay) {
    return axios.post("api/system/vehicles_grid", Object.assign({}, ay));
  },
  sys_role_grid(ay) {
    return axios.post("api/system/role_grid", Object.assign({}, ay));
  },
};
