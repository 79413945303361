export default {
  cph: "Plate No.",
  sbh: "Device ID",
  ssgs: "Company",
  kssj: "Start Time",
  jssj: "End Time",
  sim: "SIM Card",
  sxcs: "Online",
  xxcs: "Offline",
  dswz: "Location",
  dwsj: "Time",
  dszt: "State",
  zqxslc: "Trip Mileage​",
  tcsc: "Parking Time",
  cslc: "Start Mileage",
  jslc: "End Mileage",
  kswz: "Departure",
  jswz: "Arrival",
  xssj: "Trip Time​",
  pjsd: "Average Speed​",
  acck: "Ignition ON",
  accg: "Ignition OFF",
  acczt: "Ignition",

  csc: "Overspd",
  sgc: "Collision",
  sosc: "Panic",
  ydzc: "Motion Detection",
  spzd: "Camcover",
  cjc: "Remove",
  ffjs: "Lllegal Driving",
  dcdld: "Low Volt",
  cgqbj: "Trigger",
  pljsbj: "Fatigue",
  cstcbj: "Idiling",
  jjs: "HA",
  jjsbj: "HB",
  jzw: "HC",
  bjsj: "Start Time",
  ejsj: "End Time",
  su: "Speed",
  bjwz: "Location",
  bjnr: "Alarm",
  wlmc: "Fence Name",
  gps_dw: "OK",
  xq: "Details",

  cbyc: "Disk Error",
  lxyc: "Recording",
  gpscw: "GPS Error",
  dyyc: "Low Volt",
  spds_c: "Video Loss",
  wifimk: "WIFI Error",

  ADAS: "ADAS",
  DMS: "DMS",
  BSD: "BSD",
  jsxwbj: "Unsafe Driving​",
  ckfj: "Media",
  // bjsj: "Time",
  bjjb: "Alarm Level",
  fjsl: "Media",

  jrdzwl: "In Fence​",
  lkdzwl: "Out Fence",
  xsqycs: "Geo-overspd",
  xsqyds: "Geo-Lowspd",
  xlpy: "Route Deviation",

  sxll: "Upload",
  xxll: "Download",
  zll: "All",
  tjsj: "Time",
  fwd: "Access Mode",
  cz: "Action",
  IP: "IP",
  gzbj: "Health Data",

  // dcxslc: "Trip Mileage(KM)",

  acc_1: "Ignition ON",
  acc_0: "Ignition OFF",

  // acckwz: "Ignition ON Location",
  acccwz: "Ignition OFF Location",

  // sjxm: "Name",
  // sjdh: "Tel",
  // wgcs: "Violation",
  // wgsjzc: "Time(Min)",

  // lxjscc: "Duration(Min)",
  // sfyjjs: "Night Travel",

  // myll: "Cellular Data/Month",
  // syll: "Remaining Data​",
  // syllbfb: "Remaining Data %",
  // llqlsj: "Data Reset Time",
  // gbsp: "OFF Video",
  // lszjll: "Temporary In of Data",
  // llql: "Reset",

  // syllkb: "Cellular",

  zh: "Account",
  xm: "Name",
  dlcs: "Log in",
  dlsc: "Time(Min)",
  czsj: "Time",
  czxw: "Type",

  jsy: "Driver",
  fs: "Fraction",
  zxkfsj: "Deduction Time​",
  kfyy: "Reason For Fine",

  cx: "Model",
  rz: "License",
  kfsj: "Time",
  kfmx: "Details",
  yjjs: "Night Trip",
  kffs: "Deduct",

  e_1: "Panic",
  e_2: "Overspd",
  e_3: "Fatigue",
  e_4: "Early Warning",
  e_5: "GPS Error",
  e_6: "GPS Cable Error",
  e_7: "GPS Cable Shorted​",
  e_8: "Main Voltage Low",
  e_9: "No Power​",
  e_10: "LCD Error",
  e_11: "TTS Error",
  e_12: "Camera Error",
  e_13: "IC Card Error",
  e_14: "Overspd Early Warning",
  e_15: "Fatigue Early Warning",
  e_19: "Duration/Day",
  e_20: "Idiling",
  e_21: "Fence",
  e_22: "Route",
  e_23: "Route Time",
  e_24: "Route Deviation",
  e_25: "VSS Error",
  e_26: "Fuel Alarm",
  e_27: "Car Stolen​",
  e_28: "Illegal Ignition​",
  e_29: "Illegal Trip",
  e_30: "Collision",
  e_31: "Rollover",
  e_32: "Illegal Entry",

  e_33: "Camera Error",
  e_34: "Cam-Covered​",
  e_35: "Disk Alarm",
  e_36: "Other Video Error​",
  e_37: "Overload Alarm​",
  e_38: "Abnormal Driving",
  e_39: "Event video memory reach the threshold​",

  e_65: "Fence Overspd",
  e_66: "Fence Lowspd",
  e_67: "Fence Alarm",
  e_68: "Route Deviation",
  e_69: "Time Slot Overspeed",
  e_70: "Time Slot Lowspd",
  e_71: "Fatigue",
  e_72: "Idiling",
  e_73: "Key Location Alarm",
  e_74: "Route Overspeed",
  e_75: "Route Lowspd",
  e_76: "Road Overspd",
  e_77: "Night Trip",
  e_78: "In Fence",
  e_79: "Out Fence",

  e_101: "Camera Error",
  e_102: "Cam-Covered",
  e_103: "Disk Alarm",
  e_104: "Other Video Error",
  e_105: "Overload Alarm",
  e_106: "Abnormal Driving Alarm",
  e_107: "Alarm Record Overload Alarm",

  e_201: "Fence Overspd",
  e_202: "Fence Lowspd",
  e_203: "Fence Alarm",
  e_204: "Route Deviation",
  e_205: "Time Slot Overspeed",

  e_206: "Time Slot Lowspd",
  e_207: "Fatigue",
  e_208: "Idiling",
  e_209: "Key Location Alarm",
  e_210: "Route Overspeed",
  e_211: "Route Lowspd",
  e_212: "Road Overspd",
  e_213: "Night Trip",
  e_214: "In Fence",
  e_215: "Out Fence",
  e_216: "Remove",
  e_217: "Panic",
  e_218: "Front Door Alarm",
  e_219: "Middle Door Alarm",
  e_220: "Back Door Alarm",
  e_221: "Right",
  e_222: "Left",
  e_223: "Brake",
  e_224: "Reverse",
  //IO1  Panic   IO8  Reverse   IO6  Left  IO5  Right   IO7Brake  IO2 Front Door  IO3Middle Door IO4Back Door
  login_1: "LOGIN",
  login_2: "Exit",

  koufen_1: "Overspd",
  koufen_2: "HC",
  koufen_3: "HA",
  koufen_4: "HB",
  koufen_5: "Fatigue",
  koufen_6: "Other",

  auth_0: "Unauth",
  auth_1: "Facial Matching",
  auth_2: "IC Card Matching",

  yjjs_0: "No",
  yjjs_1: "Yes",

  item_detail: "Details",
  item_addr: "Location",
  gps_0: "(LBS)",
  gps_1: "(GPS)",
  gps_2: "(Not Iocate)",
  zt_1: "Online",
  zt_0: "Offline",

  gps_dw: "GPS",
  tree_root_name: "All",
  pljsbj: "Fatigue",
  cstcbj: "Idiling",
  srsbid: "Fill in the device ID",
  xz: "Download",

  timeError: "The start time must be less than the end time!",
  timeError2:
    "Only video files within thirty minutes are allowed to be downloaded at a time!",
  time_out_msg: "Only one month's data can be queried!",

  qxztdh: "Please select the channel.​!",
  ksxz: "Download",
  qxhqxzwj: "Please search for recording files first.!",
  zwfhdxzwj: "There are no recording files.!",
  td: "Channel",
  ksrq: "Start Date​",
  zwjg: "No Data",

  ml_8103: "Set Param",
  ml_8104: "Query Parameters",
  ml_8106: "Query Unit Parameter",
  ml_0104: "Answer",
  ml_8105: "Control",
  ml_8107: "Attribute",
  ml_0107: "Answer",
  ml_8201: "Location",
  ml_8300: "Send TTS",
  ml_8400: "Call Back​",
  ml_8500: "Control",
  ml_0500: "Answer",
  ml_9206: "Recording Download/Custom Parameters",
  ml_8202: "Tracking Mode",
  ml_9101: "Request Live",
  ml_9102: "Live Control​",
  ml_8801: "Camera",
  ml_9205: "Query Video Record​",
  ml_1205: "List of video files send by the device​",
  ml_9201: "Video Playback​",
  ml_9202: "Video Playback Control​",
  ml_8900: "Downside Transparent",
  ml_0900: "Upload Transparent",
  ml_B054: "Getparameter",
  ml_B055: "Answer",
  ml_B056: "Set Param",
  ml_B057: "Answer",

  tcsc: "Parking Time",
  xssj: "Trip Time",
  pjsd: "Mean Speed",
  kswz: "Departure",
  jswz: "Arrival",

  excel: "EXCEL",
  pdf: "PDF",

  jjs: "HA",
  jjsbj: "HB",
  jzw: "HC",

  e_301: "FCW",
  e_302: "LDWS",
  e_303: "FCW",
  e_304: "PCW",
  e_305: "LDWS",
  e_306: "Road Overspd",
  e_307: "Obstacle Alarm",
  e_316: "Road Signs",
  e_317: "ADAS Camera",
  e_351: "Fatigue",
  e_352: "Call Up",
  e_353: "Smoking​",
  e_354: "Distracted",
  e_355: "No Driver​",
  e_360: "Infrared Blok",
  e_361: "Camcover",
  e_366: "DMS Camera",
  e_367: "Change Driver",
  e_380: "Tire Pressure",
  e_401: "BSD Rear",
  e_402: "BSD Left",
  e_403: "BSD Right",
  e_308: "ADAS Error",
  e_356: "Seat Belt",
  e_357: "DMS Error",
  e_381: "​High TP",
  e_382: "Low TP",
  e_383: "High Tire Temp",
  e_384: "Sensor Abnormal",
  e_385: "Tyre Inbalance",
  e_386: "Leak Alarm",
  e_387: "Sensor Voltage Low",
  e_431: "HA",
  e_432: "HB",
  e_433: "HC",
  e_434: "Idiling",
  e_435: "Ignition Error",
  e_436: "Coasting Alarm​",
  e_437: "Engine Overload",
  yjbj: "Level 1 Alarm",
  ejbj: "Level 2 Alarm",
};
