export default {
  cph: "车牌号",
  sbh: "设备号",
  ssgs: "所属公司",
  kssj: "开始时间",
  jssj: "结束时间",
  sim: "SIM卡号",
  sxcs: "上线次数",
  xxcs: "下线次数",
  dswz: "位置",
  dwsj: "时间",
  dszt: "状态",
  zqxslc: "行驶里程",
  tcsc: "停车时长",
  cslc: "开始里程",
  jslc: "结束里程",
  kswz: "开始位置",
  jswz: "结束位置",
  xssj: "行驶时间",
  pjsd: "平均速度",
  acck: "ACC开",
  accg: "ACC关",
  acczt: "ACC状态",

  csc: "超速",
  sgc: "碰撞",
  sosc: "紧急",
  ydzc: "移动帧测",
  spzd: "视频遮挡",
  cjc: "拆机",
  ffjs: "非法驾驶",
  dcdld: "电池电量低",
  cgqbj: "IO报警",
  pljsbj: "疲劳驾驶",
  cstcbj: "怠速",
  jjs: "急加速",
  jjsbj: "急减速",
  jzw: "急转弯",
  bjsj: "报警开始时间",
  ejsj: "报警结束时间",
  su: "速度",
  bjwz: "报警位置",
  bjnr: "报警内容",
  wlmc: "围栏名称",
  gps_dw: "定位",
  xq: "详情",

  cbyc: "磁盘异常",
  lxyc: "录像状态",
  gpscw: "GPS错误",
  dyyc: "电压异常",
  spds_c: "视频丢失",
  wifimk: "WIFI模块错误",

  ADAS: "ADAS",
  DMS: "DMS",
  BSD: "BSD",
  jsxwbj: "驾驶行为",
  ckfj: "查看附件",
  bjsj: "报警时间",
  bjjb: "报警级别",
  fjsl: "附件数量",

  jrdzwl: "进入围栏",
  lkdzwl: "离开围栏",
  xsqycs: "区域超速",
  xsqyds: "区域低速",
  xlpy: "线路偏移",

  sxll: "上行流量",
  xxll: "下行流量",
  zll: "总流量",
  tjsj: "统计时间",
  fwd: "访问端",
  cz: "操作",
  IP: "IP",
  gzbj: "故障报警",

  // dcxslc: "单次行驶里程(km)",

  acc_1: "ACC开",
  acc_0: "ACC关",

  // acckwz: "ACC开位置",
  acccwz: "ACC关位置",

  // sjxm: "司机姓名",
  // sjdh: "司机电话",
  // wgcs: "违规次数",
  // wgsjzc: "违规时间总长(分)",

  // lxjscc: "连续驾驶时长(分)",
  // sfyjjs: "是否夜间驾驶",

  // myll: "每月流量",
  // syll: "剩余流量",
  // syllbfb: "剩余流量百分比",
  // llqlsj: "流量清零时间",
  // gbsp: "关闭视频",
  // lszjll: "临时增加流量",
  // llql: "流量清零",

  // syllkb: "使用流量",

  zh: "账号",
  xm: "姓名",
  dlcs: "登录次数",
  dlsc: "登录时长(分钟)",
  czsj: "操作时间",
  czxw: "操作类型",

  jsy: "驾驶员",
  fs: "分数",
  zxkfsj: "最新扣分时间",
  kfyy: "扣分原因",

  cx: "车型",
  rz: "认证",
  kfsj: "扣分时间",
  kfmx: "扣分明细",
  yjjs: "夜间驾驶",
  kffs: "扣除分数",

  e_1: "紧急报警",
  e_2: "超速报警",
  e_3: "疲劳驾驶",
  e_4: "危险预警",
  e_5: "GPS模块故障",
  e_6: "GPS天线未接或被剪断",
  e_7: "GPS天线短路",
  e_8: "主电源欠压",
  e_9: "主电源掉电",
  e_10: "LCD或显示器故障",
  e_11: "TTS模块故障",
  e_12: "摄像头故障",
  e_13: "道路运输证IC卡模块故障",
  e_14: "超速预警",
  e_15: "疲劳驾驶预警",
  e_19: "当天累计驾驶超时",
  e_20: "超时停车",
  e_21: "进出区域",
  e_22: "进出线路",
  e_23: "路段行驶时间不足或过长",
  e_24: "路线偏离报警",
  e_25: "车辆VSS故障",
  e_26: "车辆油量异常",
  e_27: "车辆被盗",
  e_28: "车辆非法点火",
  e_29: "车辆非法位移",
  e_30: "碰撞报警",
  e_31: "侧翻报警",
  e_32: "非法开门预警",

  e_33: "视频丢失报警",
  e_34: "视频遮挡报警",
  e_35: "存储故障报警",
  e_36: "其他视频设备故障报警",
  e_37: "客车超员报警",
  e_38: "异常驾驶报警",
  e_39: "特殊报警录像达到存储阀值报警",

  e_65: "区域超速报警",
  e_66: "区域低速报警",
  e_67: "进出区域报警",
  e_68: "线路偏离报警",
  e_69: "时间段超速报警",
  e_70: "时间段低速报警",
  e_71: "疲劳驾驶报警",
  e_72: "超时停车报警",
  e_73: "关键点监控报警",
  e_74: "线路超速报警",
  e_75: "线路低速报警",
  e_76: "道路等级超速报警",
  e_77: "夜间行车报警",
  e_78: "进区域报警",
  e_79: "出区域报警",

  e_101: "视频丢失报警",
  e_102: "视频遮挡报警",
  e_103: "存储故障报警",
  e_104: "其他视频设备故障报警",
  e_105: "客车超员报警",
  e_106: "驾驶行为报警",
  e_107: "特殊报警录像达到存储阀值报警",

  e_201: "区域超速报警",
  e_202: "区域低速报警",
  e_203: "进出区域报警",
  e_204: "线路偏离报警",
  e_205: "时间段超速报警",

  e_206: "时间段低速报警",
  e_207: "疲劳驾驶报警",
  e_208: "超时停车报警",
  e_209: "关键点监控报警",
  e_210: "线路超速报警",
  e_211: "线路低速报警",
  e_212: "道路等级超速报警",
  e_213: "夜间行车报警",
  e_214: "进区域报警",
  e_215: "出区域报警",
  e_216: "拆机报警",
  e_217: "紧急报警",
  e_218: "前门报警",
  e_219: "中门报警",
  e_220: "后门报警",
  e_221: "右转",
  e_222: "左转",
  e_223: "刹车",
  e_224: "倒车",
  //IO1  紧急   IO8  倒车   IO6  左转  IO5  右转   IO7刹车  IO2 前门  IO3中门 IO4
  login_1: "登录",
  login_2: "退出",

  koufen_1: "超速",
  koufen_2: "急转弯",
  koufen_3: "急加速",
  koufen_4: "急刹车",
  koufen_5: "疲劳驾驶",
  koufen_6: "其他",

  auth_0: "未认证",
  auth_1: "人脸认证",
  auth_2: "IC认证",

  yjjs_0: "否",
  yjjs_1: "是",

  item_detail: "详情",
  item_addr: "获取位置",
  gps_0: "(基站定位)",
  gps_1: "(GPS定位)",
  gps_2: "(不定位)",
  zt_1: "上线",
  zt_0: "下线",

  gps_dw: "定位",
  tree_root_name: "所有数据",
  pljsbj: "疲劳驾驶报警",
  cstcbj: "怠速报警",
  srsbid: "填写设备ID",
  xz: "下载",

  timeError: "开始时间必须小于结束时间!",
  timeError2: "一次之多下载三十分钟内的视频文件！",
  time_out_msg: "只能查询一月内的数据!",

  qxztdh: "请选择通道!",
  ksxz: "开始下载",
  qxhqxzwj: "请先获取下载文件!",
  zwfhdxzwj: "暂无符合的下载文件!",
  td: "通道",
  ksrq: "开始日期",
  zwjg: "暂无结果",

  ml_8103: "设置设备参数",
  ml_8104: "查询设备参数",
  ml_8106: "查询指定设备参数",
  ml_0104: "查询设备参数应答",
  ml_8105: "设备控制",
  ml_8107: "查询设备属性",
  ml_0107: "查询设备属性应答",
  ml_8201: "位置信息查询",
  ml_8300: "文本信息下发",
  ml_8400: "电话回拨",
  ml_8500: "车辆控制",
  ml_0500: "车辆控制应答",
  ml_9206: "录像下载/自定义参数配置命令",
  ml_8202: "开启/关闭跟踪模式",
  ml_9101: "实时音视频传输请求",
  ml_9102: "音视频实时传输控制",
  ml_8801: "拍照",
  ml_9205: "查询录像文件列表",
  ml_1205: "终端上传录像文件列表",
  ml_9201: "远程录像回放请求",
  ml_9202: "下发远程录像回放控制",
  ml_8900: "数据下行透传",
  ml_0900: "数据上行透传",
  ml_B054: "获取参数文件",
  ml_B055: "获取参数文件回复",
  ml_B056: "设置参数文件",
  ml_B057: "设置参数文件回复",

  tcsc: "停车时长",
  xssj: "行驶时间",
  pjsd: "平均速度",
  kswz: "开始位置",
  jswz: "结束位置",

  excel: "Excel导出",
  pdf: "Pdf导出",

  jjs: "急加速",
  jjsbj: "急减速",
  jzw: "急转弯",

  e_301: "前向碰撞报警",
  e_302: "车道偏离报警",
  e_303: "车距过近报警",
  e_304: "行人碰撞报警",
  e_305: "频繁变道报警",
  e_306: "道路标识超限报警",
  e_307: "障碍物报警",
  e_316: "道路标志识别事件",
  e_317: "主动抓拍事件",
  e_351: "疲劳驾驶报警",
  e_352: "接打电话报警",
  e_353: "抽烟报警",
  e_354: "分神驾驶报警/长时间不目视前方报警",
  e_355: "驾驶员异常报警/未检测到驾驶员报警",
  e_360: "红外阻断报警",
  e_361: "遮挡报警",
  e_366: "自动抓拍事件",
  e_367: "驾驶员变更事件",
  e_380: "胎压（定时上报）",
  e_401: "后方接近报警",
  e_402: "左侧后方接近报警",
  e_403: "右侧后方接近报警",
  e_308: "驾驶辅助功能失效报警",
  e_356: "未系安全带",
  e_357: "驾驶员行为监测功能失效报警",
  e_381: "胎压过高报警",
  e_382: "胎压过低报警",
  e_383: "胎温过高报警",
  e_384: "传感器异常报警",
  e_385: "胎压不平衡报警",
  e_386: "慢漏气报警",
  e_387: "电池电量低报警",
  e_431: "急加速报警",
  e_432: "急减速报警",
  e_433: "急转弯报警",
  e_434: "怠速报警",
  e_435: "异常熄火报警",
  e_436: "空挡滑行报警",
  e_437: "发动机超转报警",
  yjbj: "一级报警",
  ejbj: "二级报警",
};
