import axios from "axios";
import { ElMessage, ElLoading } from "element-plus";
import VueCookies from "vue-cookies";
import { makeSign } from "./tools";
import store from "../store/index";

// axios默认配置
axios.defaults.timeout = 10000; // 超时时间
axios.defaults.baseURL = web_url; //process.env.VUE_APP_BASE_URL;
//production
if (process.env.NODE_ENV == "development") {
  axios.defaults.baseURL = process.env.VUE_APP_BASE_DEV_URL;
}
// console.log("process.env.NODE_ENV", process.env.NODE_ENV);

let loadingObj = null;

// http request 拦截器
axios.interceptors.request.use(
  (config) => {
    // console.log(config);
    // config.headers["Content-Type"] = "application/json;charset=UTF-8";
    if (config["hide_loading"] && config["hide_loading"] == 1) {
    } else {
      loadingObj = ElLoading.service({
        text: "加载中。。。。",
        lock: true,
      });
    }

    // if (config.headers === undefined) {
    //   config.headers = {};
    // }
    // config.headers["Content-Type"] = "application/x-www-form-urlencoded";
    // config.transformRequest = [
    //   function (data) {
    //     let ayData = [];
    //     for (let p in data) {
    //       ayData.push(
    //         encodeURIComponent(p) + "=" + encodeURIComponent(data[p])
    //       );
    //     }
    //     return ayData.join("&");
    //   },
    // ];

    config.headers["Content-Type"] =
      "application/x-www-form-urlencoded;charset=UTF-8";
    // config.headers["Cache-Control"] = "no-cache";
    if (VueCookies.get("gps_token")) {
      config.headers.Authorization = `GPS${VueCookies.get("gps_token")}`;
    }
    const time = Math.floor(new Date().getTime() / 1000);
    const appId = Math.ceil(Math.random() * 100000);
    const signObj = {
      time: time,
      app_id: appId,
    };
    const sign = makeSign(signObj);
    signObj.sign = sign;
    // 登录成功附加的两个参数
    if (store.getters.token) {
      signObj.token = store.getters.token;
      // signObj["uid"] = store.getters.uid;
    }

    if (localStorage.lang) {
      signObj.lang = localStorage.lang;
    } else {
      signObj.lang = "cn";
    }

    if (config.method === "post") {
      for (const p in signObj) {
        config.data[p] = signObj[p];
      }
      config.transformRequest = [
        function (data) {
          const ayData = [];
          for (const p in data) {
            ayData.push(
              encodeURIComponent(p) + "=" + encodeURIComponent(data[p])
            );
          }
          return ayData.join("&");
        },
      ];
    } else {
      const url = config.url;
      const ayUrl = [];
      for (let p in config.data) {
        ayUrl.push(
          encodeURIComponent(p) + "=" + encodeURIComponent(config.data[p])
        );
      }
      for (const p in signObj) {
        ayUrl.push(p + "=" + signObj[p]);
      }
      const urlParam = ayUrl.join("&");
      if (url.indexOf("?") > -1) {
        config.url += "&" + urlParam;
      } else {
        config.url += "?" + urlParam;
      }
    }

    return config;
  },
  (error) => Promise.reject(error.response)
);

// http response 拦截器
axios.interceptors.response.use(
  (response) => {
    if (loadingObj != null) {
      loadingObj.close();
    }

    // console.log(response.data, "axios");
    if (response.data.result === 400) {
      ElMessage({
        message: response.data.msg,
        type: "warning",
      });
      return Promise.reject(response);
    }

    return Promise.resolve(response);
  },
  (error) => {
    loadingObj.close();
    return Promise.reject(error); // 返回接口返回的错误信息
  }
);
export default axios;
