export default {
  searchTip: "​Rule Name",
  btnAllData: "All Rule",
  btnAddRule: "Add",
  typeTip:
    "Instructions for using the rules: 1. Add rules 2. Set custom trigger conditions 3. Authorize the vehicle that needs to execute this rule",

  ID: "Serial No.",
  rRuleName: "​Rule Name",
  rRuleType: "Rule Type",
  rCompanyID: "Company",
  rLastUpdated: "Time",
  item_edit: "Edit",
  item_delete: "Del",
  rule_empty: "Please choose the rules!",
  item_bind: "Vehicle",

  yes: "Save",
  no: "Close",
  alert_confirm: "Are you sure you want to delete?",
  success: "Done",
  error: "Error",

  rule_title: "Information​",
  th_rule_name: "Rule Name",
  th_vehgroup: "Company",

  th_travel_time: "Trip Time",
  travelTimeUnit: "Min",
  th_rest_time: "Rest Time",
  restTimeUnit: "Min",
  th_min_speed: "Min Speed",
  minSpeedUnit1: "KM/H（0 No Limit）",
  minSpeedUnit2: "MP/H（0 No Limit）",
  th_begin_time: "Time Range",
  time_split: "To",
  th_voice: "Alarm Audio",
  panel_title: "Picture And Video​​",
  th_camera: "Picture",
  label_camera_yes: "Yes",
  label_camera_no: "No",
  th_cameraWebcam: "Camera",
  checkbox_cameraAll: "All",
  camera_num_ay: [
    "CH1",
    "CH2",
    "CH3",
    "CH4",
    "CH5",
    "CH6",
    "CH7",
    "CH8",
    "CH9",
    "CH10",
  ],
  th_video: "Video",
  videoUnit: "Sec（0-120）",
  th_videoWebcam: "Camera",
  checkbox_videoAll: "All",
  videoWebcam_num_ay: [
    "CH1",
    "CH2",
    "CH3",
    "CH4",
    "CH5",
    "CH6",
    "CH7",
    "CH8",
    "CH9",
    "CH10",
  ],
  panel_title_info: "Email",
  th_phone: "Mobile phone No.",
  phoneTip:
    "Please in your phone number. For multiple phone numbers, please use | Isolation​.",
  phoneContentTip: "​SMS: Plate No. + Time + Alarm",
  th_mail: "Email Address",
  mailTip:
    "Please fill in the email. If there are multiple emails, please use I to separate them. The email content is: device + time + alarm.",
  submit: "Save",
  close: "Close",
  top_CompanyID: "Please Select Company​",
  top_bindid: "Please Select Fleet",
  th_area_name: "Fence Name",

  th_maxSpeed: "Max Speed",
  maxSpeedUnit1: "KM/H（0 No Limit）",
  maxSpeedUnit2: "MP/H（0 No Limit）",
  th_minSpeed: "Min Speed",
  minSpeedUnit1: "KM/H（0 No Limit）",
  minSpeedUnit2: "MP/H（0 No Limit）",
  th_keyType: "Snap Mode",
  modeTypeValue: [
    [1, "Snap Multi"],
    [0, "Snap Once​"],
  ],

  th_time_jg: "Snap Interval",
  offsetWidthUnit: "Sec（60-9999）",
  th_begin_time_a: "Time Range 2",
  th_keyType_xz: "Download",
  labelAlarmSelect: "Alarm",
  jj: "Panic",
  pz: "Collision",
  cs: "Speeding",
  cpcw: "Disk Error",
  lxls: "No Recording",
  cclljg: "Cellular Data Threshold Reminder",
};
