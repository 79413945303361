import { createStore } from "vuex";
import menus from "./modules/menus";
import VueCookies from "vue-cookies";

const token = VueCookies.get("gps_token");
const uname = VueCookies.get("gps_uname");
const pwd = VueCookies.get("gps_pwd");

const order_ip = VueCookies.get("gps_order_ip");
const order_port = VueCookies.get("gps_order_port");
const video_ip = VueCookies.get("gps_video_ip");
const video_port = VueCookies.get("gps_video_port");
const video_playback_port = VueCookies.get("gps_video_playback_port");

export default createStore({
  state: {
    token,
    uname,
    pwd,
    order_ip,
    order_port,
    video_ip,
    video_port,
    video_playback_port,
  },
  mutations: {
    setOrderIp(state, order_ip) {
      state.order_ip = order_ip;
      VueCookies.set("gps_order_ip", order_ip);
    },
    setOrderPort(state, order_port) {
      state.order_port = order_port;
      VueCookies.set("gps_order_port", order_port);
    },
    setVideoIp(state, video_ip) {
      state.video_ip = video_ip;
      VueCookies.set("gps_video_ip", video_ip);
    },
    setVideoPort(state, video_port) {
      state.video_port = video_port;
      VueCookies.set("gps_video_port", video_port);
    },
    setVideoPlaybackPort(state, video_playback_port) {
      state.video_playback_port = video_playback_port;
      VueCookies.set("gps_video_playback_port", video_playback_port);
    },
    setToken(state, token) {
      state.token = token;
      VueCookies.set("gps_token", token);
    },
    clearToken(state) {
      state.token = null;
      state.uname = null;
      state.pwd = null;
      VueCookies.set("gps_token", "");
      VueCookies.set("gps_uname", "");
      VueCookies.set("gps_pwd", "");
    },
    setuName(state, uname) {
      state.uname = uname;
      VueCookies.set("gps_uname", uname);
    },
    setPwd(state, pwd) {
      state.pwd = pwd;
      VueCookies.set("gps_pwd", pwd);
    },
  },
  actions: {},
  modules: { menus },
  getters: {
    token: (state) => state.token,
    uName: (state) => state.uname,
    pwd: (state) => state.pwd,

    orderIp: (state) => state.order_ip,
    orderPort: (state) => state.order_port,
    videoIp: (state) => state.video_ip,
    videoPort: (state) => state.video_port,
    videoPlaybackPort: (state) => state.video_playback_port,
  },
});
