import axios from "../../utils/axios";
export default {
  main_home(ay) {
    return axios.post("ajax.php", Object.assign({ op: "main_home" }, ay));
  },
  study_list(ay) {
    return axios.post("ajax.php", Object.assign({ op: "study_list" }, ay));
  },
  user_play_end(ay) {
    return axios.post("ajax.php", Object.assign({ op: "user_play_end" }, ay));
  },
  user_play_start(ay) {
    return axios.post("ajax.php", Object.assign({ op: "user_play_start" }, ay));
  },
  edus_list(ay) {
    return axios.post("ajax.php", Object.assign({ op: "edus_list" }, ay));
  },
  get_user_row(ay) {
    return axios.post("ajax.php", Object.assign({ op: "get_user_row" }, ay));
  },
};
