export default {
  userName: "USER",
  userPwd: "PASSWORD",
  vcode: "CODE",
  login: "LOGIN",

  empty_username: "No Username.​",
  length_username: "​Please use  5-character usernam.",
  empty_userpwd: "No Password!",
  empty_vcode: "Please fill in the verification code.",

  msg_type_1: "Verification code error.",
  msg_type_2: "No username or password entered.",
  msg_type_3: "Your account has expired, please contact the administrator.",
  msg_type_4: "Incorrect username or password.",
  msg_type_5: "Your account is in use.",
  msg_type_6: "Your account has been locked, please contact the administrator.",
  oPwd: "Old Psd",
  uPwd: "New Psd",
  cPwd: "Confirm",
  oPwd_empty: "Enter old password​",
  uPwd_empty: "Enter new password",
  cPwd_empty: "Please confirm your password again.",
  btnSave: "Save",
  pwdNOSame: "The password you entered twice is different.",
  userpwd_rule: "6-18 characters long, including numbers, uppercase letters, lowercase letters, and special symbols.​",

  msgType_1: "Please log in with your new password.​",
  msgType_2: "The old password is incorrect.​",
  msgType_3: "Please login again.​",
  msgType_4: "No permission to change password.",
};
