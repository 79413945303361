import axios from "../../utils/axios";
export default {
  menu_get_menu_list_by_role(ay) {
    return axios.post("api/menu/get_menu_list_by_role", Object.assign({}, ay), {
      hide_loading: 1,
    });
  },
  menu_get_menu_chk_by_rid(ay) {
    return axios.post("api/menu/get_menu_chk_by_rid", Object.assign({}, ay), {
      hide_loading: 1,
    });
  },
  menu_get_menu_list(ay) {
    return axios.post("api/menu/get_menu_list", Object.assign({}, ay), {
      hide_loading: 1,
    });
  },
};
