import md5 from "md5";
import underscore from "underscore";

function makeSign(obj) {
  const objTemp = Object.assign({ key: process.env.VUE_APP_MD5_KEY }, obj);
  let aySort = [];
  for (var p in objTemp) {
    aySort.push({
      name: p,
      value: objTemp[p],
    });
  }
  // 排序
  aySort = underscore.sortBy(aySort, "name");
  // 去重
  aySort = underscore.uniq(aySort, true, "name");
  const ayUrl = [];
  aySort.forEach((item) => {
    ayUrl.push(item.name + "=" + item.value);
  });
  const url = ayUrl.join("&");
  return md5(url).toUpperCase();
}

function parserDate(date) {
  const t = Date.parse(date);
  if (!isNaN(t)) {
    return new Date(Date.parse(date.replace(/-/g, "/")));
  } else {
    return new Date();
  }
}
function dateFormat(fmt, date) {
  let ret;
  const opt = {
    "Y+": date.getFullYear().toString(), // 年
    "m+": (date.getMonth() + 1).toString(), // 月
    "d+": date.getDate().toString(), // 日
    "H+": date.getHours().toString(), // 时
    "M+": date.getMinutes().toString(), // 分
    "S+": date.getSeconds().toString(), // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (const k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(
        ret[1],
        ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
      );
    }
  }
  return fmt;
}

function dateAddDay(dataFormat, days, fmt) {
  let dataCurPar = Date.parse(parserDate(dataFormat)) / 1000; // 转换为时间戳
  dataCurPar += 86400 * days; // 加days天后的时间戳
  const dataDefPar = new Date(parseInt(dataCurPar) * 1000); // 转换为时间
  return dateFormat(fmt, dataDefPar);
}

function getUploadUrl(url) {
  return process.env.VUE_APP_BASE_URL + url;
}

function getDomain() {
  let domain = web_url; //process.env.VUE_APP_BASE_URL;
  if (process.env.NODE_ENV == "development") {
    // domain = process.env.VUE_APP_BASE_DEV_URL;
  }
  return domain;
}

function int2time(seconds) {
  var hours = Math.floor(seconds / 3600);
  var minutes = Math.floor((seconds % 3600) / 60);
  var remainingSeconds = seconds % 60;

  var timeString =
    hours.toString().padStart(2, "0") +
    ":" +
    minutes.toString().padStart(2, "0") +
    ":" +
    remainingSeconds.toString().padStart(2, "0");

  return timeString;
}

function time2int(str) {
  let ay = str.split(":");
  return (
    Number(parseInt(ay[0]) * 3600) +
    Number(parseInt(ay[1]) * 60) +
    Number(parseInt(ay[2]))
  );
}

function dyLoadJs(ayJs, callback) {
  //
}

function formatDate(date) {
  var date = new Date(date);

  if (lang == "en") {
    return dateFormat("dd-mm-YYYY HH:MM:SS", date);
  }
  return dateFormat("YY-mm-dd HH:MM:SS", date);
}
/*
let date = new Date()
dateFormat("YYYY-mm-dd HH:MM", date)
*/
// function dateFormat(fmt, date) {
//     let ret;
//     const opt = {
//         "Y+": date.getFullYear().toString(),        // 年
//         "m+": (date.getMonth() + 1).toString(),     // 月
//         "d+": date.getDate().toString(),            // 日
//         "H+": date.getHours().toString(),           // 时
//         "M+": date.getMinutes().toString(),         // 分
//         "S+": date.getSeconds().toString()          // 秒
//         // 有其他格式化字符需求可以继续添加，必须转化成字符串
//     };
//     for (let k in opt) {
//         ret = new RegExp("(" + k + ")").exec(fmt);
//         if (ret) {
//             fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
//         };
//     };
//     return fmt;
// }

function dateFormatByDateStr(fmt, date) {
  let date1_str = date.replace(/-/gi, "/");
  return dateFormat(fmt, new Date(date1_str));
}

function getDir(val) {
  if ((val > 337.5 && val <= 360) || (val >= 0 && val < 22.5)) {
    return 0;
  }
  if (val >= 22.5 && val < 67.5) {
    return 1;
  }
  if (val >= 67.5 && val < 112.5) {
    return 2;
  }
  if (val >= 112.5 && val < 157.5) {
    return 3;
  }
  if (val >= 157.5 && val < 202.5) {
    return 4;
  }
  if (val >= 202.5 && val < 247.5) {
    return 5;
  }
  if (val >= 247.5 && val < 292.5) {
    return 6;
  }
  if (val >= 292.5 && val < 337.5) {
    return 7;
  }
}
//21 02 25 16 49 51
function str2time(str) {
  if (str.length != 12) return str;
  let ay = [];
  for (let i = 0; i < str.length; i = i + 2) {
    ay.push(str.substr(i, 2));
  }
  // if(lang == 'en'){
  // 	return  ay[2]+'-'+ ay[1] +'-'+ '20'+ay[0] +' '+ ay[3] +':'+ ay[4] +':'+ ay[5];
  // }
  return (
    "20" +
    ay[0] +
    "-" +
    ay[1] +
    "-" +
    ay[2] +
    " " +
    ay[3] +
    ":" +
    ay[4] +
    ":" +
    ay[5]
  );
}

function km2mph(val) {
  let t = localStorage.lang;
  if (t == "en") {
    return (val * 0.1 * 0.62).toFixed(2) + "MPH";
  }
  return (val * 0.1).toFixed(2) + "KM/H";
}

function km2mph2(val, flag) {
  if (flag == 2) {
    return (val * 0.1 * 0.62).toFixed(2) + "MPH";
  }
  return (val * 0.1).toFixed(2) + "KM/H";
}

function mph2km(val) {
  let t = localStorage.lang;
  if (t == "cn") {
    let v = val.toUpperCase();
    v = v.replace("MPH", "");
    return (parseFloat(v) / 0.62).toFixed(2) + "KM/H";
  }
  return val;
}
function mph2km2(val, flag) {
  if (flag == 1) {
    let v = val.toUpperCase();
    v = v.replace("MPH", "");
    return (parseFloat(v) / 0.62).toFixed(2) + "KM/H";
  }
  return val;
}

function km2mp(val) {
  let t = localStorage.lang;
  if (t == "en") {
    return (val * 0.1 * 0.62).toFixed(2) + "MP";
  }
  return (val * 0.1).toFixed(2) + "KM";
}

function km2mp2(val, flag) {
  if (flag == 2) {
    return (val * 0.1 * 0.62).toFixed(2) + "Mile";
  }
  return (val * 0.1).toFixed(2) + "KM";
}

function car_st(val) {
  if (val == 1 || val == 2) {
    return "offline";
  } else if (val == 6) {
    return "alarm";
  } else if (val == 8) {
    return "parkaccon";
  } else if (val == 9) {
    return "stopaccoff";
  } else if (val == 10) {
    return "stopaccon";
  }
  return "online";
}

function dateCalc(date1, date2) {
  let date1_str = date1.replace(/-/gi, "/");
  let timestamp1 = Date.parse(new Date(date1_str));

  let date2_str = date2.replace(/-/gi, "/");
  let timestamp2 = Date.parse(new Date(date2_str));

  let total_times = Math.abs(timestamp2 - timestamp1) / 1000; //秒

  let h = Math.floor(total_times / 3600);
  let m = Math.floor((total_times - h * 3600) / 60);
  let s = Math.floor(total_times - h * 3600 - m * 60);

  let msg = "";
  if (h > 0) {
    msg += h + " h ";
  }
  if (m > 0) {
    msg += m + " min";
  }
  if (msg == "") {
    msg += s + " sec";
  }

  return msg;
}

function getTotalSec(date1, date2) {
  let date1_str = date1.replace(/-/gi, "/");
  let timestamp1 = Date.parse(new Date(date1_str));

  let date2_str = date2.replace(/-/gi, "/");
  let timestamp2 = Date.parse(new Date(date2_str));

  let total_times = Math.abs(timestamp2 - timestamp1) / 1000; //秒
  return total_times;
}
function times2str(sec) {
  // let total_times = sec;
  let h = Math.floor(sec / 3600);
  let m = Math.floor((sec - h * 3600) / 60);
  let s = Math.floor(sec - h * 3600 - m * 60);

  let msg = "";
  if (h > 0) {
    msg += h + " h ";
  }
  if (m > 0) {
    msg += m + " min";
  }
  if (msg == "") {
    msg += s + " sec";
  }

  return msg;
}

//2019-06-11 16:18:15
function getDateFromString(str) {
  var reg = /^(\d+)-(\d+)-(\d+) (\d+):(\d+):(\d+)/;
  var s = str.match(reg);
  var result = "";
  if (s) {
    result = new Date(s[1], s[2] - 1, s[3], s[4], s[5], s[6]);
  }
  return result;
}
function get_unix_time(dateStr) {
  var newstr = dateStr.replace(/-/g, "/");
  var date = new Date(newstr);
  var time_str = date.getTime().toString();
  return time_str.substr(0, 10);
}

export {
  makeSign,
  parserDate,
  dateFormat,
  dateAddDay,
  getUploadUrl,
  getDomain,
  time2int,
  int2time,
  formatDate,
  getDir,
  str2time,
  // dateFormat,
  km2mph,
  mph2km,
  car_st,
  km2mp,
  dateCalc,
  dateFormatByDateStr,
  getTotalSec,
  times2str,
  km2mph2,
  mph2km2,
  km2mp2,
};
