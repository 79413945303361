import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "./assets/bootstrap.min.css";
import "./assets/site.scss";
import i18n from "@/i18n";
import api from "./api";
import "./assets/font/iconfont.css";
import "./assets/vfont/iconfont.css";
import divDrag from "./utils/divDrag";

import * as ElementPlusIconsVue from "@element-plus/icons-vue";

// Vue.directive("draggable", {
//   bind(el, binding) {
//     let startX, startY, initialMouseX, initialMouseY;

//     function onMouseMove(e) {
//       let dx = e.clientX - initialMouseX;
//       let dy = e.clientY - initialMouseY;
//       el.dispatchEvent(
//         new CustomEvent("dragging", {
//           detail: { dx, dy },
//         })
//       );
//     }

//     function onMouseUp() {
//       document.removeEventListener("mousemove", onMouseMove);
//       document.removeEventListener("mouseup", onMouseUp);
//     }

//     el.addEventListener("mousedown", (e) => {
//       startX = el.offsetLeft;
//       startY = el.offsetTop;
//       initialMouseX = e.clientX;
//       initialMouseY = e.clientY;
//       document.addEventListener("mousemove", onMouseMove);
//       document.addEventListener("mouseup", onMouseUp);
//     });
//   },
// });

const app = createApp(App);
app.use(store);
app.use(router);
app.use(ElementPlus, { size: "small" });
app.use(i18n);
app.use(divDrag);
app.mount("#app");

let imgUrl = web_url; //process.env.VUE_APP_BASE_URL;
//production
if (process.env.NODE_ENV == "development") {
  imgUrl = process.env.VUE_APP_BASE_DEV_URL;
}

imgUrl = imgUrl.replace(/^\/+|\/+$/g, "");
// 配置全局属性
app.config.globalProperties.$api = api;
app.config.globalProperties.$imgUrl = imgUrl;

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
