export default {
  Title: "基本信息",

  dCompanyID: "所属公司",
  dName: "司机姓名",
  dSex: "性别",
  dBodyNo: "身份证号",
  dContactTel: "司机联系电话",
  dDriverType: "驾驶证类型",
  dDriverNo: "驾驶证号码",
  dDriverStopDate: "驾驶证有效期",
  dDrvICNo: "从业资格证",
  dDrvEntrytime: "入职时间",
  dSortCode: "排序",
  dMore: "备注",
  dLastUpdated: "添加时间",
  bindCars: "绑定车辆",
  dfinger_img: "驾驶员指纹ID",
  dimg: "驾驶员照片",

  // top_dCompanyID: "请选公司",
  // top_dSex: "请选性别",
  // top_dDriverType: "请选驾证类型",

  // xb_first: "请选性别",
  // xb: [
  //   ["男", "男"],
  //   ["女", "女"],
  // ],
  // jzlx_first: "请选驾证类型",
  // jzlx: [
  //   ["C1", "C1"],
  //   ["C2", "C2"],
  // ],
};
